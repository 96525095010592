import * as React from 'react';
import Button from '@mui/material/Button';

export default function HeroButton(props) {
  let _props = Object.assign({}, props);
  if( !_props.sx ) _props.sx = {};

  _props.sx = Object.assign(
    _props.sx,
    {
      justifyContent: 'left',
      height: '150px',
      width: '150px',
      textAlign: 'left',
      verticalAlign: 'top',
      alignItems: 'flex-start',
      padding: '20px',
      boxShadow: '0 6px #cfdadd',
      marginLeft: '6px',
      marginBottom: '6px',
      '.MuiSvgIcon-root': {
        position: 'absolute',
        width: '1.5em',
        height: '1.5em',
        left: '20px',
        bottom: '20px'
      }
  });

  _props.variant = 'contained';
  return (
    <Button {..._props}>{props.children}</Button>
  );
}