import * as React from 'react';

import Box from '@mui/material/Box';

import LayoutImagePlaceholder from '../../assets/img/layout.image.png';

import LayoutDesktopPositionFull from '../../assets/img/layout.full.png'

import CampaignListSingle from './CampaignListSingle';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';

const LayoutButton = function(props) {
    var buttonStyle = 'standard';
    var buttonSize = 5;

    if( props.buttonStyle )
    {
        buttonStyle = props.buttonStyle;
    }
    
    if( props.buttonSize )
    {
        if( props.buttonSize === 'small' )
        {
            buttonSize = 4;
        }
        else if( props.buttonSize === 'large' )
        {
            buttonSize = 5.5;
        }
        else if( props.buttonSize === 'xlarge' )
        {
            buttonSize = 12;
        }
    }
    var style = {
        border: '2px solid #46bde6',
        height: '20px'
    };

    if( buttonStyle === 'full' )
    {
        style['width'] = '60px';
        style['height'] = '60px';
    }

    return <Grid
                item
                sx={style}
                xs={buttonStyle === 'full' ? null : buttonSize}
            />
}

const LayoutTextTitle = function(props) {
    return <Box
                sx={{
                    borderBottom: '2px solid #46bde6',
                    height: '2px',
                    width: '22px',
                    position: 'relative',
                    mb: 4,
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        left: '50%',
                        margin: '0 0 0 -1px',
                        backgroundColor: '#46bde6',
                        width: '2px',
                        top: 0,
                        height: '16px'
                    }
                }}
            />
}

const LayoutTextParagraph = function(props) {
    return <Box sx={{borderBottom: '2px solid #46bde6', height: '2px', mt: 2}} />
}

const LayoutTextDescription = function(props) {
    return (
        <>
            <LayoutTextParagraph />
            <LayoutTextParagraph />
            <LayoutTextParagraph />
            <LayoutTextParagraph />
        </>
    );
}

const LayoutImage = function(props){
    var gridProps = {
        item: true,
        xs: props.xs || 4,
        sx: Object.assign(
            {
                backgroundImage: `url(${LayoutImagePlaceholder})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                border: '2px solid #46bde6',
                paddingBottom: '33.333333%'
            },
            props.sx
        )
    }

    return <Grid
            {...gridProps}
        />;
}

const LayoutButtons = function(props){
    var gridProps = {
        xs: props.xs || null,
        sx: props.sx || null,
        mt: props.mt || null,
        mb: props.mb || null,
    };

    return <Grid {...gridProps} item container gap={1}>
                <LayoutButton buttonStyle={props.buttonStyle} buttonSize={props.buttonSize} />
                <LayoutButton buttonStyle={props.buttonStyle} buttonSize={props.buttonSize} />
            </Grid>;
}

const LayoutText = function(props){
    var gridProps = {
        item: true,
        xs: props.xs || 4
    }

    return <Grid
                {...gridProps}
            >
            {
                ( props.buttonPosition && props.buttonPosition == 'above_description' ) &&
                <LayoutButtons mb={3} buttonStyle={props.buttonStyle} buttonSize={props.buttonSize} />
            }

            <Box>
                <LayoutTextTitle />
                <LayoutTextDescription />
            </Box>

            {
                ( !props.buttonPosition || props.buttonPosition != 'above_description' ) &&
                <LayoutButtons mt={3} buttonStyle={props.buttonStyle} buttonSize={props.buttonSize} />
            }

        </Grid>;
}

export default function LayoutPreview(props) {

    let containerStyle = {
        border: '2px solid #000',
        borderRadius: '3px',
        maxWidth: '630px',
        margin: '0 auto',
        paddingTop: '10px'
    };

    if( props.themePosition === 'full' )
    {
        containerStyle.backgroundImage = `url(${LayoutDesktopPositionFull})`;
        containerStyle.backgroundPosition = 'center center';
        containerStyle.backgroundRepeat = 'no-repeat';
    }

    return (
            <Box
                sx={containerStyle}
            >
                <Box p={3} sx={{borderTop: '2px solid #000'}}>

                    <Grid container justifyContent="center" sx={{marginBottom: 3}}>
                        <Grid
                            item
                            xs={4}
                            sx={{
                                backgroundImage: `url(${LayoutImagePlaceholder})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                                border: '2px solid #46bde6',
                                minHeight: '80px'
                            }}
                        />
                    </Grid>
                    {
                        ( props.themeStyle === 'thin' || props.themeStyle === 'wide_normal' ) &&
                            <>
                                <Grid container justifyContent="center" mb={4} gap={3}>
                                    <LayoutImage xs={props.themeStyle === 'thin' ? 4 : 5} />
                                </Grid>

                                <Grid container justifyContent="center" gap={3}>
                                    <LayoutText {...props} xs={props.themeStyle === 'thin' ? 4 : 5} />
                                </Grid>
                            </>
                    }
                    {
                        ( props.themeStyle === 'wide_description_right' || props.themeStyle === 'wide_description_left' ) &&
                            <>
                                <Grid container justifyContent="center" gap={3}>
                                    {
                                        props.themeStyle === 'wide_description_right' ?
                                            <>
                                                <LayoutImage />

                                                <LayoutText {...props} />
                                            </>
                                        :
                                            <>
                                                <LayoutText {...props} />

                                                <LayoutImage />
                                            </>
                                    }

                                </Grid>
                            </>
                    }

                </Box>
            </Box>
    );
}