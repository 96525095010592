import React, { Fragment, useState } from 'react';

import { TablePagination, Chip, Box, Button, ButtonGroup, Typography, CircularProgress, Stack, Divider, Paper, TextField, Tooltip, Link as MLink, InputLabel, Grid, Autocomplete, NativeSelect, Select, MenuItem, InputAdornment, List, ListItem, ListItemText, Checkbox, IconButton, ListItemIcon, ListItemButton } from '@mui/material';

import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Info as InfoIcon
} from '@mui/icons-material';
import ContextMenu from '../../ContextMenu';

import Flag from 'react-world-flags'

export default function CountrySelector(props) {
    const [formFieldData, setFormFieldData] = useState(props.value || []);

    var _props = {
        options: props.options || [],
        id: props.id || null,
        name: props.name || null,
        value: formFieldData
    };

    // prop.options
    _props.options = _props.options.sort((a, b) => {
        if ( a.getRegion().getName() < b.getRegion().getName() ){
            return -1;
        }
        
        if ( a.getRegion().getName() > b.getRegion().getName() ){
            return 1;
        }
        
        return 0;
    });

    const handleChange = (event, newValue) => {
        setFormFieldData(newValue);
        
        if( props.onChange )
        {
            props.onChange(event, newValue);
        }
    }

    // prop.isOptionEqualToValue
    if( !_props.isOptionEqualToValue ) _props.isOptionEqualToValue = (option, value) => option.getId() === value.getId();

    return (
        <Autocomplete
            {..._props}
            multiple
            groupBy={(option) => option.getRegion().getName()}
            getOptionLabel={(option) => option.getName()}
            getOptionDisabled={(option) => {
                let disabledOption = props.disabledOptions && props.disabledOptions.find((optionSingle) =>  option.getId() === optionSingle.getId());
                return disabledOption ? true : false;
            }}
            renderGroup={(params) => {
                let groupHeaders = [];

                groupHeaders.push(<Box key={params.group} component="li"><Typography variant="body1" sx={{fontWeight: 'bold'}} ml={2} mt={3} mb={1}>{params.group}</Typography></Box>);
                groupHeaders.push(params.children);

                return groupHeaders;
            }}
            renderOption={(props, option, { selected }) => (
                <Box key={option.getId()} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />

                    <Flag height="18" width="26" style={{display: 'flex'}} code={option.getA2()} />
                
                    {option.getName()}
                </Box>
            )}
            freeSolo
            disableCloseOnSelect
            onChange={handleChange}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return <Chip key={index} label={<><Flag height="11" width="17" code={option.getA2()} /> {option.getName()}</>} {...getTagProps({ index })} />
                })
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    placeholder={( !formFieldData || formFieldData.length === 0 ) ? 'Global' : ''}
                />
            )}
        />
    );
}