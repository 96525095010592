import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import LoadingButton from '@mui/lab/LoadingButton';

import { TablePagination, Box, Button, Typography, CircularProgress, Paper, TextField, Tooltip, Link as MLink, InputLabel, Table, TableCell, TableContainer, TableHead, TableRow, TableBody } from '@mui/material';
import { AcquisitionRepository } from '@warnerconnect/library';

import ModalDialogue from './ModalDialogue';
import FileUpload from './Forms/Fields/FileUpload';
import Fieldset from './Forms/Fieldset';
import FieldContainer from './Forms/FieldContainer';

import {
  Info as InfoIcon
} from '@mui/icons-material';
import { useAuthState } from '../../Context';


export default function DownloadCodeForm(props) {
    const userDetails = useAuthState();

  const [downloadCodeData, setDownloadCodeData] = useState();
  const [downloadCodeLoading, setDownloadCodeLoading] = useState(false);
  const [downloadCodePage, setDownloadCodePage] = useState({page: 0, perPage: 10});
  
  const [downloadModalAddCodes, setDownloadModalAddCodes] = useState(false);
  const [downloadModalAddCodesSubmitting, setDownloadModalAddCodesSubmitting] = useState(false);
  const [downloadModalAddCodesData, setDownloadModalAddCodesData] = useReducer(
    ( currentValues, newValues ) => ({...currentValues, ...newValues}), {}
  );
  const [downloadModalUploadCodes, setDownloadModalUploadCodes] = useState(false);
  const [downloadModalUploadCodesSubmitting, setDownloadModalUploadCodesSubmitting] = useState(false);
  const [downloadModalUploadCodesData, setDownloadModalUploadCodesData] = useReducer(
    ( currentValues, newValues ) => ({...currentValues, ...newValues}), {}
  );
  const [downloadModalGenerateCodes, setDownloadModalGenerateCodes] = useState(false);
  const [downloadModalGenerateCodesSubmitting, setDownloadModalGenerateCodesSubmitting] = useState(false);
  const [downloadModalGenerateCodesData, setDownloadModalGenerateCodesData] = useReducer(
    ( currentValues, newValues ) => ({...currentValues, ...newValues}), {}
  );


  const setDownloadModalGenerateCodesDataSingle = (name, value) => {
    setDownloadModalGenerateCodesData({ [name]: value });
};

const setDownloadModalAddCodesDataSingle = (name, value) => {
    setDownloadModalAddCodesData({ [name]: value });
};

const setDownloadModalUploadCodesDataSingle = (name, value) => {
    setDownloadModalUploadCodesData({ [name]: value });
};



  const toggleModalAddCodes = event => setDownloadModalAddCodes(!downloadModalAddCodes);
  const toggleModalUploadCodes = event => setDownloadModalUploadCodes(!downloadModalUploadCodes);
  const toggleModalGenerateCodes = event => setDownloadModalGenerateCodes(!downloadModalGenerateCodes);

  const [downloadModalSuccess, setDownloadModalSuccess] = useState(false);

  const toggleModalSuccess = event => setDownloadModalSuccess(!downloadModalSuccess);

  const submitModalAddCodes = event => {
    setDownloadModalAddCodesSubmitting(true);
    
    acquisitionRepository.createDownloadCodeGroup(props.downloadId, {name: downloadModalAddCodesData.reference})
      .then(downloadCodeGroup => {

        acquisitionRepository.createDownloadCodes( props.downloadId, downloadCodeGroup.getId(), downloadModalAddCodesData.codes.split("\n") )
          .then( downloadCodes => {
            setDownloadModalAddCodesDataSingle('reference', '');
            setDownloadModalAddCodesDataSingle('codes', '');
            setDownloadCodePage({...downloadCodePage, page: 0});
            setDownloadModalAddCodesSubmitting(false);
            setDownloadModalSuccess(true);
            setDownloadModalAddCodes(false);
          });

      });
  };

  const submitModalUploadCodes = event => {
    setDownloadModalUploadCodesSubmitting(true);
  }

  const submitModalGenerateCodes = event => {
    setDownloadModalGenerateCodesSubmitting(true);

    let downloadCodeParams = {
      total: downloadModalGenerateCodesData.total,
      prefix: downloadModalGenerateCodesData.prefix,
      length: downloadModalGenerateCodesData.length
    };

    acquisitionRepository.createDownloadCodeGroup(props.downloadId, {name: downloadModalGenerateCodesData.reference})
      .then(downloadCodeGroup => {

        acquisitionRepository.generateDownloadCodes(props.downloadId, downloadCodeGroup.getId(), downloadCodeParams)
            .then( downloadCodes => {
              setDownloadModalGenerateCodesData({});
              setDownloadCodePage({...downloadCodePage, page: 0});
              setDownloadModalGenerateCodesSubmitting(false);
              setDownloadModalSuccess(true);
              setDownloadModalGenerateCodes(false);
            } );
      });
  }

  function handleChangeDownloadCodeRowsPerPage(event){
      setDownloadCodePage({page: 0, perPage: event.target.value});
  }

  function handleChangeDownloadCodePage(event, page){
      setDownloadCodePage({page: page, perPage: downloadCodePage.perPage});
  }

  function labelDisplayedRows( { from, to, count, page } ){
      return `${from}–${to} of ${count !== -1 ? count.toLocaleString() : `more than ${to}`}`;
  }

    // Listen for changes to the redeemable code property
    useEffect(() => {
      if( props.downloadId )
      {
          setDownloadCodeLoading(true);

          let limit = downloadCodePage.perPage;
          let offset = downloadCodePage.page !== 0 ? ( downloadCodePage.page * downloadCodePage.perPage ) : 0;
  
          acquisitionRepository.getDownloadCodes(props.downloadId, {limit: limit, offset: offset})
              .then(downloadCodes => {
                  setDownloadCodeData(downloadCodes);
                  setDownloadCodeLoading(false);
              });
      }
  }, [downloadCodePage]);

  const acquisitionRepository = new AcquisitionRepository();
  acquisitionRepository.setBearerToken(userDetails.accessToken);

  return (
    <>
        <ModalDialogue size="small" open={downloadModalSuccess} onClose={toggleModalSuccess}>
          <Box sx={{px: 2, pt: 2, pb: 1}}>
              <Typography variant="h4">Download Codes Added</Typography>
              <Typography variant="body1">Your download codes have been added</Typography>
          </Box>
        </ModalDialogue>
        <ModalDialogue open={downloadModalAddCodes} onClose={toggleModalAddCodes}>
            <Box sx={{px: 2, pt: 2, pb: 1}}>
                <Typography variant="h4">Add Codes</Typography>
                <Typography variant="body1">Provide a reference and a list of codes, sepearated by new lines, as shown in the example to add them to this campaign.</Typography>
            </Box>
            <form autoComplete="off">
              <Fieldset>
                  <FieldContainer xs={12}>
                      <InputLabel shrink htmlFor="download.addCodes.reference">Reference <Tooltip title={<>All codes added will be associated with this reference.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                      <TextField
                          id="download.addCodes.reference"
                          name="reference"
                          value={downloadModalAddCodesData['reference'] || ''}
                          fullWidth
                          autoComplete="off"
                          onChange={ e => setDownloadModalAddCodesDataSingle(e.target.name, e.target.value )}
                      />
                  </FieldContainer>
                  <FieldContainer xs={12}>
                      <InputLabel shrink htmlFor="download.addCodes.codes">Codes <Tooltip title={<>Codes should be unique and separated by a new line.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                      <TextField
                          name="codes"
                          id="download.addCodes.codes"
                          fullWidth
                          value={downloadModalAddCodesData['codes'] || ''}
                          placeholder="e.g. QE5WIFOISY
WVBLXSNLSA
LF5Q93SCE9
HECVGVCO4R
KATL7ZIRXO
PWYFJXSJX6
UC62DL7ZCO"
                          onChange={ e => setDownloadModalAddCodesDataSingle(e.target.name, e.target.value )}
                          multiline
                          rows={8}
                      />
                  </FieldContainer>
                  <FieldContainer xs={12}>
                      <LoadingButton onClick={submitModalAddCodes} loading={downloadModalAddCodesSubmitting} variant="outlined" size="large">Add Codes</LoadingButton>
                  </FieldContainer>
              </Fieldset>
            </form>
        </ModalDialogue>

        <ModalDialogue open={downloadModalUploadCodes} onClose={toggleModalUploadCodes}>
            <Box sx={{px: 2, pt: 2, pb: 1}}>
                <Typography variant="h4">Upload Codes</Typography>
                <Typography variant="body1">Provide a reference and a list of codes, sepearated by new lines, as shown in the example to add them to this campaign.</Typography>
            </Box>
            <Fieldset>                    
                <FieldContainer xs={12}>
                    <InputLabel shrink htmlFor="download.addCodes.reference">Reference <Tooltip title={<>All codes added will be associated with this reference.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                    <TextField
                        id="download.addCodes.reference"
                        name="reference"
                        autoComplete="off"
                        fullWidth
                        value={downloadModalUploadCodesData['reference'] || ''}
                        onChange={ e => setDownloadModalUploadCodesDataSingle(e.target.name, e.target.value)}
                    />
                </FieldContainer>
                <FieldContainer xs={6}>
                    <InputLabel shrink>Add File</InputLabel>
                    <FileUpload />
                </FieldContainer>
                <FieldContainer xs={12}>
                    <LoadingButton onClick={submitModalUploadCodes} variant="outlined" size="large">Upload Codes</LoadingButton>
                </FieldContainer>
            </Fieldset>
        </ModalDialogue>

        <ModalDialogue open={downloadModalGenerateCodes} onClose={toggleModalGenerateCodes}>
            <Box sx={{px: 2, pt: 2, pb: 1}}>
                <Typography variant="h4">Generate Codes</Typography>
                <Typography variant="body1">Automatically generate codes based on the data below.</Typography>
            </Box>
            <Fieldset>                    
            <FieldContainer xs={6}>
                    <InputLabel shrink htmlFor="download.generateCodes.reference">Reference <Tooltip title={<>All codes added will be associated with this reference.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                    <TextField
                        id="download.generateCodes.reference"
                        name="reference"
                        fullWidth
                        value={downloadModalGenerateCodesData['reference'] || ''}
                        onChange={ e => setDownloadModalGenerateCodesDataSingle(e.target.name, e.target.value)}
                    />
                </FieldContainer>
                <FieldContainer xs={6}>
                    <InputLabel shrink htmlFor="download.generateCodes.prefix">Prefix <Tooltip title={<>All codes generated will be prefixed with this text.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                    <TextField
                        id="download.generateCodes.prefix"
                        name="prefix"
                        fullWidth
                        value={downloadModalGenerateCodesData['prefix'] || ''}
                        onChange={ e => setDownloadModalGenerateCodesDataSingle(e.target.name, e.target.value)}
                    />
                </FieldContainer>
                <FieldContainer xs={6}>
                    <InputLabel shrink htmlFor="download.generateCodes.length">Length <Tooltip title={<>Code length, excluding the prefx.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                    <TextField
                        id="download.generateCodes.length"
                        name="length"
                        fullWidth
                        type="number"
                        value={downloadModalGenerateCodesData['length'] || ''}
                        onChange={ e => setDownloadModalGenerateCodesDataSingle(e.target.name, e.target.value)}
                    />
                </FieldContainer>
                <FieldContainer xs={6}>
                    <InputLabel shrink htmlFor="download.generateCodes.total">How Many Codes <Tooltip title={<>Total codes created.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                    <TextField
                        name="total"
                        id="download.generateCodes.total"
                        fullWidth
                        type="number"
                        value={downloadModalGenerateCodesData['total'] || ''}
                        onChange={ e => setDownloadModalGenerateCodesDataSingle(e.target.name, e.target.value)}
                    />
                </FieldContainer>

                <FieldContainer xs={12}>
                    <LoadingButton onClick={submitModalGenerateCodes} variant="outlined" size="large">Generate Codes</LoadingButton>
                </FieldContainer>
            </Fieldset>
        </ModalDialogue>

        <Fieldset>
            {
                <>
                    <FieldContainer xs={12}>
                        <Button onClick={toggleModalAddCodes} size="medium">Add Codes</Button>
                        <Button onClick={toggleModalUploadCodes} size="medium">Upload Codes</Button>
                        <Button onClick={toggleModalGenerateCodes} size="medium">Generate Codes</Button>
                        {/*<Button size="medium">Download Codes</Button>*/}
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{backgroundColor: '#f4f4f4'}}>
                                        <TableCell sx={{width: '25%', paddingRight: 0}}><Typography variant="body1">Reference</Typography></TableCell>
                                        <TableCell sx={{width: '25%', paddingRight: 0}}><Typography variant="body1">Code</Typography></TableCell>
                                        <TableCell sx={{width: '20%', paddingRight: 0}}><Typography variant="body1">Redeemed?</Typography></TableCell>
                                        <TableCell sx={{width: '15%', paddingRight: 0}}><Typography variant="body1">Download Count</Typography></TableCell>
                                        <TableCell sx={{width: '15%', paddingRight: 0}}><Typography variant="body1">Redeem Count</Typography></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                        {
                            ( downloadCodeLoading && !downloadCodeData ) &&
                                <TableRow>
                                    <TableCell colSpan={5} sx={{textAlign: 'center'}}><Typography variant="subtitle2"><CircularProgress size={10} /> Loading</Typography></TableCell>
                                </TableRow>
                        }
                        {
                            ( ( !downloadCodeLoading ) && ( downloadCodeData ) && ( downloadCodeData.getTotal() === 0 ) ) &&
                                <TableRow>
                                    <TableCell colSpan={5} sx={{textAlign: 'center'}}><Typography variant="subtitle2">There are no codes associated with this download.</Typography></TableCell>
                                </TableRow>
                        }
                        {
                            ( downloadCodeData && ( downloadCodeData.getTotal() > 0 ) ) &&
                                downloadCodeData.getItems().map( downloadCode => {
                                    return (
                                        <TableRow key={downloadCode.getId()}>
                                            <TableCell><Typography variant="subtitle2">{downloadCode.getCodeGroup() ? downloadCode.getCodeGroup().getName() : '' }</Typography></TableCell>
                                            <TableCell><Typography variant="subtitle2">{downloadCode.getCode()}</Typography></TableCell>
                                            <TableCell><Typography variant="subtitle2">{downloadCode.getRedeemTotal() ? 'Yes' : 'No'}</Typography></TableCell>
                                            <TableCell><Typography variant="subtitle2">{downloadCode.getDownloadTotal() || 0}</Typography></TableCell>
                                            <TableCell><Typography variant="subtitle2">{downloadCode.getRedeemTotal() || 0}</Typography></TableCell>
                                        </TableRow>
                                    );
                                })
                        }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        {
                            ( downloadCodeData && downloadCodeData.getTotal() > 0 ) &&
                            <>
                            <TablePagination
                                component="div"
                                count={parseInt(downloadCodeData.getTotal())}
                                page={downloadCodePage.page}
                                onPageChange={handleChangeDownloadCodePage}
                                rowsPerPage={downloadCodePage.perPage}
                                onRowsPerPageChange={handleChangeDownloadCodeRowsPerPage}
                                labelDisplayedRows={labelDisplayedRows}
                            />
                            </>
                        }
                    </FieldContainer>
                </>
            }
        </Fieldset>
    </>
  );
}
