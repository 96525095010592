import React, { Fragment, useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import { Utilities, AcquisitionRepository } from '@warnerconnect/library';
import { Box } from '@mui/system';
import { useAuthState } from '../../../../Context';

export default function Search(props) {
    const [fieldValue, setFieldValue] = useState(props.value);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchHandler, setSearchHandler] = useState();
    const [searchValue, setSearchValue] = useState(props.value);
    const [searchLoading, setSearchLoading] = useState();
    const userDetails = useAuthState();

    let acquisitionRepository = new AcquisitionRepository();
    acquisitionRepository.setBearerToken(userDetails.accessToken);

    const handleSearchChange = event => {
        setSearchValue(event.target.value);
    }

    const handleChange = (event, newValues) => {
        var newValuesFlat = [];

        newValues.forEach(newValue => {
            newValuesFlat.push(newValue);
        });

        setFieldValue(newValuesFlat);

        if( props.onChange )
        {
            props.onChange(event, newValuesFlat);
        }
    }

    useEffect(() => {
        if( searchHandler )
        {
            searchHandler.abort();
        }

        if( searchValue && ( searchValue.length >= 3 ) )
        {
            setSearchLoading(true);

            var searchAbortController = new AbortController();

            setSearchHandler(searchAbortController);

            acquisitionRepository
                .getCampaigns({include: ['locales']}, { 'artist': searchValue, 'locale.type': ['presave'] }, { signal: searchAbortController.signal })
                .then( (response) => {
                    var options = [];
                    if( response.getTotalItems() > 0 )
                    {
                        response.getItems().forEach( playlist => {
                            options.push({
                                image: playlist.getDefaultLocale().getConfigurationValue('theme.image.url'),
                                label: playlist.getDefaultLocale().getConfigurationValue('general.context')+' by '+playlist.getDefaultLocale().getArtist(),
                                id: playlist.getId(),
                                context: playlist.getDefaultLocale().getConfigurationValue('general.context'),
                                artist: playlist.getDefaultLocale().getArtist()
                            });
                        });
                    }

                    setSearchLoading(false);

                    setOptions(options);
                })
                .catch(e => {
                    // handle search being aborted
                });
        }
        
    }, [searchValue]);

    useEffect(() => {
        if (!open) {
        setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            id={props.id || null}
            name={props.name || null}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            multiple={props.hasOwnProperty('multiple') ? props.multiple : true}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.label}
            noOptionsText="Start typing to search"
            options={options}
            loading={searchLoading}
            value={fieldValue}
            onChange={handleChange}
            renderOption={(props, option, index) => {
                const key = `listItem-${index}-${option.id}`;
                return (
                    <li {...props} key={key}>
                        {option.label}
                    </li>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={fieldValue.length > 0 ? '' : props.placeholder || 'All'}
                    onChange={handleSearchChange}
                    InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                        {searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                    }}
                />
            )}
        />
    );
}
