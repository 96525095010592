import * as React from 'react';
import ContentBox from './ContentBox';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import FilterListIcon from '@mui/icons-material/FilterList';

import CampaignListSingle from './CampaignListSingle';
import { Button } from '@mui/material';

export default function CampaignList(props) {
    var campaignSingleProps = {};

  return (
    <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell sx={{width: '5%', paddingRight: 0}}></TableCell>
                    <TableCell sx={{width: '53%', paddingRight: 0}}><Typography variant="body1">Name</Typography></TableCell>
                    <TableCell sx={{width: '10%', paddingRight: 0}}><Typography variant="body1">Entered</Typography></TableCell>
                    <TableCell sx={{width: '13%', paddingRight: 0}}><Typography variant="body1">End</Typography></TableCell>
                    <TableCell sx={{width: '10%', paddingRight: 0}}><Typography variant="body1"></Typography></TableCell>
                    <TableCell sx={{width: '9%', paddingRight: 0}}>{props.filter && <Button onClick={props.filter} variant="text" endIcon={<FilterListIcon />}>Search</Button>}</TableCell>
                </TableRow>
            </TableHead>
        </Table>
        <ContentBox sx={{p: 0}}>
            <Table>
                <TableBody>
                    { ( props.data.length > 0 ) ?
                        props.data.map((row) => {
                            var campaignSingleProps = {
                                key: row.getId(),
                                data: row
                            };

                            if( props.onDelete )
                            {
                                campaignSingleProps['onDelete'] = props.onDelete;
                            }

                            if( props.onCopy )
                            {
                                campaignSingleProps['onCopy'] = props.onCopy;
                            }

                            return <CampaignListSingle {...campaignSingleProps} />;
                        })
                    :
                    <TableRow>
                        <TableCell colSpan={7} sx={{width: '40%', paddingRight: 0, textAlign: 'center'}} component="th" scope="row">
                            <Typography variant="body1">No results to display</Typography>
                        </TableCell>
                    </TableRow>
                    }
                </TableBody>
            </Table>
        </ContentBox>
    </TableContainer>
  );
}