import React, { useState, useEffect, useReducer } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AcquisitionRepository, EngagementRepository, EngagementAPI } from '@warnerconnect/library';

import { TablePagination, Button, Typography, CircularProgress, Link as MLink, Box, InputLabel, Tooltip, MenuItem, Select, FormControlLabel, Switch } from '@mui/material';

import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';

import { useAuthDispatch, logout, useAuthState, changeUserTeam } from '../../../Context';

import AudienceList from '../../Elements/AudienceList';
import useQuery from '../../Utilities/useQuery';
import useTitle from '../../Utilities/useTitle';
import PageContainer from '../../Elements/PageContainer';
import HeroText from '../../Elements/HeroText';
import Hero from '../../Elements/Hero';
import HeroButtonGroup from '../../Elements/HeroButtonGroup';
import HeroButton from '../../Elements/HeroButton';
import Loader from '../../Elements/Loader';

import {
    Info as InfoIcon,
    AddBox as AddBoxIcon,
    ArrowBack as ArrowBackIcon
} from '@mui/icons-material';

import CampaignsHeroImage from '../../../assets/media/hero/campaigns.jpg';
import Fieldset from '../../Elements/Forms/Fieldset';
import FieldContainer from '../../Elements/Forms/FieldContainer';
import ModalDialogue from '../../Elements/ModalDialogue';
import { LoadingButton } from '@mui/lab';

import { useSnackbar } from "notistack";

export default function InsightsIndex() {
    useTitle('Email Schedule');

    const perPage = 10;

    var query = useQuery();
    const history = useHistory();
    const location = useLocation();

    const userDetails = useAuthState();

    const { enqueueSnackbar } = useSnackbar();

    if( !query.get('page') )
    {
        query.set('page', 0)
    }

    if( !query.get('perPage') )
    {
        query.set('perPage', 25)
    }

    const [audienceDataEvents, setAudienceDataEvents] = useState(0);

    const [audienceData, setAudienceData] = useState();
    const [audienceDataLoading, setAudienceDataLoading] = useState(false);

    const engagementRepository = new EngagementRepository();
    const engagementAPI = new EngagementAPI();
    engagementRepository.setBearerToken(userDetails.accessToken);
    engagementAPI.setBearerToken(userDetails.accessToken);

    const refreshAudienceData = event => {
        setAudienceDataEvents(audienceDataEvents + 1);
    }

    useEffect(() => {
        setAudienceDataLoading(true);

        let limit = query.get('perPage');

        let offset = query.get('page') !== 0 ? ( query.get('page') * query.get('perPage') ) : 0;

        let criteria = {};

        let options = {
            limit: limit,
            offset: offset,
            include: ['language', 'emailTemplate']
        };

        engagementRepository.getAudiences(options, criteria).then( response => {
            setAudienceData(response);
            setAudienceDataLoading(false);
        });
    }, [userDetails.team, query.get('perPage'), query.get('page'), audienceDataEvents]);

    function handleChangePage(event, newValue){
        query.set('page', newValue);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function handleChangeRowsPerPage(event, newValue){
        query.set('perPage', newValue.props.value);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function labelDisplayedRows( { from, to, count, page } ){
        return `${from}–${to} of ${count !== -1 ? count.toLocaleString() : `more than ${to}`}`;
    }

    return (
        <>
            <Hero image={CampaignsHeroImage}>
                <HeroText>
                    <Typography variant="h4" gutterBottom>Scheduled Emails</Typography>
                    <Typography paragraph>This is a complete list of all scheduled emails past, present and future.</Typography>
                </HeroText>
                <HeroButtonGroup>
                    <HeroButton component={Link} to={`/insights/audience-builder`} color="secondary">Create Audience<AddCircleOutlineIcon /></HeroButton>
                </HeroButtonGroup>
            </Hero>

            <PageContainer>
                <Typography variant="h4">Audiences</Typography>
                { audienceDataLoading && <Loader /> }
                { !audienceDataLoading && audienceData &&
                <>
                    <AudienceList
                        data={audienceData.getItems()}
                        onDelete={audienceId => {
                            return engagementAPI.deleteAudience(audienceId)
                                .then(deleteAudienceResponse => {
                                    enqueueSnackbar("Scheduled send deleted", {
                                        variant: 'success'
                                    });

                                    refreshAudienceData();

                                    return deleteAudienceResponse;
                                });
                        }}
                    />
                    {
                        ( audienceData.getTotal() > 0 ) &&
                            <TablePagination
                                component="div"
                                count={audienceData.getTotal()}
                                page={parseInt(query.get('page'))}
                                onPageChange={handleChangePage}
                                rowsPerPage={parseInt(query.get('perPage'))}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelDisplayedRows={labelDisplayedRows}
                            />
                    }
                </>
                }
            </PageContainer>
        </>
    );
};
