import React, { Fragment, useState } from 'react';
import { Radio, Modal, RadioGroup, FormControlLabel, FormControl, FormLabel, TablePagination, Chip, Box, Button, ButtonGroup, Typography, CircularProgress, Stack, Divider, Paper, TextField, Tooltip, Link as MLink, InputLabel, Grid, Autocomplete, NativeSelect, Select, MenuItem, InputAdornment, List, ListItem, ListItemText, Checkbox, IconButton, ListItemIcon, ListItemButton, OutlinedInput, Card } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

export default function ModalDialogue(props) {
    const [open, setOpen] = React.useState(props.open);
    
    var showFooter = props.hasOwnProperty('showFooter') ? props.showFooter : true;
    
    var width = 800;

    if( props.size )
    {
        if( props.size == "small" )
        {
            width = 600;
        }
        else if( props.size == "large" )
        {
            width = 1000;
        }
    }
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        maxHeight: '80%',
        overflowY: 'scroll',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: width
    };

    return (
        <Modal
            open={props.open}
            onClose={(e, r) => {
                if( props.backdropClick === true )
                {
                    props.onClose(e, r);
                }
            }}
        >
            <Box component={Card} style={style}>
                {
                    props.children &&
                        props.children
                }
                {
                    showFooter &&
                        <Box sx={{px: 2, py: 1, borderTop: 1, borderColor: 'grey.500'}}>
                            <Button onClick={props.onClose ? props.onClose : null} startIcon={<CloseIcon />} color="error">Close</Button>
                        </Box>
                }
            </Box>
        </Modal>
    );
}