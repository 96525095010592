import * as React from 'react';

import { CircularProgress } from '@mui/material';

import ContentBox from './ContentBox';

export default function Loader(props) {

  var margin = '10rem auto';

  if( props.size === 'small' )
  {
    margin = '5rem auto';
  }
  else if( props.size === 'x-small' )
  {
    margin = '2rem auto';
  }
  
  return (
    <ContentBox sx={{textAlign: 'center', marginTop: '58px'}}><CircularProgress sx={{margin: margin}} /></ContentBox>
  );
}