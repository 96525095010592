
export async function openPopup( url, window_name, width, height )
{
	var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
	var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;
	
	var _width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
	var _height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;
	
	var left = ((_width / 2) - (width / 2)) + dualScreenLeft;
	var top = ((_height / 2) - (height / 2)) + dualScreenTop;

	   var window_features = "toolbar=no,menubar=no,location=no,resizable=no,scrollbars=yes,status=no,width="+width+",height="+height+",top="+top+",left="+left+"";

	return window.open(url, window_name, window_features);
}

export function isObject(value) {
	return value && typeof value === 'object' && value.constructor === Object;
}

export function isValidURL(string){
	let url;

	if( !( string.substring(0, 7) === "http://" || string.substring(0, 8) === "https://" ) )
	{
		return false;
	}

	try
	{
		url = new URL(string);
	}
	catch (e)
	{
		return false;
	}

	return true;
}

export function getDaysInMonth(existingDate) {
	let month = existingDate.getMonth();
	let date = new Date(existingDate.getFullYear(), existingDate.getMonth(), 1);
	let days = [];

	while (date.getMonth() === month) {
		days.push(new Date(date));
		date.setDate(date.getDate() + 1);
	}

	return days;
}

export function getMonth(month)
{
	var monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];

	return monthNames[month];
}

function _flattenObject( value, existingValue = null, existingKey = null, delimiter = null )
{
	if( !delimiter )
	{
		delimiter = '.';
	}

	if( !existingKey )
	{
		existingKey = [];
	}

	if( !existingValue )
	{
		existingValue = {};
	}

	if( isObject(value) )
	{
		Object.keys(value).forEach( (singleKey) => {
			var newKey = [...existingKey];
			newKey.push(singleKey);

			_flattenObject(value[singleKey], existingValue, newKey);
		});
	}
	else if( Array.isArray(value) && value.length === 0 )
	{
		existingValue[existingKey.join(delimiter)] = null;
	}
	else
	{
		existingValue[existingKey.join(delimiter)] = value;
	}
}

export function flattenObject( value, delimiter = null )
{
	var existingValue = {};

	_flattenObject(value, existingValue);

	return existingValue;
}

export function mergeObjects(target, source, mergeArrays = true) {
    const isObject = (obj) => obj && typeof obj === 'object';

    if (!isObject(target) || !isObject(source)) {
      return source;
    }

    Object.keys(source).forEach(key => {
      const targetValue = target[key];
      const sourceValue = source[key];

      if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
        // This method merges arrays based on index
        if( mergeArrays )
        {
          target[key] = Object.values( mergeObjects(Object.assign({}, targetValue), sourceValue, mergeArrays) )
        }
        else
        {
          target[key] = sourceValue;
        }
      } else if ( Array.isArray(targetValue) ) {
		target[key] = targetValue;
	  } else if ( Array.isArray(sourceValue) ) {
		target[key] = sourceValue;
      } else if ( isObject(targetValue) || isObject(sourceValue) ) {
        target[key] = mergeObjects(Object.assign({}, targetValue || {}), sourceValue || {}, mergeArrays);
      } else {
        target[key] = sourceValue;
      }
	});

	return target;
}

