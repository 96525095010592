import { useState, Fragment } from 'react';

import ModalDialogue from './ModalDialogue';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import MLink from '@mui/material/Link';

import ContextMenu from './ContextMenu.js';

import Flag from 'react-world-flags'

import { Button, Box, Chip } from '@mui/material';

import {
    Delete as DeleteIcon,
    AddBox as AddBoxIcon
} from '@mui/icons-material';

export default function TeamListSingle(props) {
    const [deleteModal, setDeleteModal] = useState(false);
    const [showAllCountries, setShowAllCountries] = useState(false);

    const toggleDeleteModal = event => {
        setDeleteModal(!deleteModal);
    }

    var options = [];

    if( props.onClickAddChild )
    {
        options.push( {icon: <AddBoxIcon />, label: 'Add Sub Team', onClick: () => { props.onClickAddChild(props.data) }} );
    }

    if( props.onDelete )
    {
        options.push( {icon: <DeleteIcon />, label: 'Delete', onClick: toggleDeleteModal} );
    }

    let countryNames = [];

    if( props.data.getCountries() && ( props.data.getCountries().length > 0 ) )
    {
        props.data.getCountries().forEach(country => {
            countryNames.push(<Fragment key={country.getId()}><Chip sx={{margin: '3px'}} label={<><Flag height="11" width="17" code={country.getA2()} /> {country.getName()}</>} /></Fragment>);
        });
    }
    else
    {
        countryNames.push(<Chip key="0" sx={{margin: '3px'}} label="Global" />);
    }

    let countryLimit = 3;

    return (
        <Fragment key={props.data.getId()}>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {
                    props.fields.map(field => {

                        return <TableCell sx={{width: field.width, paddingRight: 0}} key={field.name}>
                            {
                                ( field.name === 'name') && <>
                                    <Typography sx={{pl: props.indentation ? props.indentation * 4 : 0}} variant="body1">
                                        { props.indentation > 0 ? '- ' : '' }
                                        
                                        <MLink component={Link} to={`/teams/${props.data.getId()}/edit`}>{props.data.getName()}</MLink>
                                    </Typography>
                                </>
                            }
                            {
                                ( field.name === 'territories') && <>
                                    <Typography variant="subtitle2">
                                        {
                                            !showAllCountries ?
                                                <>{countryNames.slice(0, countryLimit)}{ ( countryNames.length > countryLimit ) && <Button onClick={() => setShowAllCountries(true)} variant="text" size="small">and {countryNames.length - countryLimit} more</Button> }</>
                                            :
                                                <>{countryNames}</>

                                        }
                                    </Typography>
                                </>
                            }
                            {
                                ( field.name === 'totalUsers') && <>
                                    <Typography variant="subtitle2">{props.data.getUsers() ? props.data.getUsers().length.toLocaleString() : 0}</Typography>
                                </>
                            }
                            {
                                ( field.name === 'options') && <>
                                    <ModalDialogue showFooter={false} open={deleteModal} onClose={toggleDeleteModal}>
                                        <Box sx={{px: 2, pt: 2, pb: 1}}>

                                            <Typography mb={2} variant="h4">Delete Team</Typography>
                                            <Typography variant="body1">Are you sure you want to delete the team {props.data.getName()} .</Typography>

                                            <Box sx={{textAlign: 'right'}}>
                                                <Button
                                                    onClick={toggleDeleteModal}
                                                    sx={{mt: 2, ml: 2}}
                                                >
                                                    Cancel
                                                </Button>

                                                <Button
                                                    onClick={() => {
                                                        if( props.onDelete )
                                                        {
                                                            props.onDelete(props.data.getId()).then(deleteResponse => {
                                                                toggleDeleteModal();
                                                            });
                                                        }
                                                    }}
                                                    sx={{mt: 2, ml: 2}
                                                }>
                                                    Delete
                                                </Button>
                                            </Box>
                    
                                        </Box>
                                    </ModalDialogue>
                                    {
                                        ( options.length > 0 ) &&
                                            <ContextMenu
                                                options={options}
                                            />
                                    }
                                </>
                            }
                        </TableCell>;
                    })
                }

            </TableRow>
            {
                props.data.getChildTeams() &&
                    props.data.getChildTeams().map((subRow) => {
                        var teamSingleProps = {
                            key: subRow.getId(),
                            data: subRow,
                            fields: props.fields,
                            indentation: props.indentation ? props.indentation + 1 : 1
                        };

                        if( props.onDelete )
                        {
                            teamSingleProps['onDelete'] = props.onDelete;
                        }

                        if( props.onClickAddChild )
                        {
                            teamSingleProps['onClickAddChild'] = props.onClickAddChild;
                        }

                        return (
                            <TeamListSingle {...teamSingleProps}/>
                        )
                })
            }
        </Fragment>
    );
}