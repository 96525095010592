import React, {useEffect, useState} from 'react';

import {
    CloudUpload as CloudUploadIcon,
    AttachFile as AttachFileIcon
} from '@mui/icons-material';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Container } from '@mui/material';

import { AcquisitionAPI } from '@warnerconnect/library';
import { LoadingButton } from '@mui/lab';
import { useAuthState } from '../../../../Context';

export default function LargeFileUpload(props) {
  const [formDataUploading, setFormDataUploading] = useState(false);
  const [formDataLoading, setFormDataLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [formURL, setFormURL] = useState();
  const [formFileName, setFormName] = useState();
  const [formFileNameFinal, setFormNameFinal] = useState();
  const userDetails = useAuthState();

  let acquisitionAPI = new AcquisitionAPI();
  acquisitionAPI.setBearerToken(userDetails.accessToken);
  
  const handleFileInputChange = (e) => {
    let fileName = e.target.files[0] && e.target.files[0].name ? e.target.files[0].name : null;

    setFormName(fileName);
  };

  const handleFormSubmit = (e) => {
    let checkUploadInterval;

    setFormDataUploading(true);

    checkUploadInterval = setInterval(() => {

      acquisitionAPI.checkUploadURLStatus(formFileNameFinal).then(response => {
        if( response.complete )
        {
          clearInterval(checkUploadInterval);

          if( props.onUpload )
          {
            props.onUpload(formFileNameFinal);
          }

          setFormDataUploading(false);

          setFormData(null);
          setFormURL(null);
          setFormName(null);
          setFormNameFinal(null);
        }
      });
    }, 4000);
  };

  useEffect(() => {
    if( formFileName )
    {
      setFormDataLoading(true);

      acquisitionAPI.getUploadURL(props.path+'/'+formFileName).then(formFields => {
        setFormURL(formFields.url);
        setFormNameFinal(formFields.fields.key);
        let formDataNew = [];
  
        for( let formFieldKey in formFields.fields )
        {
          let formFieldValue = formFields.fields[formFieldKey];
          formDataNew.push({
            key: formFieldKey,
            value: formFieldValue
          });
        }

        setFormDataLoading(false);

        setFormData(formDataNew);  
      })
    }
  }, [formFileName]);

  
  return (
    <form action={formURL} method="post" encType="multipart/form-data" onSubmit={handleFormSubmit}>
    <Card sx={{mt: 2, mb: 2}}>
      <CardActions>
          {
            formData &&
              formData.map(formDataField => (
                <input type="hidden" key={formDataField.key} name={formDataField.key} value={formDataField.value} />
              ))
          }
          
          <LoadingButton
              disabled={formDataLoading}
              loading={formDataLoading}
              loadingPosition="end"
              endIcon={<AttachFileIcon />}
              component="label"
          >
            <input hidden type="file" name="file" onChange={handleFileInputChange} />

            {formFileName || 'Choose File'}
          </LoadingButton>

      </CardActions>
    </Card>
          <LoadingButton
              disabled={!formFileNameFinal}
              sx={{mb: 2}}
              variant="contained"
              type="submit"
              loading={formDataUploading}
              loadingPosition="end"
              endIcon={<CloudUploadIcon />}
          >
              Upload File
          </LoadingButton>
    </form>

    );
}
