import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { AcquisitionRepository, ProcessingAPI } from '@warnerconnect/library';
import Statistic from '../../Elements/Statistic';

import { TablePagination, Box, Button, Typography, CircularProgress, Stack, Divider, Paper, Link as MLink, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';

import Flag from 'react-world-flags'
import { getName } from 'country-list';

import CampaignList from '../../Elements/CampaignList';
import useQuery from '../../Utilities/useQuery';
import useTitle from '../../Utilities/useTitle';

import { Redirect, useParams } from 'react-router';
import PageContainer from './../../Elements/PageContainer';
import Hero from '../../Elements/Hero';
import HeroButtonGroup from '../../Elements/HeroButtonGroup';
import HeroButton from '../../Elements/HeroButton';
import TabGroup from '../../Elements/TabGroup';
import ContentBox from '../../Elements/ContentBox';
import TableBarChart from '../../Elements/TableBarChart';
import EntrantList from '../../Elements/EntrantList';
import EntrantListSingle from '../../Elements/EntrantListSingle';

import * as dayjs from 'dayjs'

import { Line, Utils } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  } from 'chart.js';

import {
    Edit as EditIcon,
    ShowChart as ShowChartIcon,
    Visibility as VisibilityIcon,
    MoreHoriz as MoreHorizIcon
} from '@mui/icons-material';
import CampaignHeaderButtons from '../../Elements/CampaignHeaderButtons';
import { useAuthState } from '../../../Context';

function RenderDailyVisitsChart(props) {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Filler,
        Tooltip,
        Legend
    );

    let entrantDataFlat = {};

    if( props.entrantData )
    {
        props.entrantData.forEach( entrantDataSingle => {
            entrantDataFlat[entrantDataSingle.date] = entrantDataSingle.total;
        });
    }

    let labels = [];

    let dataListEntrants = [];
    let dataListVisits = [];

    let dateCursor = props.dateStart;

    while( dateCursor <= props.dateFinish)
    {
        let dateReference = dayjs(dateCursor).format('YYYY-MM-DD');

        labels.push(dayjs(dateCursor).format('D MMM'));

        if( dateCursor > Date.now() )
        {
            dataListEntrants.push( null );
            dataListVisits.push( null );
        }
        else
        {
            dataListEntrants.push( entrantDataFlat ? ( entrantDataFlat[dateReference] || 0 ) : 0 );
            dataListVisits.push( props.visitsData && props.visitsData[dateReference] ? ( props.visitsData[dateReference].total || 0 ) : props.visitsData ? 0 : null );
        }

        dateCursor = dayjs(dateCursor).add(1, 'days').toDate();
    }

    var dataListMax = Math.max( ...dataListEntrants.concat(dataListVisits) );
        dataListMax = dataListMax > 50 ? null : 50;

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    usePointStyle: true,
                    boxWidth: 6
                }
            },
        },
        interaction: {
            intersect: false,
            mode: 'index'
        },
        scales: {
            y: {
                max: dataListMax
            }
        }
    };

    const data = {
        labels,
        datasets: [
            {
                label: 'Entries',
                data: dataListEntrants,
                borderColor: 'rgb(107, 120, 170)',
                pointBackgroundColor: 'rgb(107, 120, 170)',
                backgroundColor: 'rgba(107, 120, 170, 0.2)',
                lineTension: 0.3,
                pointRadius: 4,
                pointHoverRadius: 6,
                fill: true
            },
            {
                label: 'Visits',
                data: dataListVisits,
                borderColor: 'rgb(68, 188, 228)',
                pointBackgroundColor: 'rgb(68, 188, 228)',
                backgroundColor: 'rgba(68, 188, 228, 0.2)',
                lineTension: 0.3,
                pointRadius: 4,
                pointHoverRadius: 6,
                fill: true
            },
        ],
    };
  
    return (
        <Line options={options} data={data} />
    )
}

export default function CampaignsStats(props) {
    let query = useQuery();
    const { campaignId } = useParams();
    const userDetails = useAuthState();

    useTitle('Campaign Stats');

    // Campaign State
    const [campaignData, setCampaignData] = useState();
    const [campaignDataLoading, setCampaignDataLoading] = useState(true);

    // Analytics State
    const [campaignAnalyticsData, setCampaignAnalyticsData] = useState();
    const [campaignAnalyticsDataLoading, setCampaignAnalyticsDataLoading] = useState(true);

    // Entrants State
    const [campaignEntrantsPage, setCampaignEntrantsPage] = useState({page: query.get('entrantsPage') || 0, perPage: query.get('entrantsPerPage') || 25});
    const [campaignEntrantsData, setCampaignEntrantsData] = useState();
    const [campaignEntrantsDataLoading, setCampaignEntrantsDataLoading] = useState(false);

    const acquisitionRepository = new AcquisitionRepository();
    const processingAPI = new ProcessingAPI();

    acquisitionRepository.setBearerToken(userDetails.accessToken);
    processingAPI.setBearerToken(userDetails.accessToken);

    useEffect(() => {
        processingAPI.getCampaignWebAnalyticsVisits(campaignId)
            .then( getCampaignWebAnalyticsVisitsResponse => {
                setCampaignAnalyticsData(getCampaignWebAnalyticsVisitsResponse);
                setCampaignAnalyticsDataLoading(false);
            })
            .catch(e => {});

        acquisitionRepository.getCampaign(campaignId)
            .then( getCampaignResponse => {
                document.title = getCampaignResponse.getName()+' Stats';

                setCampaignData(getCampaignResponse);
                setCampaignDataLoading(false);
            });

    }, []);

    useEffect(() => {
        setCampaignEntrantsDataLoading(true);

        let limit = campaignEntrantsPage.perPage;
        let offset = campaignEntrantsPage.page !== 0 ? ( campaignEntrantsPage.page * campaignEntrantsPage.perPage ) : 0;

        acquisitionRepository.getCampaignEntrants(campaignId, {limit: limit, offset: offset, include: ['entrant']})
            .then( getCampaignResponse => {
                setCampaignEntrantsData(getCampaignResponse);
                setCampaignEntrantsDataLoading(false);
            });
    }, [campaignEntrantsPage]);

    function handleChangeRowsPerPage(event){
        setCampaignEntrantsPage({page: 0, perPage: event.target.value});
    }

    function handleChangePage(event, page){
        setCampaignEntrantsPage({page: page, perPage: campaignEntrantsPage.perPage});
    }

    function labelDisplayedRows( { from, to, count, page } ){
        return `${from}–${to} of ${count !== -1 ? count.toLocaleString() : `more than ${to}`}`;
    }

    var entrantsBySourceFormTotal = 0;

    if( campaignData )
    {
        var entrantsBySource = campaignData.getStatistic('entrantsBySource') ? campaignData.getStatistic('entrantsBySource').getData() : [];
        var formEntriesByPlatform = campaignData.getStatistic('formEntriesByPlatform') ? campaignData.getStatistic('formEntriesByPlatform').getData() : [];
        var entrantsByMailingList = campaignData.getStatistic('entrantsByMailingList') ? campaignData.getStatistic('entrantsByMailingList').getData() : [];
        var entrantsCities = campaignData.getStatistic('entrantsCities') ? campaignData.getStatistic('entrantsCities').getData() : [];
        var entrantsCountries = campaignData.getStatistic('entrantsCountries') ? campaignData.getStatistic('entrantsCountries').getData() : [];
        
        // Total entrants by form
        var entrantsBySourceForm = entrantsBySource.find(x => x.source === 'form');
        entrantsBySourceFormTotal = entrantsBySourceForm ? entrantsBySourceForm.total : 0;

        // Entrants By Source
        var entrantsBySourceTableData = [];
        var entrantsBySourceTableCounter = 0;

        if( entrantsBySource )
        {
            entrantsBySource.forEach( entrantsBySourceSingle => {
                entrantsBySourceTableCounter++;
    
                var sourceName = entrantsBySourceSingle.source;
    
                if (sourceName == 'spotify')          sourceName = 'Spotify';
                else if (sourceName == 'form')        sourceName = 'Form Entry';
                else if (sourceName == 'youTube')     sourceName = 'YouTube';
                else if (sourceName == 'appleMusic')  sourceName = 'Apple Music';
                else if (sourceName == 'deezer')      sourceName = 'Deezer';
                else if (sourceName == 'amazon')      sourceName = 'Amazon Music';
                else if (sourceName == 'facebook')    sourceName = 'Facebook';
    
                entrantsBySourceTableData.push({
                    index: entrantsBySourceTableCounter,
                    name: sourceName,
                    total: entrantsBySourceSingle.total,
                    percentage: ( 100 / campaignData.getTotalEntrants() ) * entrantsBySourceSingle.total
                });
            });
    
        }

        // Entries by platform
        var formEntriesByPlatformData = [];
        var formEntriesByPlatformCounter = 0;

        formEntriesByPlatform.forEach( formEntriesByPlatformSingle => {
            formEntriesByPlatformCounter++;

            var serviceName = formEntriesByPlatformSingle.music_service;

            if (serviceName == 'AppleMusic')      serviceName = 'Apple Music';
            else if (serviceName == 'youtube')    serviceName = 'YouTube';
            else if (serviceName == 'none')       serviceName = 'None';
            else if (serviceName == 'other')      serviceName = 'Other';
            else if (serviceName == 'napster')    serviceName = 'Napster';
            else if (serviceName == 'imusic')    serviceName = 'iMusic';
            else if (serviceName == 'GooglePlay') serviceName = 'Google Play';

            formEntriesByPlatformData.push({
                index: formEntriesByPlatformCounter,
                name: serviceName,
                total: formEntriesByPlatformSingle.total,
                percentage: ( 100 / campaignData.getTotalEntrants() ) * formEntriesByPlatformSingle.total
            });
        });

        // Mailing lists
        var entrantsByMailingListData = [];
        var entrantsByMailingListCounter = 0;

        entrantsByMailingList.forEach( entrantsByMailingListSingle => {
            entrantsByMailingListCounter++;

            entrantsByMailingListData.push({
                index: entrantsByMailingListCounter,
                name: entrantsByMailingListSingle.listId,
                total: entrantsByMailingListSingle.total,
                percentage: ( 100 / campaignData.getTotalEntrants() ) * entrantsByMailingListSingle.total
            });
        });

        // Entrants Cities
        var entrantsCitiesData = [];
        var entrantsCitiesCounter = 0;
        
        entrantsCities.forEach( entrantsCitiesSingle => {
            entrantsCitiesCounter++;

            if( entrantsCitiesCounter > 20 ) return;

            let name = entrantsCitiesSingle.addressCity;

            if( name === '?' )
            {
                name = null;
            }

            entrantsCitiesData.push({
                index: entrantsCitiesCounter,
                image: entrantsCitiesSingle.addressCountry ? <Flag height="18" width="26" style={{display: 'flex'}} code={entrantsCitiesSingle.addressCountry} /> : '',
                name: name || <em>Unknown</em>,
                total: entrantsCitiesSingle.total,
                percentage: ( 100 / campaignData.getTotalEntrants() ) * entrantsCitiesSingle.total
            });
        });

        // Entrants Countries
        var entrantsCountriesData = [];
        var entrantsCountriesCounter = 0;

        entrantsCountries.forEach( entrantsCountriesSingle => {
            entrantsCountriesCounter++;

            if( entrantsCountriesCounter > 20 ) return;

            entrantsCountriesData.push({
                index: entrantsCountriesCounter,
                image: <Flag height="18" width="26" style={{display: 'flex'}} code={entrantsCountriesSingle.addressCountry} />,
                name: entrantsCountriesSingle.addressCountry ? getName(entrantsCountriesSingle.addressCountry) : <em>Unknown</em>,
                total: entrantsCountriesSingle.total,
                percentage: ( 100 / campaignData.getTotalEntrants() ) * entrantsCountriesSingle.total
            });
        });

    }

    if( campaignAnalyticsData && campaignAnalyticsData.webAnalyticsSources && campaignAnalyticsData.webAnalyticsSources.sources )
    {
        // Traffic Sources
        var webAnalyticsSourcesData = [];
        var webAnalyticsSourcesCounter = 0;

        var webAnalyticsTotal = 0;

        //campaignAnalyticsData.webAnalyticsTotalVisits

        campaignAnalyticsData.webAnalyticsSources.sources.forEach( webAnalyticsSourcesSingle => {
            webAnalyticsTotal+= webAnalyticsSourcesSingle.total;
        });

        if( webAnalyticsTotal < campaignAnalyticsData.webAnalyticsTotalVisits)
        {
            webAnalyticsTotal = campaignAnalyticsData.webAnalyticsTotalVisits;
        }

        campaignAnalyticsData.webAnalyticsSources.sources.forEach( webAnalyticsSourcesSingle => {
            webAnalyticsSourcesCounter++;

            if( webAnalyticsSourcesCounter > 20 ) return;

            webAnalyticsSourcesData.push({
                index: webAnalyticsSourcesCounter,
                name: webAnalyticsSourcesSingle.source,
                total: webAnalyticsSourcesSingle.total,
                percentage: ( 100 / webAnalyticsTotal ) * webAnalyticsSourcesSingle.total
            });
        });
    }

    let tabData = [
        {
            label: 'Overview',
            content: <>
                { ( !campaignDataLoading ) &&
                    <>
                        <Typography variant="h5">Daily Entries & Visits</Typography>
                        <ContentBox margin={true}>
                            <RenderDailyVisitsChart dateStart={campaignData.getStatisticsDateStart()} dateFinish={campaignData.getStatisticsDateFinish()} entrantData={campaignData.getStatistic('entrantsByDay') && campaignData.getStatistic('entrantsByDay').getData()} visitsData={campaignAnalyticsData && campaignAnalyticsData.webAnalyticsDailyVisits ? campaignAnalyticsData.webAnalyticsDailyVisits.dates : null} />
                        </ContentBox>

                        { entrantsBySourceTableData.length > 0 &&
                            <>
                                <Typography variant="h5">Channels</Typography>
                                <Typography>Users are given a variety a methods to enter campaigns.<br />This is a breakdown of which methods users chose in order to enter the campaign.</Typography>
                                <ContentBox margin={true}>
                                    <TableBarChart options={{label: 'Source'}} data={entrantsBySourceTableData} />
                                </ContentBox>
                            </>
                        }

                        { formEntriesByPlatformData.length > 0 &&
                            <>
                                <Typography variant="h5">Form Entry Services</Typography>
                                <ContentBox margin={true}>
                                    <TableBarChart options={{label: 'Source'}} data={formEntriesByPlatformData} />
                                </ContentBox>
                            </>
                        }
                    </>
                }
            </>
        },
        {
            label: 'Fan Data',
            content: <>
                { ( !campaignDataLoading ) &&
                    campaignData.getTotalEntrants() > 0 ?
                    <>
                        { entrantsByMailingListData.length > 0 &&
                            <>
                                <Typography variant="h5">Mailing Lists Subscribed To</Typography>
                                <Typography>When users enter the campaign they are given the option to subscribe to one or more mailing lists.<br />Because joining these mailing lists is optional (In some territories), this is a breakdown of which lists users decided to join.</Typography>
                                <ContentBox margin={true}>
                                    <TableBarChart options={{label: 'List ID'}} data={entrantsByMailingListData} />
                                </ContentBox>
                            </>
                        }

                        { entrantsCitiesData.length > 0 &&
                            <>
                                <Typography variant="h5">Cities</Typography>
                                <ContentBox margin={true}>
                                    <TableBarChart options={{label: 'City', imageLabel: 'Country'}} data={entrantsCitiesData} />
                                </ContentBox>
                            </>
                        }
                        { entrantsCountriesData.length > 0 &&
                            <>
                                <Typography variant="h5">Countries</Typography>
                                <ContentBox margin={true}>
                                    <TableBarChart options={{label: 'Country', imageLabel: ' '}} data={entrantsCountriesData} />
                                </ContentBox>
                            </>
                        }
                    </>
                    :
                    <Alert severity="info">No fans have engaged with this campaign yet.</Alert>
                }
            </>
        },
        {
            label: 'Fan List',
            content: <>

                { campaignEntrantsDataLoading && <ContentBox sx={{textAlign: 'center', marginTop: '58px'}}><CircularProgress sx={{margin: '10rem auto'}} /></ContentBox> }
                { ( !campaignEntrantsDataLoading && ( campaignEntrantsData && campaignEntrantsData.getTotal() > 0 ) ) ?
                    <>
                        <Typography variant="h5">Fan List</Typography>
                        <EntrantList data={campaignEntrantsData.getItems()} />
                        <TablePagination
                            component="div"
                            count={parseInt(campaignEntrantsData.getTotal())}
                            page={campaignEntrantsPage.page}
                            onPageChange={handleChangePage}
                            rowsPerPage={campaignEntrantsPage.perPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelDisplayedRows={labelDisplayedRows}
                        />
                    </>
                    :
                    <Alert severity="info">No fans have engaged with this campaign yet.</Alert>
                }
            </>
        },
        {
            label: 'Traffic',
            content: <>
                { ( !campaignDataLoading ) &&
                    <>
                        { ( webAnalyticsSourcesData && webAnalyticsSourcesData.length > 0 ) ?
                            <>
                                <Typography variant="h5">Traffic Sources</Typography>
                                <ContentBox margin={true}>
                                    <TableBarChart options={{label: 'Source'}} data={webAnalyticsSourcesData} />
                                </ContentBox>
                            </>
                            :
                            <Alert severity="info">There's no traffic source data to show right now.</Alert>
                        }
                    </>
                }
            </>
        }
    ];

    return (
        <>
        {
            campaignData &&
            <>
                <Hero image={campaignData.getDataValue(null, null, 'theme.image.url')}>
                    <CampaignHeaderButtons options={['toolkit', 'view', 'edit']} campaign={campaignData} />
                </Hero>
                <PageContainer>
                    <Typography variant="h4">{campaignData && campaignData.getFullName()}</Typography>

                    <Stack sx={{mt: 4}} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                        <Statistic sx={{width: '25%', mb: 0}} elevation={0}>
                            <Typography paragraph sx={{mb: 1, fontWeight: 'bold'}}>Page Visits</Typography>
                            <Typography paragraph sx={{mb: 0}}>{ campaignAnalyticsData ? Number(campaignAnalyticsData.webAnalyticsTotalVisits || 0).toLocaleString() : <CircularProgress size={17} /> }</Typography>
                        </Statistic>
                        <Statistic sx={{width: '25%', mb: 0}} elevation={0}>
                            <Typography paragraph sx={{mb: 1, fontWeight: 'bold'}}>Click Throughs</Typography>
                            <Typography paragraph sx={{mb: 0}}>{ campaignAnalyticsData ? ( Number(campaignAnalyticsData.webAnalyticsEvents && campaignAnalyticsData.webAnalyticsEvents.clicked_follow_button ? campaignAnalyticsData.webAnalyticsEvents.clicked_follow_button : 0 ) + entrantsBySourceFormTotal).toLocaleString() : <CircularProgress size={17} /> }</Typography>
                        </Statistic>
                        <Statistic sx={{width: '25%', mb: 0}} elevation={0}>
                            <Typography paragraph sx={{mb: 1, fontWeight: 'bold'}}>{ campaignData.getDataValue(null, null, 'preSave.enabled') ? 'Pre Saves' : 'Entries' }</Typography>
                            <Typography paragraph sx={{mb: 0}}>{campaignData.getTotalEntrants().toLocaleString()}</Typography>
                        </Statistic>
                        <Statistic sx={{width: '25%', mb: 0}} elevation={0}>
                            <Typography paragraph sx={{mb: 1, fontWeight: 'bold'}}>Mailing List Opt-Ins</Typography>
                            <Typography paragraph sx={{mb: 0}}>{campaignData.getTotalAcquisitions().toLocaleString()}</Typography>
                        </Statistic>
                    </Stack>

                    <TabGroup tabList={tabData} />
                </PageContainer>
            </>
        }
        </>
    );
};
