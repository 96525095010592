import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import { useAuthDispatch, logout, useAuthState } from '../../Context'
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
  
import Header from './Header';

import { campaignTypes } from '../../Config/campaigns';

const campaignTypeList = Array.from(campaignTypes, campaignType => {
  return {id: campaignType.title, link: '/campaigns/'+campaignType.value}
});

const navigationLinks = [
    { id: 'Home', link: '/' },
    { id: 'Audience Suite', link: '/dashboard' },
    {
        id: 'Pre-Release Schedule',
        link: '/releases',
        admin: true
    },
    {
      id: 'Campaigns',
      link: '/campaigns',
      children: campaignTypeList,
    },
    {
        id: 'Audience Builder',
        link: '/insights/audience-builder',
        children: [
            { id: 'Token Emails', link: '/insights' }
        ],
    },
    {
      id: 'Translations',
      link: '/translations/campaigns',
      children: [
          //{ id: 'Pre-Release Schedule', link: '/translations/releases' },
          { id: 'Campaigns', link: '/translations/campaigns' },
          //{ id: 'Token Emails', link: '/translations/token-emails' }
      ],
      admin: true
    },
    {
      id: 'Countries',
      link: '/countries'
    },
    {
      id: 'Teams',
      link: '/teams',
      admin: true
    },
    {
      id: 'Users',
      link: '/users',
      admin: true,
      children: [
          { id: 'Pending Approval', link: '/users/pending-approval' },
      ],
    },
    {
      id: 'Resources',
      link: '/resources',
      children: [
          { id: 'Getting Started Guide', link: '/resources' },
          { id: 'Campaign SDK', link: '/resources/campaign-sdk' },
      ],
  },
];

const drawerWidth = 300;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function Wrapper(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  
  const userDetails = useAuthState()

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
            <Grid
                justify="space-between"
                container 
                spacing={24}
            >
                <Grid sm={6} item>
                  {
                    userDetails.user &&
                      <IconButton
                          color="light"
                          aria-label="open drawer"
                          onClick={handleDrawerOpen}
                          edge="start"
                          sx={{ mr: 2, ...(open && { display: 'none' }) }}
                      >
                          <MenuIcon />
                      </IconButton>
                  }
                </Grid>
                <Grid sm={6} item>
                    <Header />
                </Grid>
            </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          '& .MuiTypography-body1': {
            fontSize: 14,
          }
        }}
        anchor="left"
        open={open}
        ModalProps={{
          onBackdropClick: () => {
            handleDrawerClose();
          }
        }}
      >
        <DrawerHeader>
          <IconButton color="light" onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <List disablePadding sx={{borderTop: '0.0625rem solid #323637'}}>
        {navigationLinks.map(({ id, children, link }) => (
          <Box key={id} sx={{borderBottom: '0.0625rem solid #323637'}}>
            <ListItem disablePadding sx={{borderBottom: '0.0625rem solid #323637'}}>
              <ListItemButton onClick={handleDrawerClose} component={Link} to={link}><ListItemText>{id}</ListItemText></ListItemButton>
            </ListItem>
            {children && children.map(({ id: childId, active, link }) => (
              <ListItem sx={{color: '#bcbcbc'}} disablePadding key={childId}>
                <ListItemButton sx={{pl: 3}} component={Link} to={link} selected={active}>
                  <ListItemText onClick={handleDrawerClose} sx={{m: 0}}>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
        ))}
        </List>
      </Drawer>
      {/* <Main sx={{ padding: '64px 0 0 0' }} open={open}> */ }
      <Main sx={{ padding: '64px 0 0 0' }} open={open}>
        {/* <DrawerHeader /> */ }
        {props.children}
      </Main>
    </Box>
  );
}