import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {
    CloudUpload as CloudUploadIcon
} from '@mui/icons-material';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import { LoadingButton } from '@mui/lab';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Container } from '@mui/material';
import { useAuthState } from '../../../../Context';

import { AcquisitionAPI } from '@warnerconnect/library';

export default function FileUpload(props) {
  const [file, setFile] = useState(props.value);
  const [loading, setLoading] = useState(false);

  const userDetails = useAuthState();

  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    onDrop: acceptedFiles => {
      setLoading(true);

      if( props && ( props.raw === true  ) )
      {
        if( props.onChange )
        {
            setFile(acceptedFiles[0].name);
            props.onChange(acceptedFiles[0]);
        }

        setLoading(false);
      }
      else
      {
        let formData = new FormData();           
        formData.append("file", acceptedFiles[0]);
  
        var acquisitionAPI = new AcquisitionAPI();
        acquisitionAPI.setBearerToken(userDetails.accessToken);

          acquisitionAPI.uploadFile(formData).then(fileResponse => {
            if( props.onChange )
            {
                setFile(fileResponse.file.url);
                props.onChange(fileResponse.file.url);
            }
  
            setLoading(false);
        });
      }

    }
    
  });

  return (
    <Card>
      <Typography sx={{backgroundColor: '#f4f4f4', p: 2}} variant="body1">{ file ? file : 'No file selected'}</Typography>
      <CardActions disableSpacing>

        <LoadingButton
          {...getRootProps({className: ''})}
          size="medium"
          loading={loading}
          loadingPosition="start"
          startIcon={<CloudUploadIcon />}
        >
              <input {...getInputProps()} />
              {loading ? 'Uploading' : file ? 'Change File' : 'Add File'}
          </LoadingButton>
          {
            file &&
            <Button
              color="error"
              size="medium"
              sx={{ml: 2}}
              startIcon={<DeleteIcon />}
              onClick={() => {
                setFile(null);

                if( props.onChange )
                {
                    props.onChange(null);
                }
              }}
            >
              Remove File
            </Button>
          }
      </CardActions>
    </Card>
    );
}
