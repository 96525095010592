import React, { Fragment, useState } from 'react';

import { Editor } from '@tinymce/tinymce-react';

export default function RichTextEditor(props) {
    const [formFieldData, setFormFieldData] = useState(props.value);

    var config = {
        menubar: false,
        branding: false,
        elementpath: false,
        statusbar: false,
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code'
        ],
        toolbar: 'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat code'
    };

    var size = props.size || 'standard';

    if( size === 'small' )
    {
        config.height = 100;
    }
    else if( size === 'medium' )
    {
        config.height = 200;
    }
    else if( size === 'auto' )
    {
        config.plugins+= ' autoresize'
        config.autoresize_bottom_margin = 1;
    }
    else
    {
        config.height = 500;
    }


    return (
        <Editor
        //onInit={(evt, editor) => editorRef.current = editor}
            {...props}
            apiKey="519x1z0sdomkni149t0yj73npsc1zhn4xbn5zarezuz3a3qi"
            init={config}
        />
    );
}