import React, { useState, useEffect, useReducer, Fragment } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';

import { AcquisitionRepository, EngagementAPI, EngagementRepository } from '@warnerconnect/library';

import { TablePagination, Button, Typography, CircularProgress, Link as MLink, Box, InputLabel, Tooltip, MenuItem, Select, FormControlLabel, Switch, Stack, Divider, Chip } from '@mui/material';

import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';

import { useAuthDispatch, logout, useAuthState, changeUserTeam } from '../../../Context';

import Statistic from '../../Elements/Statistic';
import useQuery from '../../Utilities/useQuery';
import useTitle from '../../Utilities/useTitle';
import PageContainer from '../../Elements/PageContainer';
import HeroText from '../../Elements/HeroText';
import Hero from '../../Elements/Hero';
import HeroButtonGroup from '../../Elements/HeroButtonGroup';
import HeroButton from '../../Elements/HeroButton';
import Loader from '../../Elements/Loader';

import dateFormat, { masks } from "dateformat";

import {
    Info as InfoIcon,
    AddBox as AddBoxIcon,
    CalendarMonth as CalendarMonthIcon,
    ArrowBack as ArrowBackIcon
} from '@mui/icons-material';

import CampaignsHeroImage from '../../../assets/media/hero/campaigns.jpg';
import Fieldset from '../../Elements/Forms/Fieldset';
import FieldContainer from '../../Elements/Forms/FieldContainer';
import ModalDialogue from '../../Elements/ModalDialogue';
import { LoadingButton } from '@mui/lab';
import LineChart from '../../Elements/Chart/LineChart';
import ContentBox from '../../Elements/ContentBox';

export default function InsightsStats() {
    useTitle('Audiecne Stats');

    var query = useQuery();
    const history = useHistory();

    const userDetails = useAuthState();

    const { audienceId } = useParams();

    const [audienceStatsLoading, setAudienceStatsLoading] = useState(true);
    const [audienceStatsData, setAudienceStatsData] = useState();

    const [audienceEntrantsLoading, setAudienceEntrantsLoading] = useState(true);
    const [audienceEntrantsData, setAudienceEntrantsData] = useState();

    const [audienceStatsByDayLoading, setAudienceStatsByDayLoading] = useState(true);
    const [audienceStatsByDayData, setAudienceStatsByDayData] = useState();
    const [audienceStatsByDayLabels, setAudienceStatsByDayLabels] = useState();

    const [audienceDataLoading, setAudienceLoading] = useState(true);
    const [audienceData, setAudienceData] = useState();

    const engagementRepository = new EngagementRepository();
    const engagementAPI = new EngagementAPI();
    engagementRepository.setBearerToken(userDetails.accessToken);
    engagementAPI.setBearerToken(userDetails.accessToken);

    // Detect path change to hand page edit
    useEffect(() => {

        setAudienceLoading(true);
        setAudienceStatsLoading(true);
        setAudienceStatsByDayLoading(true);

        engagementRepository.getAudience(audienceId, {include: ['emailTemplate', 'language']}).then( audience => {
            setAudienceData(audience);
            setAudienceLoading(false);

            engagementAPI.getAudienceStatsTotal(audienceId).then(statsResponse => {
                setAudienceStatsData(statsResponse);
                setAudienceStatsLoading(false);
            });

            engagementAPI.getAudienceEntrantsTotal(audienceId).then(statsResponse => {
                setAudienceEntrantsData(statsResponse);
                setAudienceEntrantsLoading(false);
            });

            engagementAPI.getAudienceStatsTotalByDay(audienceId).then(statsResponse => {
                let labels = [];
                let data = {};

                statsResponse = statsResponse.filter(item => ['Open', 'Click'].includes(item.action));

                let dateRangeDays = 14;

                let startDate = audience.getSendDateTime();
                let endDate = new Date(startDate.getTime());
                    endDate.setDate(endDate.getDate() + dateRangeDays);

                let dateCursor = new Date(startDate.getTime());

                while( dateCursor < endDate )
                {
                    let dateKey = dateFormat(dateCursor, "yyyy-mm-dd");
                    labels.push(dateKey);

                    statsResponse.forEach(item => {
                        if( !data.hasOwnProperty(item.action) )
                        {
                            data[item.action] = {label: item.action, data: []};
                        }

                        let itemDay = item.data.find(itemDate => itemDate.date == dateKey);
                        let itemTotal = itemDay ? parseInt(itemDay.total) : 0;

                        data[item.action]['data'].push(itemTotal);
                    });

                    dateCursor.setDate(dateCursor.getDate() + 1);
                }

                let dataNew = [];

                Object.keys(data).forEach(dataKey => {
                    dataNew.push(data[dataKey]);
                });

                setAudienceStatsByDayLabels(labels);
                setAudienceStatsByDayData(dataNew);
                setAudienceStatsByDayLoading(false);
            });
        });

    }, []);

    return (
        <>
            <Hero image={CampaignsHeroImage}>
                <HeroButtonGroup>
                    <HeroButton component={Link} to={`/insights`} color="secondary">Back to<br />Schedule<CalendarMonthIcon /></HeroButton>
                </HeroButtonGroup>
            </Hero>
            <PageContainer>

            { audienceDataLoading && <Loader /> }
            
            { !audienceDataLoading &&
                <>
                    <Typography variant="h4">{ audienceData.getFullName() }</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            mt: 1,
                            '& > .MuiChip-root': {
                                mx: .2,
                            },
                            '& > .MuiDivider-root': {
                                mx: 1,
                            },
                        }}
                    >
                        <Chip
                            color={ ( audienceEntrantsData && (audienceEntrantsData.totalSent === audienceEntrantsData.total) ) ? 'success' : 'primary' }
                            label={
                                <>
                                    <><strong>Status:</strong> </>
                                    {
                                        audienceEntrantsLoading ?
                                            <CircularProgress size={17} />
                                        :
                                            <>
                                                { ((audienceEntrantsData.total > 0) && (audienceEntrantsData.totalSent === audienceEntrantsData.total)) && 'Sent'}
                                                { (audienceEntrantsData.totalSent === 0) && 'Waiting to Send'}
                                                { ((audienceEntrantsData.totalSent > 0) && (audienceEntrantsData.totalSent < audienceEntrantsData.total)) && 'Sending'}
                                            </>
                                            
                                    }
                                </>
                            }
                        />

                        <Divider orientation="vertical" flexItem />

                        <Chip label={<><strong>Audience Size:</strong> {audienceEntrantsLoading ? <CircularProgress size={17} /> : audienceEntrantsData.total.toLocaleString()}</>} />

                        <Divider orientation="vertical" flexItem />

                        {
                            audienceData.getQueryData() && audienceData.getQueryData().find(filter => ( filter.context === 'countries' && filter.type === 'include' ) ) ?
                                audienceData.getQueryData().find(filter => ( filter.context === 'countries' && filter.type === 'include' ) ).value.map(country => (
                                    <Fragment key={country.name}><Chip label={country.name} /> </Fragment>
                                ))
                            :
                                    <Chip label="Global" />
                        }

                        {
                            audienceData.getEmailData() &&
                            <>
                                {
                                    audienceData.getEmailData().artist &&
                                        <>
                                            <Divider orientation="vertical" flexItem />
                                            <Chip label={audienceData.getEmailData().artist.label} />
                                        </>

                                }

                                {
                                    audienceData.getEmailData().playlist &&
                                        <>
                                            <Divider orientation="vertical" flexItem />
                                            <Chip label={audienceData.getEmailData().playlist.label} />
                                        </>

                                }
                            </>
                        }

                        <Divider orientation="vertical" flexItem />
                        <Chip label={<><strong>Send Date & Time:</strong>  {audienceData.getSendDateTime().toLocaleString()}</>} />

                    </Box>

                    <Stack sx={{mt: 4}} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                        <Statistic sx={{width: '25%', mb: 0}} elevation={0}>
                            <Typography paragraph sx={{mb: 1, fontWeight: 'bold'}}>Sent</Typography>
                            <Typography paragraph sx={{mb: 0}}>
                                { ( !audienceStatsLoading && audienceStatsData ) ?
                                    audienceStatsData.find(audienceStat => audienceStat.action === 'Sent') ? Number(audienceStatsData.find(audienceStat => audienceStat.action === 'Sent').total).toLocaleString() : 0
                                :
                                    <CircularProgress size={17} />
                                }
                            </Typography>
                        </Statistic>
                        <Statistic sx={{width: '25%', mb: 0}} elevation={0}>
                            <Typography paragraph sx={{mb: 1, fontWeight: 'bold'}}>Opened</Typography>
                            <Typography paragraph sx={{mb: 0}}>
                                { ( !audienceStatsLoading && audienceStatsData ) ?
                                    audienceStatsData.find(audienceStat => audienceStat.action === 'Open') ? Number(audienceStatsData.find(audienceStat => audienceStat.action === 'Open').total).toLocaleString() : 0
                                :
                                    <CircularProgress size={17} />
                                }
                            </Typography>
                        </Statistic>
                        <Statistic sx={{width: '25%', mb: 0}} elevation={0}>
                            <Typography paragraph sx={{mb: 1, fontWeight: 'bold'}}>Clicked</Typography>
                            <Typography paragraph sx={{mb: 0}}>
                                { ( !audienceStatsLoading && audienceStatsData ) ?
                                    audienceStatsData.find(audienceStat => audienceStat.action === 'Click') ? Number(audienceStatsData.find(audienceStat => audienceStat.action === 'Click').total).toLocaleString() : 0
                                :
                                    <CircularProgress size={17} />
                                }
                            </Typography>
                        </Statistic>
                        <Statistic sx={{width: '25%', mb: 0}} elevation={0}>
                            <Typography paragraph sx={{mb: 1, fontWeight: 'bold'}}>Unsubscribed</Typography>
                            <Typography paragraph sx={{mb: 0}}>
                                { ( !audienceStatsLoading && audienceStatsData ) ?
                                    audienceStatsData.find(audienceStat => audienceStat.action === 'Unsubscribe') ? Number(audienceStatsData.find(audienceStat => audienceStat.action === 'Unsubscribe').total).toLocaleString() : 0
                                :
                                    <CircularProgress size={17} />
                                }
                            </Typography>
                        </Statistic>
                    </Stack>

                    { !audienceStatsByDayLoading &&
                        <ContentBox margin={true}><LineChart labels={audienceStatsByDayLabels} data={audienceStatsByDayData} /></ContentBox>
                    }
                 </>   
            }
                </PageContainer>
        </>
    );
};
