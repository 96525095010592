import * as React from 'react';

import Colors from './Colors';
import Grid from '@mui/material/Grid';

import * as dayjs from 'dayjs'

import { Line, Utils } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  } from 'chart.js';

export default function LineChart(props) {
  ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Filler,
      Tooltip,
      Legend
  );

  let labels = props.labels;

  let dataList = [];
  let dataListMax = 0;

  props.data.forEach( (item, itemIndex) => {
    let dataListMaxCurrent = Math.max( ...item.data );

    if( dataListMaxCurrent > dataListMax )
    {
        dataListMax = dataListMaxCurrent;
    }

    dataList.push({
        label: item.label,
        data: item.data,
        borderColor: Colors[itemIndex].borderColor,
        pointBackgroundColor: Colors[itemIndex].pointBackgroundColor,
        backgroundColor: Colors[itemIndex].backgroundColor,
        lineTension: 0.3,
        pointRadius: 4,
        pointHoverRadius: 6,
        fill: true
    });
  });

  dataListMax = dataListMax > 50 ? null : 50;

  const options = {
      responsive: true,
      plugins: {
          legend: {
              position: 'top',
              labels: {
                  usePointStyle: true,
                  boxWidth: 6
              }
          },
      },
      interaction: {
          intersect: false,
          mode: 'index'
      },
      scales: {
          y: {
              max: dataListMax
          }
      }
  };

  const data = {
      labels,
      datasets: dataList,
  };

  return (
      <Line options={options} data={data} />
  )
}