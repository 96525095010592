import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AcquisitionAPI } from '@warnerconnect/library';

import { TablePagination, Button, Typography, CircularProgress, Link as MLink, Grid } from '@mui/material';

import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';

import { useAuthDispatch, logout, useAuthState, changeUserTeam } from '../../../Context';

import Loader from '../../Elements/Loader';
import CampaignList from '../../Elements/CampaignList';
import useQuery from '../../Utilities/useQuery';
import useTitle from '../../Utilities/useTitle';
import ContentBox from '../../Elements/ContentBox';
import PageContainer from './../../Elements/PageContainer';
import HeroText from '../../Elements/HeroText';
import Hero from '../../Elements/Hero';
import HeroButtonGroup from '../../Elements/HeroButtonGroup';
import HeroButton from '../../Elements/HeroButton';
import TableBarChart from '../../Elements/TableBarChart';
import BarChart from '../../Elements/Chart/BarChart';
import LineChart from '../../Elements/Chart/LineChart';

import CampaignsHeroImage from '../../../assets/media/hero/campaigns.jpg';

export default function CampaignsDashboard() {
    useTitle('Campaigns Dashboard');

    let query = useQuery();

    const userDetails = useAuthState()

    const [entrantsByDay, setEntrantsByDay] = useState();
    const [entrantsByDayLoading, setEntrantsByDayLoading] = useState(true);

    const [entrantsByWeek, setEntrantsByWeek] = useState();
    const [entrantsByWeekLoading, setEntrantsByWeekLoading] = useState(true);

    const [entrantsGrowthByWeek, setEntrantsGrowthByWeek] = useState();
    const [entrantsGrowthByWeekLoading, setEntrantsGrowthByWeekLoading] = useState(true);

    useEffect(() => {
        let acquisitionAPI = new AcquisitionAPI();

        let dateFrom = new Date(2021, 9, 1);
        let dateTo = new Date(2022, 8, 30);

        setEntrantsByDayLoading(true);

        acquisitionAPI.getTotalEntrantsByDay(dateFrom, dateTo).then(response => {
            let entrantsPerDayLabels = [];
            let entrantsPerDayData = [];
    
            response.forEach(singleDay => {
                entrantsPerDayLabels.push(singleDay.date);
                entrantsPerDayData.push(singleDay.total);
            });

            // Last 28 Days
            setEntrantsByDay({
                labels: entrantsPerDayLabels.slice(-28),
                data: entrantsPerDayData.slice(-28)
            });

            // By Week
            let entrantsPerWeekLabels = [];
            let entrantsPerWeekData = [];

            let entrantsGrowthPerWeekLabels = [];
            let entrantsGrowthPerWeekData = [];

            let entrantsPerWeekChunks = response
                .reduce((all,one,i) => {
                    const ch = Math.floor(i/7); 
                    all[ch] = [].concat((all[ch]||[]),one); 
                    return all
                }, []);
                
            var total = 0;
            
            entrantsPerWeekChunks.forEach(weekData => {
                    var totalWeekly = 0;

                    weekData.forEach(dayData => {
                        totalWeekly = totalWeekly + dayData.total;
                        total = total + dayData.total;
                    });

                    entrantsGrowthPerWeekLabels.push(weekData[0].date);
                    entrantsPerWeekLabels.push(weekData[0].date);
                    entrantsGrowthPerWeekData.push(total);
                    entrantsPerWeekData.push(totalWeekly);
                });

            setEntrantsGrowthByWeek({
                labels: entrantsGrowthPerWeekLabels,
                data: {
                    label: 'Entrants',
                    data: entrantsGrowthPerWeekData
                }
            });
            
            setEntrantsByWeek({
                labels: entrantsPerWeekLabels,
                data: entrantsPerWeekData
            });
    
            setEntrantsByDayLoading(false);
        });
    }, []);

    return (
        <>
            <Hero image={CampaignsHeroImage}>
                <HeroText>
                    <Typography variant="h4" sx={{mb: 1}}>Dashboard</Typography>
                    <Typography paragraph>Here you can view the overall stats for your current team, for each financial year.</Typography>
                    <Typography variant="button"><MLink component={Link} to="/resources">Learn More</MLink></Typography>
                </HeroText>
                <HeroButtonGroup>
                    <HeroButton color="secondary">Create Campaign<AddCircleOutlineIcon /></HeroButton>
                </HeroButtonGroup>
            </Hero>
            <PageContainer>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Fan Growth by Week</Typography>
                        <ContentBox margin={true}>
                        { entrantsByDayLoading && <Loader /> }
                        {
                            !entrantsByDayLoading &&
                                <LineChart labels={entrantsGrowthByWeek.labels} data={[entrantsGrowthByWeek.data]} />
                        }
                        </ContentBox>

                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Typography variant="h5">New Fans This Month</Typography>
                        <ContentBox margin={true}>
                        { entrantsByDayLoading && <Loader size="small" /> }
                        {
                            !entrantsByDayLoading &&
                                <BarChart labels={entrantsByDay.labels} data={[entrantsByDay.data]} />
                        }
                        </ContentBox>

                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Typography variant="h5">New Fans by Week</Typography>
                        <ContentBox margin={true}>
                        { entrantsByDayLoading && <Loader size="small" /> }
                        {
                            !entrantsByDayLoading &&
                                <BarChart labels={entrantsByWeek.labels} data={[entrantsByWeek.data]} />
                        }
                        </ContentBox>
                    </Grid>
                </Grid>
            </PageContainer>
        </>
    );
};
