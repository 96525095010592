import React, { useState, useEffect, useReducer, Fragment } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AcquisitionAPI, AcquisitionRepository } from '@warnerconnect/library';

import { TablePagination, Button, Typography, CircularProgress, Link as MLink, Box, InputLabel, Tooltip, MenuItem, Select, FormControlLabel, Switch, TextField, InputAdornment, Divider, Grid } from '@mui/material';

import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';

import { useAuthDispatch, logout, useAuthState, changeUserTeam } from '../../../Context';

import CampaignTranslationList from '../../Elements/CampaignTranslationList';
import useQuery from '../../Utilities/useQuery';
import useTitle from '../../Utilities/useTitle';
import PageContainer from '../../Elements/PageContainer';
import HeroText from '../../Elements/HeroText';
import Hero from '../../Elements/Hero';
import HeroButtonGroup from '../../Elements/HeroButtonGroup';
import HeroButton from '../../Elements/HeroButton';
import Loader from '../../Elements/Loader';

import OptInTypeComplex from '../../../assets/img/optin.type.complex.png'
import OptInTypeComplexActive from '../../../assets/img/optin.type.complex.on.png'

import OptInTypeSimple from '../../../assets/img/optin.type.simple.png'
import OptInTypeSimpleActive from '../../../assets/img/optin.type.simple.on.png'

import OptInTypeSignUp from '../../../assets/img/optin.type.signup.png'
import OptInTypeSignUpActive from '../../../assets/img/optin.type.signup.on.png'

import { useSnackbar } from "notistack";

import { formReducer } from '../../../Context/formReducer';

import {
    Info as InfoIcon,
    AddBox as AddBoxIcon,
    ArrowBack as ArrowBackIcon,
    Save as SaveIcon
} from '@mui/icons-material';

import { campaignFooterLinks } from '../../../Config/campaigns';
import { supportedIntegrations } from '../../Utilities/Services';

import CampaignsHeroImage from '../../../assets/media/hero/campaigns.jpg';
import Fieldset from '../../Elements/Forms/Fieldset';
import FieldContainer from '../../Elements/Forms/FieldContainer';
import ModalDialogue from '../../Elements/ModalDialogue';
import { LoadingButton } from '@mui/lab';
import TabGroup from '../../Elements/TabGroup';
import FormBuilderFields from '../../Elements/Forms/Fields/FormBuilderFields';
import { flattenObject } from '../../Utilities/Utilities';
import ImageUpload from '../../Elements/Forms/Fields/ImageUpload';
import RichTextEditor from '../../Elements/Forms/Fields/RichTextEditor';
import OptIn from '../../Elements/Forms/Fields/OptIn';
import ImageRadio from '../../Elements/Forms/Fields/ImageRadio';

export default function TranslationsCampaigns() {
    useTitle('Campaign Translations');

    const { enqueueSnackbar } = useSnackbar();

    const perPage = 10;

    var query = useQuery();
    const history = useHistory();

    const userDetails = useAuthState();

    if( !query.get('page') )
    {
        query.set('page', 0)
    }

    if( !query.get('perPage') )
    {
        query.set('perPage', 25)
    }

    const location = useLocation();

    //const [campaignPage, setCampaignPage] = useState({page: query.get('page') || 0, perPage: query.get('perPage') || 25});
    const [translationData, setTranslationData] = useState();
    const [translationDataLoading, setTranslationDataLoading] = useState(false);

    const [countryData, setCountryData] = useState();
    const [countryDataLoading, setCountryDataLoading] = useState(true);

    const [translationCreateModal, setTranslationCreateModal] = useState(false);
    const [translationCreateLoading, setTranslationCreateLoading] = useState(false);
    const [translationCreateSubmitting, setTranslationCreateSubmitting] = useState(false);

    const [languageData, setLanguageData] = useState();
    const [languageDataLoading, setLanguageLoading] = useState(true);

    const [translationEdit, setTranslationEdit] = useState();
    const [translationEditModal, setTranslationEditModal] = useState(false);
    const [translationEditLoading, setTranslationEditLoading] = useState(false);
    const [translationEditSubmitting, setTranslationEditSubmitting] = useState(false);

    const [translationEditData, setTranslationEditData] = useReducer(formReducer, {});

    const [translationEditTextData, setTranslationEditTextData] = useReducer(formReducer, {});

    const [translationFormData, setTranslationFormData] = useReducer(formReducer, {});

    const handleSubmitTranslationEdit = () => {
        setTranslationEditSubmitting(true);
        console.log(translationEditTextData);

        if( translationEditTextData.hasOwnProperty('optIn.format') )
        {
            delete translationEditTextData['optIn.format'];
        }

        Object.keys(translationEditTextData).forEach(configurationKey => {
            var value = translationEditTextData[configurationKey];

            translationEdit.setTextValue(configurationKey, value);
        });

        let updateTranslationData = {
            language: translationEditData['language'],
            text: translationEdit.getText(),
            isGlobal: translationEditData['isGlobal']
        };

        if( translationEditData['country'] )
        {
            updateTranslationData['country'] = translationEditData['country'];
        }
        console.log(translationEditTextData);

        acquisitionRepository.updateTranslation(translationEdit.getId(), updateTranslationData)
            .then(translationUpdate => {
                setTranslationEditSubmitting(false);
                
                enqueueSnackbar("Campaign translation updated", {
                    variant: 'success'
                });
            });
    };

    
    const [translationDataEvents, setTranslationDataEvents] = useState(0);

    const refreshTranslationData = event => {
        setTranslationDataEvents(translationDataEvents + 1);
    }
    
    const setTranslationEditDataSingle = (name, value) => {
        setTranslationEditData({ name: name, value: value });
    };

    const setTranslationEditTextDataSingle = (name, value) => {
        setTranslationEditTextData({ name: name, value: value });
    };

    const setTranslationFormDataSingle = (name, value) => {
        setTranslationFormData({ name: name, value: value });
    };

    const closeTranslationEditModal = event => {
        setTranslationEditData({ type: 'reset' });
        setTranslationEditTextData({ type: 'reset' });

        history.push('/translations/campaigns');
    }
    
    const acquisitionRepository = new AcquisitionRepository();
    const acquisitionAPI = new AcquisitionAPI();

    acquisitionAPI.setBearerToken(userDetails.accessToken);
    acquisitionRepository.setBearerToken(userDetails.accessToken);

    const handleChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setTranslationFormDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    const handleTranslationEditChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setTranslationEditData(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    const handleTranslationEditTextChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setTranslationEditTextDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    const toggleTranslationCreateModal = event => {
        setTranslationCreateModal(!translationCreateModal);
    }
    
    // Detect path change to hand page edit
    useEffect(() => {
        let locationParts = location.pathname.split('/');

        setTranslationEditLoading(false);
        setTranslationEditModal(false);
        setTranslationEditData({});

        if( locationParts[3] && locationParts[4] && ( locationParts[4] === 'edit' ) )
        {
            setTranslationEditLoading(true);
            setTranslationEditModal(true);

            let translationId = parseInt(locationParts[3]);

            acquisitionRepository.getTranslation(translationId, {include: ['country', 'language']}).then( translation => {
                setTranslationEdit(translation);

                setTranslationEditDataSingle('isGlobal', translation.getIsGlobal());
                setTranslationEditDataSingle('country', translation.getCountry() ? translation.getCountry().getId() : null);
                setTranslationEditDataSingle('language', translation.getLanguage() ? translation.getLanguage().getId() : null);

                var language = flattenObject(translation.getText());

                Object.keys(language).forEach(languageKey => {
                    setTranslationEditTextDataSingle(languageKey, language[languageKey]);
                });

                setTranslationEditLoading(false);
            });
        }
        else
        {

        }

    }, [location]);

    useEffect(() => {
        setTranslationDataLoading(true);

        let limit = query.get('perPage');

        let offset = query.get('page') !== 0 ? ( query.get('page') * query.get('perPage') ) : 0;

        let criteria = {};

        let options = {
            limit: limit,
            offset: offset,
            include: ['country', 'language']
        };

        acquisitionRepository.getTranslations(options, criteria).then( response => {
            setTranslationData(response);
            setTranslationDataLoading(false);
        });
    }, [userDetails.team, query.get('perPage'), query.get('page'), translationDataEvents]);

    useEffect(() => {
        acquisitionRepository.getCountries({limit: 200})
            .then( countries => {
                setCountryData(countries);
                setCountryDataLoading(false);
            });

        acquisitionRepository.getLanguages({limit: 200})
            .then( languages => {
                setLanguageData(languages);
                setLanguageLoading(false);
            });

    }, []);

    function handleChangePage(event){
        query.set('page', event.target.value);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function handleChangeRowsPerPage(event){
        query.set('perPage', event.target.value);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function labelDisplayedRows( { from, to, count, page } ){
        return `${from}–${to} of ${count !== -1 ? count.toLocaleString() : `more than ${to}`}`;
    }

    return (
        <>
            <ModalDialogue open={translationEditModal} size="large" onClose={closeTranslationEditModal}>
                <Box sx={{px: 2, pt: 2, pb: 1}}>
                    <Typography variant="h4">Edit Translation</Typography>                    
                </Box>
                { translationEditLoading ?
                    <Loader size="x-small" />
                    :
                    <>
                        <TabGroup
                            tabList={[
                                {
                                    label: 'Settings',
                                    content: <>
                                        <Fieldset>
                        
                                            <FieldContainer>
                                                <InputLabel shrink htmlFor="language">Language <Tooltip title={<>You can add additional languages later but this should specify the campaign's main language.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                                { languageData &&
                                                    <Select
                                                        id="language"
                                                        name="language"
                                                        onChange={handleTranslationEditChange}
                                                        value={translationEditData['language'] || ''}
                                                        fullWidth
                                                    >
                                                        <MenuItem value="">None</MenuItem>

                                                        {languageData.getItems().map((language) => (
                                                            <MenuItem key={language.getId()} value={language.getId()}>{language.getName()}</MenuItem>
                                                        ))}
                                                    </Select>
                                                }
                                            </FieldContainer>

                                            <FieldContainer>
                                                <InputLabel shrink>Global Language <Tooltip title={<>Is this the global version of the lagnuage or a territory specific version?</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                <FormControlLabel control={<Switch name="isGlobal" onChange={handleTranslationEditChange} checked={translationEditData['isGlobal'] !== true ? false : true} />} label="Yes" />
                                            </FieldContainer>

                                            { ( translationEditData['isGlobal'] !== true ) &&
                                                <FieldContainer>
                                                    <InputLabel shrink htmlFor="country">Country <Tooltip title={<>This is the primary country of this language.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                                    { countryData &&
                                                        <Select
                                                            id="country"
                                                            name="country"
                                                            onChange={handleTranslationEditChange}
                                                            value={translationEditData['country'] || ''}
                                                            fullWidth
                                                        >
                                                            <MenuItem value="">None</MenuItem>

                                                            {countryData.getItems().map((country) => (
                                                                <MenuItem key={country.getId()} value={country.getId()}>{country.getName()}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    }
                                                </FieldContainer>
                                            }

                                        </Fieldset>
                                    </>
                                },
                                {
                                    label: 'Form Entry',
                                    content: <>
                                        <Fieldset>
                                            <FieldContainer>
                                                <InputLabel shrink htmlFor="formEntry.entryButton.text">Button Text <Tooltip title={<>Text to prompt user to fill in the alternate entry form. Leave blank to default to <strong>"Not on Spotify? Enter here."</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                <TextField
                                                    name="formEntry.entryButton.text"
                                                    fullWidth
                                                    placeholder="Not on Spotify? Enter here."
                                                    value={translationEditTextData['formEntry.entryButton.text'] || ''}
                                                    onChange={handleTranslationEditTextChange}
                                                />
                                            </FieldContainer>

                                            <FieldContainer xs={12}>
                                                <FormBuilderFields name="formEntry.fields" onChange={(newValue) => { setTranslationEditTextDataSingle('formEntry.fields', newValue); }} value={translationEditTextData['formEntry.fields']} />
                                            </FieldContainer>

                                            <FieldContainer xs={6}>
                                                <InputLabel shrink htmlFor="formEntry.backButton.text">Back Button Text <Tooltip title={<>Text for back button on alternate entry form. Defaults to <strong>"Back"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                <TextField
                                                    name="formEntry.backButton.text"
                                                    fullWidth
                                                    placeholder="Back"
                                                    value={translationEditTextData['formEntry.backButton.text'] || ''}
                                                    onChange={handleTranslationEditTextChange}
                                                />
                                            </FieldContainer>

                                            <FieldContainer xs={6}>
                                                <InputLabel shrink htmlFor="formEntry.submitButton.text">Submit Button Text <Tooltip title={<>Text for enter button on alternate entry form. Defaults to <strong>"Enter Competition"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                <TextField
                                                    name="formEntry.submitButton.text"
                                                    fullWidth
                                                    placeholder="Enter Competition"
                                                    value={translationEditTextData['formEntry.submitButton.text'] || ''}
                                                    onChange={handleTranslationEditTextChange}
                                                />
                                            </FieldContainer>

                                            <FieldContainer xs={6}>
                                                <InputLabel shrink>Facebook Button Prompt Text? <Tooltip title={<>This text is displayed before the Facebook CTA inviting the user to fill in the form or enter with Facebook.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                <TextField
                                                    name="formEntry.facebook.label.text"
                                                    fullWidth
                                                    placeholder="Fill in the form below or"
                                                    value={translationEditTextData['formEntry.facebook.label.text'] || ''}
                                                    onChange={handleTranslationEditTextChange}
                                                />
                                            </FieldContainer>

                                            <FieldContainer xs={6}>
                                                <InputLabel shrink>Facebook Button Text? <Tooltip title={<>This is the text that appears on the Facebook CTA.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                <TextField
                                                    name="formEntry.facebook.button.text"
                                                    fullWidth
                                                    placeholder="Enter with Facebook"
                                                    value={translationEditTextData['formEntry.facebook.button.text'] || ''}
                                                    onChange={handleTranslationEditTextChange}
                                                />
                                            </FieldContainer>
                            
                                            {/*<FieldContainer xs={12}>
                                                <FormBuilderFields name="additionalDataForm.fields" onChange={(newValue) => { setTranslationEditDataSingle('additionalDataForm.fields', newValue); }} value={translationEditData['additionalDataForm.fields']} />
                                </FieldContainer>*/}

                                        </Fieldset>
                                    </>
                                },
                                {
                                    label: 'Additional Data',
                                    content: <>
                                        <Fieldset>

                                            <FieldContainer xs={6}>
                                                <InputLabel shrink htmlFor="additionalDataForm.submitButton.text">Button Text <Tooltip title={<>Text that will appear on the form\'s "Submit" button.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                <TextField
                                                    value={translationEditTextData['additionalDataForm.submitButton.text'] || ''}
                                                    name="additionalDataForm.submitButton.text"
                                                    placeholder="Save & Continue"
                                                    fullWidth
                                                    onChange={handleTranslationEditTextChange}
                                                />
                                            </FieldContainer>

                                            <FieldContainer xs={6}>
                                                <InputLabel shrink htmlFor="additionalDataForm.content">Title <Tooltip title={<>Small piece of text that will appear above the form, prompting user to enter their details.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                <TextField
                                                    value={translationEditTextData['additionalDataForm.content'] || ''}
                                                    name="additionalDataForm.content"
                                                    placeholder="Enter your details below to continue."
                                                    fullWidth
                                                    onChange={handleTranslationEditTextChange}
                                                />
                                            </FieldContainer>

                                            <FieldContainer xs={12}>
                                                <FormBuilderFields name="additionalDataForm.fields" onChange={(newValue) => { setTranslationEditTextDataSingle('additionalDataForm.fields', newValue); }} value={translationEditTextData['additionalDataForm.fields']} />
                                            </FieldContainer>

                                        </Fieldset>
                                    </>
                                },
                                {
                                    label: 'Footer',
                                    content: <>
                                        <Fieldset>                    

                                            {
                                                campaignFooterLinks
                                                    .map( (footerLink, footerLinkIndex) => (
                                                        <FieldContainer xs={6} key={footerLinkIndex}>
                                                            <InputLabel shrink>{footerLink.label}</InputLabel>
                                                            <TextField
                                                                value={translationEditTextData['footer.buttons.'+footerLink.id+'.url'] || ''}
                                                                name={`footer.buttons.${footerLink.id}.url`}
                                                                disabled={!(translationEditTextData['footer.buttons.'+footerLink.id+'.enabled'] || false)}
                                                                fullWidth
                                                                onChange={handleTranslationEditTextChange}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment sx={{opacity: !(translationEditTextData['footer.buttons.'+footerLink.id+'.enabled'] || false) ? .38 : 1}} position="start">
                                                                            {footerLink.icon}
                                                                        </InputAdornment>
                                                                    ),
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <Switch name={`footer.buttons.${footerLink.id}.enabled`} onChange={handleTranslationEditTextChange} checked={translationEditTextData['footer.buttons.'+footerLink.id+'.enabled'] ? true : false} />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </FieldContainer>
                                                    ))
                                            }

                                            <FieldContainer xs={12}>
                                                <Divider />
                                            </FieldContainer>

                                            <FieldContainer xs={6}>
                                                <InputLabel shrink>Footer Links <Tooltip title={<>Terms & Conditions & Privacy Policy links are added to the footer automatically. You can use this field if you\'d like to replace these links with custom text.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                                <RichTextEditor
                                                    value={translationEditTextData['footer.link'] || ''}
                                                    onEditorChange={(value) => {
                                                        setTranslationEditTextDataSingle('footer.link', value);
                                                    }}
                                                    name="footer.link"
                                                    size="small"
                                                />
                                            </FieldContainer>

                                        </Fieldset>

                                    </>
                                },
                                {
                                    label: 'Content',
                                    content: <>
                                        <Fieldset>
                                            <FieldContainer xs={12}>
                                                <InputLabel shrink>Bottom Description (Competition) <Tooltip title={<>This text appears at the bottom of all campaigns flagged as being a competition.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                <RichTextEditor
                                                    value={translationEditTextData['landingPage.bottomContentCompetition']}
                                                    onEditorChange={(value) => {
                                                        setTranslationEditTextDataSingle('landingPage.bottomContentCompetition', value);
                                                    }}
                                                    fullWidth
                                                    size="medium"
                                                    name="landingPage.bottomContentCompetition"
                                                />

                                            </FieldContainer>

                                        </Fieldset>
                                    </>
                                },
                                {
                                    label: 'Buttons',
                                    content: <>
                                        {
                                            supportedIntegrations.map( ( integrationData, integrationIndex ) => {
                                                    let integration = integrationData.id;

                                                    return (
                                                        <Fragment key={integration}>
                                                            <Grid container>
                                                                <Grid item xs={6}>

                                                                    <Fieldset>
                                                                        <FieldContainer xs={12}>
                                                                            <InputLabel shrink htmlFor={`integrations.${integration}.text.default`}>{integrationData.label} (Default) <Tooltip title={<>This text will appear on the call to action button. If no text is entered it will default to "Follow to Enter".</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                                            <TextField
                                                                                value={translationEditTextData['integrations.'+integration+'.text.default'] || ''}
                                                                                name={`integrations.${integration}.text.default`}
                                                                                placeholder={integrationData.label}
                                                                                fullWidth
                                                                                onChange={handleTranslationEditTextChange}
                                                                            />
                                                                        </FieldContainer>
                                                                        
                                                                        <FieldContainer xs={12}>
                                                                            <InputLabel shrink htmlFor={`integrations.${integration}.text.preSave`}>{integrationData.label} (Pre Save) <Tooltip title={<>This text will appear on the call to action button. If no text is entered it will default to "Follow to Enter".</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                                            <TextField
                                                                                value={translationEditTextData['integrations.'+integration+'.text.preSave'] || ''}
                                                                                name={`integrations.${integration}.text.preSave`}
                                                                                placeholder={integrationData.label}
                                                                                fullWidth
                                                                                onChange={handleTranslationEditTextChange}
                                                                            />
                                                                        </FieldContainer>
                                                                    </Fieldset>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    container
                                                                    xs={6}
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    direction="row"
                                                                >
                                                                    <Button
                                                                        startIcon={integrationData.icon}
                                                                        size="large"
                                                                        disableRipple
                                                                        variant="contained"
                                                                    >{translationEditTextData['integrations.'+integration+'.text.default'] || integrationData.label}</Button>
                                                                </Grid>
                                                                <Grid item xs={12} sx={{p: 2}}>
                                                                    <Divider />
                                                                </Grid>
                                                            </Grid>
                                                        </Fragment>
                                                    );
                                                })

                                        }
                                    </>
                                },
                                {
                                    label: 'Opt-In',
                                    content: <>
                                        <Fieldset>
                                            <FieldContainer xs={12}>
                                                <ImageRadio
                                                    value={translationEditTextData['optIn.format'] || ''}
                                                    states={[
                                                        {
                                                            value: '',
                                                            label: 'Competition Consent',
                                                            active: OptInTypeComplexActive,
                                                            inactive: OptInTypeComplex
                                                        },
                                                        {
                                                            value: 'simple_consent',
                                                            label: 'Non-Competition Consent',
                                                            active: OptInTypeSimpleActive,
                                                            inactive: OptInTypeSimple
                                                        },
                                                        {
                                                            value: 'simple_sign_up',
                                                            label: 'Simple Sign Up',
                                                            active: OptInTypeSignUpActive,
                                                            inactive: OptInTypeSignUp
                                                        }
                                                    ]}
                                                    onChange={(newValue) => {
                                                        setTranslationEditTextDataSingle('optIn.format', newValue);
                                                    }}
                                                />
                                            </FieldContainer>


                                            {
                                                    // Full Consent
                                                    ( !translationEditTextData['optIn.format'] ) &&
                                                        <>
                                                            <FieldContainer xs={12}>
                                                                <OptIn
                                                                    value={translationEditTextData['optIn.fullConsent.lists'] || null}
                                                                    override={true}
                                                                    onChange={(newValue) => {
                                                                        setTranslationEditTextDataSingle('optIn.fullConsent.lists', newValue);
                                                                    }}
                                                                />
                                                            </FieldContainer>

                                                            <FieldContainer xs={12}>
                                                                <InputLabel shrink htmlFor="optIn.fullConsent.entryButton.text">Entry Button Text <Tooltip title={<>This is the text that appears on the button, alongside the opt-in options where the users agrees to our Terms & Conditions. By default this text reads "<strong>Enter</strong>".</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                                <TextField
                                                                    value={translationEditTextData['optIn.fullConsent.entryButton.text'] || ''}
                                                                    name="optIn.fullConsent.entryButton.text"
                                                                    placeholder="Enter"
                                                                    fullWidth
                                                                    onChange={handleTranslationEditTextChange}
                                                                />
                                                            </FieldContainer>

                                                            <FieldContainer xs={12}>
                                                                <InputLabel shrink htmlFor="optIn.fullConsent.error.text">Error Message <Tooltip title={<>If you the user clicks button to enter the competition & they haven't agreed to the "<strong>Terms & Conditions</strong>" they will shown an error prompting them to agree to the Terms & Conditions before proceeding. By default this text reads "<strong>Please agree to the Terms & Conditions to continue</strong>".</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                                <TextField
                                                                    value={translationEditTextData['optIn.fullConsent.error.text'] || ''}
                                                                    name="optIn.fullConsent.error.text"
                                                                    placeholder="Please agree to the Terms & Conditions to continue"
                                                                    fullWidth
                                                                    onChange={handleTranslationEditTextChange}
                                                                />
                                                            </FieldContainer>

                                                        </>
                                                }
                                                {
                                                    // Simple Consent
                                                    ( translationEditTextData['optIn.format'] === 'simple_consent' ) &&
                                                        <>
                                                            <FieldContainer xs={12}>
                                                                <OptIn
                                                                    override={true}
                                                                    value={translationEditTextData['optIn.simpleConsent.lists'] || null }
                                                                    onChange={(newValue) => {
                                                                        setTranslationEditTextDataSingle('optIn.simpleConsent.lists', newValue);
                                                                    }}
                                                                />
                                                            </FieldContainer>

                                                            <FieldContainer xs={12}>
                                                                <InputLabel shrink htmlFor="optIn.simpleConsent.entryButton.text">Entry Button Text <Tooltip title={<>This is the text that appears on the button, alongside the opt-in options where the users agrees to our Terms & Conditions. By default this text reads "<strong>Enter</strong>".</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                                <TextField
                                                                    value={translationEditTextData['optIn.simpleConsent.entryButton.text'] || ''}
                                                                    name="optIn.simpleConsent.entryButton.text"
                                                                    placeholder="Enter"
                                                                    fullWidth
                                                                    onChange={handleTranslationEditTextChange}
                                                                />
                                                            </FieldContainer>

                                                            <FieldContainer xs={12}>
                                                                <InputLabel shrink htmlFor="optIn.simpleConsent.error.text">Error Message <Tooltip title={<>If you the user clicks button to enter the competition & they haven't agreed to the "<strong>Terms & Conditions</strong>" they will shown an error prompting them to agree to the Terms & Conditions before proceeding. By default this text reads "<strong>Please agree to the Terms & Conditions to continue</strong>".</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                                <TextField
                                                                    value={translationEditTextData['optIn.simpleConsent.error.text'] || ''}
                                                                    name="optIn.simpleConsent.error.text"
                                                                    placeholder="Please agree to the Terms of Use to continue"
                                                                    fullWidth
                                                                    onChange={handleTranslationEditTextChange}
                                                                />
                                                            </FieldContainer>
                                                        </>
                                                }
                                                {
                                                    // Simple Sign Up
                                                    ( translationEditTextData['optIn.format'] === 'simple_sign_up' ) &&
                                                        <>

                                                            <FieldContainer xs={12}>
                                                                {<OptIn
                                                                    value={translationEditTextData['optIn.simpleSignUp.lists'] || null}
                                                                    simple={true}
                                                                    override={true}
                                                                    onChange={(newValue) => {
                                                                        setTranslationEditTextDataSingle('optIn.simpleSignUp.lists', newValue);
                                                                    }}
                                                                />}
                                                            </FieldContainer>

                                                            <FieldContainer xs={12}>
                                                                <InputLabel shrink htmlFor="optIn.simpleSignUp.label">Entry Button Label <Tooltip title={<>This is the text that appears above the sign up button.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                                <RichTextEditor
                                                                    value={translationEditTextData['optIn.simpleSignUp.label'] || ( translationEditTextData['optIn.fullConsent.lists'] ? translationEditTextData['optIn.fullConsent.lists'][translationEditTextData['optIn.fullConsent.lists'].length - 1].label : '' )}
                                                                    onEditorChange={(value) => {
                                                                        setTranslationEditTextDataSingle('optIn.simpleSignUp.label', value);
                                                                    }}
                                                                    fullWidth
                                                                    size="medium"
                                                                    name="optIn.simpleSignUp.label"
                                                                />
                                                            </FieldContainer>

                                                            <FieldContainer xs={12}>
                                                                <InputLabel shrink htmlFor="optIn.simpleSignUp.entryButton.text">Entry Button Text <Tooltip title={<>This is the text that appears on the button, alongside the opt-in options where the users agrees to our Terms & Conditions. By default this text reads "<strong>Enter</strong>".</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                                <TextField
                                                                    value={translationEditTextData['optIn.simpleSignUp.entryButton.text'] || 'Enter'}
                                                                    name="optIn.simpleSignUp.entryButton.text"
                                                                    placeholder="Enter"
                                                                    fullWidth
                                                                    onChange={handleTranslationEditTextChange}
                                                                />
                                                            </FieldContainer>
                                                            
                                                        </>
                                                            
                                                }



                                                    <FieldContainer xs={12}>
                                                        <InputLabel shrink htmlFor="optIn.footer.text">Privacy Policy Text <Tooltip title={<>This text appears below the enter button and explains WMG's privacy policy.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                        <RichTextEditor
                                                            size="auto"
                                                            value={translationEditTextData['optIn.footer.text'] || '<p>Please see our <a href="http://www.wminewmedia.com/privacy/" target="_blank" rel="noopener">Privacy Policy</a> for more information about how Warner Music Group companies handle your data. You can opt out anytime by clicking the unsubscribe in the email.</p>'}
                                                            onEditorChange={(value) => {
                                                                setTranslationEditTextDataSingle('optIn.footer.text', value);
                                                            }}
                                                        />
                                                    </FieldContainer>

                                                                                                
                                                    <FieldContainer xs={12}>
                                                        <InputLabel shrink htmlFor="optIn.appleMusicPaidAccountError">Apple Music Paid Account Error <Tooltip title={<>This text appears when a user tries to sign in with an Apple Music account that is not a paid account. Unfortauntely it is not possible to sign in with a free Apple Music account.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                        <RichTextEditor
                                                            size="auto"
                                                            value={translationEditTextData['optIn.appleMusicPaidAccountError']}
                                                            onEditorChange={(value) => {
                                                                setTranslationEditTextDataSingle('optIn.appleMusicPaidAccountError', value);
                                                            }}
                                                        />
                                                    </FieldContainer>

                                                <FieldContainer xs={12}>
                                                    <Divider />
                                                </FieldContainer>

                                                <FieldContainer xs={6}>
                                                    <InputLabel shrink htmlFor="optInSettings.ageConstraintLabel">Age Gate Field label <Tooltip title={<>This is the text that appears next to the field.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                    <TextField
                                                        value={translationEditTextData['optInSettings.ageConstraintLabel'] || 'Enter your date of birth'}
                                                        name="optInSettings.ageConstraintLabel"
                                                        placeholder="Enter"
                                                        fullWidth
                                                        onChange={handleTranslationEditTextChange}
                                                    />
                                                </FieldContainer>

                                                <FieldContainer xs={6}>
                                                    <InputLabel shrink htmlFor="optInSettings.ageConstraintError">Age Gate Error Message <Tooltip title={<>Age Gate Message displayed if they enter a date of birth that's too low</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                    <TextField
                                                        value={translationEditTextData['optInSettings.ageConstraintError']}
                                                        name="optInSettings.ageConstraintError"
                                                        fullWidth
                                                        onChange={handleTranslationEditTextChange}
                                                    />
                                                </FieldContainer>
                                            </Fieldset>
                                    </>
                                }
                            ]}
                        />

                        <Fieldset>

                            <FieldContainer xs={12} sx={{textAlign: 'right'}}>
                                <LoadingButton
                                    sx={{mb: 2}}
                                    type="submit"
                                    loading={translationEditSubmitting}
                                    onClick={handleSubmitTranslationEdit}
                                    loadingPosition="end"
                                    variant="contained"
                                    endIcon={<SaveIcon />}
                                >
                                    Save Changes
                                </LoadingButton>
                            </FieldContainer>

                        </Fieldset>
                    </>
                    
                    
                }
            </ModalDialogue>

            <ModalDialogue open={translationCreateModal} size="large" onClose={toggleTranslationCreateModal}>
                <Box sx={{px: 2, pt: 2, pb: 1}}>

                    <Typography variant="h4">Create Translation</Typography>                    
                </Box>

                <form
                    autoComplete="off"
                    onSubmit={(e) => {
                        e.preventDefault();

                        setTranslationCreateSubmitting(true);

                        // Create campaign
                        acquisitionRepository.createTranslation({
                            isGlobal: translationFormData['isGlobal'],
                            country: translationFormData['country'],
                            language: translationFormData['language']
                        })
                            .then(createTranslationResponse => {
                                enqueueSnackbar("Campaign translation created", {
                                    variant: 'success'
                                });
                                
                                setTranslationCreateSubmitting(false);
                                setTranslationCreateModal(!false);
                                setTranslationFormData({type: 'reset'});

                                history.push('/translations/campaigns/'+createTranslationResponse.getId()+'/edit');
                            });
                            
                    }}
                >
                        <Fieldset>
                    
                    
                            <FieldContainer>
                                <InputLabel shrink htmlFor="language">Language <Tooltip title={<>The language for this translation.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                { languageData &&
                                    <Select
                                        id="language"
                                        name="language"
                                        onChange={handleChange}
                                        value={translationFormData['language'] || ''}
                                        fullWidth
                                    >
                                        <MenuItem value="">None</MenuItem>

                                        {languageData.getItems().map((language) => (
                                            <MenuItem key={language.getId()} value={language.getId()}>{language.getName()}</MenuItem>
                                        ))}
                                    </Select>
                                }
                            </FieldContainer>

                            <FieldContainer>
                                <InputLabel shrink>Global Language <Tooltip title={<>Is this the global version of the lagnuage or a territory specific version?</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <FormControlLabel control={<Switch name="isGlobal" onChange={handleChange} checked={translationFormData['isGlobal'] !== true ? false : true} />} label="Yes" />
                            </FieldContainer>

                            { ( translationFormData['isGlobal'] !== true ) &&
                                <FieldContainer>
                                    <InputLabel shrink htmlFor="country">Country <Tooltip title={<>This is the primary country of this language.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                    { countryData &&
                                        <Select
                                            id="country"
                                            name="country"
                                            onChange={handleChange}
                                            value={translationFormData['country'] || ''}
                                            fullWidth
                                        >
                                            <MenuItem value="">None</MenuItem>

                                            {countryData.getItems().map((country) => (
                                                <MenuItem key={country.getId()} value={country.getId()}>{country.getName()}</MenuItem>
                                            ))}
                                        </Select>
                                    }
                                </FieldContainer>
                            }

                            <FieldContainer xs={12} sx={{textAlign: 'right'}}>
                                <LoadingButton
                                    sx={{mb: 2}}
                                    type="submit"
                                    loading={translationCreateSubmitting}
                                    loadingPosition="end"
                                    variant="contained"
                                    endIcon={<AddBoxIcon />}
                                >
                                    Create Translation
                                </LoadingButton>
                            </FieldContainer>

                        </Fieldset>

                </form>

                    
            </ModalDialogue>

            <Hero image={CampaignsHeroImage}>
                <HeroText>
                    <Typography variant="h4" sx={{mb: 1}}>Welcome</Typography>
                    <Typography paragraph>Here you can view all of your translations.</Typography>
                    <Typography variant="button"><MLink component={Link} to="/resources">Learn More</MLink></Typography>
                </HeroText>
                <HeroButtonGroup>
                    <HeroButton color="secondary" onClick={toggleTranslationCreateModal}>Create Translation<AddCircleOutlineIcon /></HeroButton>
                </HeroButtonGroup>
            </Hero>
            <PageContainer>
                <Typography variant="h4">Translations</Typography>
                { translationDataLoading && <Loader /> }
                { !translationDataLoading && translationData &&
                <>
                    <CampaignTranslationList
                        data={translationData.getItems()}
                        onDelete={translationId => {
                            return acquisitionAPI.deleteTranslation(translationId)
                                .then(deleteTranslationResponse => {
                                    enqueueSnackbar("Translation deleted", {
                                        variant: 'success'
                                    });

                                    refreshTranslationData();

                                    return deleteTranslationResponse;
                                });
                        }}
                    />
                    {
                        ( translationData.getTotal() > 0 ) &&
                            <TablePagination
                                component="div"
                                count={translationData.getTotal()}
                                page={parseInt(query.get('page'))}
                                onPageChange={handleChangePage}
                                rowsPerPage={parseInt(query.get('perPage'))}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelDisplayedRows={labelDisplayedRows}
                            />
                    }
                </>
                }
            </PageContainer>
        </>
    );
};
