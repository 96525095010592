import { Fragment, useState } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import MLink from '@mui/material/Link';

import ContextMenu from './ContextMenu.js';
import Image from 'mui-image'

import dateFormat, { masks } from "dateformat";
import NoImage from './../../assets/img/no-image.png';

import User from './User.js';

import {
    OpenInNew as OpenInNewIcon, 
    Edit as EditIcon,
    ShowChart as ShowChartIcon,
    Visibility as VisibilityIcon,
    ContentCopy as ContentCopyIcon,
    Delete as DeleteIcon
} from '@mui/icons-material';
import ModalDialogue from './ModalDialogue.js';
import { Box, Button } from '@mui/material';

export default function AudienceListSingle(props) {
    const [deleteModal, setDeleteModal] = useState(false);

    const toggleDeleteModal = event => {
        setDeleteModal(!deleteModal);
    }

    let emailData = props.data.getEmailData();
    let queryData = props.data.getQueryData();

    let subHeader = [];
    let title = props.data.getName();

    let includeCountry = queryData ? queryData.find(filter => ( filter.context === 'countries' && filter.type === 'include' ) ) : null;
    
    if( !title )
    {
        if( emailData && emailData.playlist)
        {
            title = emailData.playlist.label;
        }
        else if( emailData && emailData.artist)
        {
            title = emailData.artist.label;
        }
        else
        {
            title = emailData.subject;
        }
    }

    if( includeCountry && includeCountry.value )
    {
        let countryNames = Array.from(includeCountry.value, country => country.name);
        subHeader.push(countryNames.join(', '));
    }
    else
    {
        subHeader.push('Global');
    }

    if( props.data.getLanguage() )
    {
        subHeader.push(props.data.getLanguage().getName());
    }

    if( props.data.getEmailTemplate() )
    {
        subHeader.push(props.data.getEmailTemplate().getName());
    }

    return (
        <TableRow key={props.data.getId()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell sx={{width: '5%', paddingRight: 0}} component="th" scope="row">
                <Image sx={{maxWidth: '40px', borderRadius: '3px'}} src={emailData.image || NoImage} />
            </TableCell>
            <TableCell sx={{width: '50%', paddingRight: 0}} component="th" scope="row">
                <Typography variant="body1"><MLink component={Link} to={`/insights/${props.data.getId()}/stats`}>{title}</MLink></Typography>
                <Typography variant="subtitle2">
                    {
                        subHeader &&
                            subHeader.map( (subHeaderItem, index) => {
                                return (index > 0) ? <Fragment key={index}>&nbsp; &middot; &nbsp;{subHeaderItem}</Fragment> : <Fragment key={index}>{subHeaderItem}</Fragment>;
                            })
                    }
                </Typography>
            </TableCell>
            <TableCell sx={{width: '25%', paddingRight: 0}}>
                <Typography variant="subtitle2">{props.data.getSendDateTime() ? dateFormat(props.data.getSendDateTime(), "mmm dS yyyy")+' at '+dateFormat(props.data.getSendDateTime(), "HH:mm") : ''}</Typography>
            </TableCell>
            <TableCell sx={{width: '10%', paddingRight: 0}}>
                {props.data.getUser() && <User id={props.data.getUser()} />}
            </TableCell>
            <TableCell sx={{width: '10%', paddingRight: 0}}>
                <ModalDialogue showFooter={false} open={deleteModal} onClose={toggleDeleteModal}>
                    <Box sx={{px: 2, pt: 2, pb: 1}}>

                        <Typography mb={2} variant="h4">Delete Campaign</Typography>
                        <Typography variant="body1">Are you sure you want to delete this campaign? This task cannot be undone.</Typography>

                        <Box sx={{textAlign: 'right'}}>
                            <Button
                                onClick={toggleDeleteModal}
                                sx={{mt: 2, ml: 2}}
                            >
                                Cancel
                            </Button>

                            <Button
                                onClick={() => {
                                    if( props.onDelete )
                                    {
                                        props.onDelete(props.data.getId()).then(deleteResponse => {
                                            toggleDeleteModal();
                                        });
                                    }
                                }}
                                sx={{mt: 2, ml: 2}
                            }>
                                Delete
                            </Button>
                        </Box>
   
                    </Box>
                </ModalDialogue>
                
                <ContextMenu
                    options={[
                        {icon: <ShowChartIcon />, label: 'Stats', to: '/insights/'+props.data.getId()+'/stats'},
                        {icon: <DeleteIcon />, label: 'Delete', onClick: toggleDeleteModal},
                    ]}
                />
            </TableCell>
        </TableRow>
    );
}