import React, { Fragment, useState } from 'react';

import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, TablePagination, Chip, Box, Button, ButtonGroup, Typography, CircularProgress, Stack, Divider, Paper, TextField, Tooltip, Link as MLink, InputLabel, Grid, Autocomplete, NativeSelect, Select, MenuItem, InputAdornment, List, ListItem, ListItemText, Checkbox, IconButton, ListItemIcon, ListItemButton } from '@mui/material';

import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Info as InfoIcon
} from '@mui/icons-material';
import ContextMenu from '../../ContextMenu';

import RadioRangeBackgroundImage from '../../../../assets/img/radioRange.background.png';

import Flag from 'react-world-flags'

export default function RadioGroupLinear(props) {

    var value = props.value || undefined;

    return (

        <RadioGroup
            {...props}
            sx={{
                justifyContent: "space-between",
                backgroundImage: `url(${RadioRangeBackgroundImage})`,
                backgroundPosition: 'center center',
                backgroundRepeat: 'repeat-x',
                '& .MuiRadio-root': {
                    backgroundColor: '#ffffff',
                    '& :hover': {
                        backgroundColor: '#ffffff'
                    }
                }
            }}
            row
        >
            {props.children}
        </RadioGroup>
    );
}