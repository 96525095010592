
import React, { useState, useEffect } from 'react';

import Wrapper from './Components/Elements/Wrapper';
import Footer from './Components/Elements/Footer';

import AccountPage from './Components/Pages/Account/Index';
import useQuery from './Components/Utilities/useQuery';

import { CSSTransition } from 'react-transition-group'

import routes from "./Config/routes";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";
import { useAuthState, Notifications } from './Context'
import { createContext } from 'react';

import { Snackbar, Alert, Box, Link, Typography } from '@mui/material';

import {
  Route,
  withRouter
} from "react-router-dom";
import Error404 from './Components/Pages/Error/404';

function AppMain(){
  const userDetails = useAuthState()
  let query = useQuery();

  // Hack? For TinyMCE modals
  document.addEventListener('focusin', (e) => {
    if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
      e.stopImmediatePropagation();
    }
  });

  return (
    <Notifications>
      <Wrapper>
        {routes.map(({ path, Component, pageData, isPublic, exact }) => (
          <Route key={path} path={path} exact={exact !== undefined ? exact : true}>
            {({ match }) => (
              <CSSTransition
                in={match != null}
                timeout={300}
                classNames="page"
                unmountOnExit
              >
                <div className="page">
                  <>
                      {
                        ( !userDetails.user && ( ( isPublic !== true ) && !query.get('token') ) ) ?
                          <AccountPage />
                        :
                          <Component />
                      }
                    </>
                    <Typography variant="body2" textAlign="center" sx={{pb: 2}}>
                      <Link target="_blank" href="https://privacy.wmg.com/wmi/privacy">Privacy Policy</Link> | <Link target="_blank" href="https://privacy.wmg.com/wmi/terms-of-use">Terms of Use</Link> | <Link href="https://www.wminewmedia.com/cookies-policy/?ot=be2c4518-1ea9-4ed3-943c-865265962e10&url=wmgconnect.com" target="_blank">Cookies Policy</Link> | <a style={{cursor: 'pointer'}} className="ot-sdk-show-settings">Cookies Settings</a>
                    </Typography >
                </div>
              </CSSTransition>
            )}

          </Route>
        ))}
        <Footer />
      </Wrapper>
    </Notifications>
  )
}

const App = withRouter(AppMain);

export default App;