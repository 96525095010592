import React, { Fragment, useState, useEffect } from 'react';

import { productFamilies, supportedIntegrations } from '../../../Utilities/Services';
import { TablePagination, Box, Button, Typography, CircularProgress, Stack, Divider, Paper, TextField, Tooltip, Link as MLink, InputLabel, Grid, Autocomplete, NativeSelect, Select, MenuItem, InputAdornment, List, ListItem, ListItemText, Checkbox, IconButton, ListItemIcon, ListItemButton, FormControlLabel, Switch } from '@mui/material';

import { isValidURL } from '../../../Utilities/Utilities';

import {
    Info as InfoIcon,
    ArrowDropDown as ArrowDropDownIcon,
    ArrowDropUp as ArrowDropUpIcon
} from '@mui/icons-material';

export default function Services(props) {
    const [integrationData, setIntegrationData] = useState(props.value || []);

    const handleChange = (id, field, value) => {
        let integrationDataNew = [];

        for (let i = 0; i < integrationData.length; i++) {
            integrationDataNew[i] = {...integrationData[i]};
        }

        let serviceData = integrationDataNew && integrationDataNew.find(integrationDataSingle => integrationDataSingle.id === id);
        let defaultServiceData = supportedIntegrations.find(supportedIntegrationsSingle => supportedIntegrationsSingle.id === id);

        if( !serviceData )
        {
            serviceData = {
                active: true,
                id: id,
                label: defaultServiceData.label
            };

            integrationDataNew.push(serviceData);
        }
        else
        {
            serviceData[field] = value;
        }

        if( field === 'active' && ( value === true ) )
        {
            if( integrationData.filter(integrationDataSingle => integrationDataSingle.active).length > 1 )
            {
                let integrationDataCurrent = integrationDataNew.find(integrationDataSingle => integrationDataSingle.id === id);
                let integrationDataIndex = integrationDataNew.findIndex(integrationDataSingle => integrationDataSingle.id === id);
    
                let lastActiveIntegrationDataIndex = integrationDataNew.findIndex(integrationDataSingle => integrationDataSingle.id === integrationData.filter(integrationDataSingle => integrationDataSingle.active).pop().id);
                lastActiveIntegrationDataIndex++;
    
                integrationDataNew.splice(integrationDataIndex, 1);
                integrationDataNew.splice(lastActiveIntegrationDataIndex, 0, integrationDataCurrent);    
            }
        }

        setIntegrationData(integrationDataNew);

        if( props.onChange )
        {
            props.onChange(integrationDataNew);
        }
    }

    const handleMove = (id, direction) => {
        console.log(id, direction);
        let integrationDataNew = [...integrationData];

        let integrationDataIndex = integrationData.findIndex(integrationDataSingle => integrationDataSingle.id === id);
        let integvrationDataElement = integrationData[integrationDataIndex];
        let fromIndex = integrationDataIndex;
        let toIndex = undefined;

        if( direction === 'up' )
        {
            toIndex = integrationDataIndex - 1;
        }
        else
        {
            toIndex = integrationDataIndex + 1;
        }

        integrationDataNew.splice(fromIndex, 1);
        integrationDataNew.splice(toIndex, 0, integvrationDataElement);    

        setIntegrationData(integrationDataNew);
        
        if( props.onChange )
        {
            props.onChange(integrationDataNew);
        }
    };

    useEffect(() => {
        let integrationDataNew = [...integrationData];

        supportedIntegrations.forEach(supportedIntegration => {
            let integrationExists = integrationDataNew.find(integrationDataNewSingle => integrationDataNewSingle.id === supportedIntegration.id);

            if( !integrationExists )
            {
                integrationDataNew.push({
                    active: false,
                    id: supportedIntegration.id,
                    label: supportedIntegration.label
                });
            }
        });

        setIntegrationData(integrationDataNew);
        
    }, [props.value]);

    return (

        integrationData.map( (streamingService, streamingServiceIndex, streamingServices) => {
            const currentStreamingService = supportedIntegrations.find(supportedIntegration => supportedIntegration.id === streamingService.id);
            const currentProductFamily = productFamilies.find(productFamily => productFamily.id === currentStreamingService.productFamily);
            const checked = streamingService.active ? true : false;

            if( !checked && ( currentStreamingService.active === false ) )
            {
                return false;
            }


            return (
                <Grid
                    item
                    xs={12}
                    component={ListItem}
                    key={streamingService.id}
                    disablePadding
                >
                    <Grid onClick={e => { handleChange(streamingService.id, 'active', !checked); }} sx={{opacity: checked ? 1 : 0.5}} container component={ListItemButton}>
                        <Grid alignContent="left" xs={0.5} item>
                            <Checkbox
                                edge="end"
                                name="integrations"
                                checked={ checked ? true : false }
                            />
                        </Grid>
                        <Grid xs={0.5} item component={IconButton} disableRipple edge="start" aria-label="comments">
                            {currentStreamingService.icon || currentProductFamily.icon}
                        </Grid>
                        <Grid xs={3} item component={ListItemText} primary={currentStreamingService.label} secondary={currentStreamingService.secondaryLabel} />
                        <Grid
                            xs={currentStreamingService.type === "Links" ? 3 : 7}
                            sx={{pr: currentStreamingService.type === "Links" ? 4 : 0}}
                            item
                            onClick={e=> {
                                if (checked)
                                {
                                    e.stopPropagation();
                                }
                            }}
                        >
                            <TextField
                                onMouseDown={e=> {
                                if (checked)
                                    {
                                        e.stopPropagation();
                                    }
                                }}
                                onChange={e => { handleChange(streamingService.id, 'label', e.target.value); }}
                                fullWidth
                                sx={{opacity: checked ? 1 : 0.5 }}
                                placeholder="Name of service/platform"
                                value={streamingService.label}
                            />
                        </Grid>
                        { currentStreamingService.type === "Links" &&
                            <Grid
                                onClick={e=> {
                                    if ( checked )
                                    {
                                        e.stopPropagation();
                                    }
                                }}
                                xs={2}
                                lg={4}
                                item
                            >
                                <TextField
                                    onMouseDown={e => {
                                        if (checked)
                                        {
                                            e.stopPropagation();
                                        }
                                    }}
                                    onChange={e => { handleChange(streamingService.id, 'link', e.target.value); }}
                                    fullWidth
                                    sx={{opacity: checked ? 1 : 0.5 }}
                                    placeholder="Extenal URL for pre-order"
                                    error={(streamingService && streamingService.link) ? !isValidURL(streamingService.link) : (streamingService && streamingService.active) ? true : false}
                                    value={(streamingService && streamingService.link) ? streamingService.link : ''}
                                        />
                            </Grid>
                        }
                        <Grid onClick={e => { e.stopPropagation(); }} onMouseDown={e => { e.stopPropagation(); }} textAlign="center" xs={1} item>
                            <IconButton
                                disabled={( streamingServiceIndex === 0 ) || !checked}
                                onClick={e => handleMove(streamingService.id, 'up')}
                            >
                                <ArrowDropUpIcon />
                            </IconButton>
                            <IconButton
                                disabled={( streamingServiceIndex === ( streamingServices.filter(streamingServicesingle => streamingServicesingle.active).length - 1 ) ) || !checked}
                                onClick={e => handleMove(streamingService.id, 'down')}
                            >
                                <ArrowDropDownIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    )
    
  );

}