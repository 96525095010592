import {
    Info as InfoIcon,
    AddBox as AddBoxIcon,
    ArrowBack as ArrowBackIcon,

    PersonAddAlt as CampaignFollowIcon,
    LibraryMusic as CampaignSaveIcon,
    NewReleases as CampaignPreSaveIcon,
    LockOpen as CampaignContentUnlockIcon,
    MusicNote as CampaignStreamToEnterIcon,
    Headphones as CampaignListeningHistoryIcon,
    DynamicForm as CampaignFormIcon,
    Downloading as CampaignRedeemableDownloadCodeIcon
} from '@mui/icons-material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpotify, faApple, faDeezer, faInstagram, faYoutube, faFacebook, faTwitter, faTiktok, faSnapchat, faAmazon } from '@fortawesome/free-brands-svg-icons'

var campaignTypes = [
    {
        title: 'Follow',
        backgroundColor: '#ff5421',
        icon: <CampaignFollowIcon />,
        value: 'follow',
        description: "Fans follow a selection of playlists, artists, albums and tracks."
    },
    {
        title: 'Save',
        backgroundColor: '#f5b996',
        icon: <CampaignSaveIcon />,
        value: 'save',
        description: "Fans enter the campaign and have an album or single added to their library."
    },
    {
        title: 'Pre-Save',
        backgroundColor: '#ff8bf5',
        icon: <CampaignPreSaveIcon />,
        value: 'presave',
        description: "Fans enter the campaign and have an album or single added to their library on the day of release."
    },
    {
        title: 'Content Unlock',
        backgroundColor: '#d2d2ff',
        icon: <CampaignContentUnlockIcon />,
        value: 'contentunlock',
        description: "Fans perform an action to unlock a piece of content which can be a video, image, audio file or message."
    },
    {
        title: 'Stream to Enter',
        backgroundColor: '#81c7b9',
        icon: <CampaignStreamToEnterIcon />,
        value: 'streamtoenter',
        description: "Fans must stream a playlist, album or track to enter this campaign."
    },
    {
        title: 'Listening History',
        backgroundColor: '#b4ff47',
        icon: <CampaignListeningHistoryIcon />,
        value: 'profile',
        description: "Uses a fan's listening history to look back at their favorite music and provide recommendations."
    },
    {
        title: 'Form',
        backgroundColor: '#6dea81',
        icon: <CampaignFormIcon />,
        value: 'form',
        description: "Fans enter the campaign by maually entering their personal details."
    },
    {
        title: 'Redeemable Download Code',
        backgroundColor: '#ff8153',
        icon: <CampaignRedeemableDownloadCodeIcon />,
        value: 'redeemabledownloadcode',
        description: "Fans enter a code to unlock a piece of content."
    }
];

var defaultCampaignConfigurationFields = {
    'integrationSettings': undefined,
    'serviceThemes': undefined,
    'buttonTheme.borderRadius': undefined,
    'buttonTheme.textColor': undefined,
    'buttonTheme.backgroundColor': undefined,
    'brandedButtonTheme.textColor': undefined,
    'brandedButtonTheme.backgroundColor': undefined,

    'password.enabled': undefined,
    'password.key': undefined,

    'blockedCountries.enabled': undefined,
    'blockedCountries.countries': undefined,

    'close.enabled': undefined,
    'close.dateTime': undefined,

    'formEntry.enabled': undefined,
    'formEntry.fields': ['type', 'name', 'mandatory'],
    'formEntry.displayByDefault': undefined,
    'formEntry.facebook.enabled': undefined,

    'general.genre': undefined,
    'general.context': undefined,

    'theme.image.url': undefined,
    'theme.headerImage.url': undefined,
    'theme.background.image.url': undefined,

    'successPage.uris': undefined,

    'social.enabled': undefined,
    'social.facebook.enabled': undefined,
    'social.facebook.image.url': undefined,
    'social.twitter.enabled': undefined,
    'social.twitter.image.url': undefined,

    'advanced.enabled': undefined,
    'advanced.additionalStyles': undefined,
    'advanced.additionalScripts': undefined,

    'termsAndConditions.enabled': undefined,
    'termsAndConditions.file': undefined,
    
    'privacyPolicy.enabled': undefined,
    'privacyPolicy.file': undefined,

    'termsOfUse.enabled': undefined,
    'termsOfUse.file': undefined,

    'footer.buttons.custom.enabled': undefined,
    'footer.buttons.custom.url': undefined,
    'footer.buttons.custom.image.url': undefined,

    'footer.buttons.appleMusic.url': undefined,
    'footer.buttons.appleMusic.enabled': undefined,
    'footer.buttons.youtube.url': undefined,
    'footer.buttons.youtube.enabled': undefined,
    'footer.buttons.instagram.url': undefined,
    'footer.buttons.instagram.enabled': undefined,
    'footer.buttons.twitter.url': undefined,
    'footer.buttons.twitter.enabled': undefined,
    'footer.buttons.facebook.url': undefined,
    'footer.buttons.facebook.enabled': undefined,
    'footer.buttons.spotify.url': undefined,
    'footer.buttons.spotify.enabled': undefined,
    'footer.buttons.tiktok.url': undefined,
    'footer.buttons.tiktok.enabled': undefined,

    'optIn.showOnLandingPage': undefined,
    'optIn.agreedToTerms': undefined,
    'optIn.format': undefined,
    'optIn.fullConsent.lists': ['id', 'entity', 'data_extension', 'trigger', 'mandatory', 'type'],
    'optIn.simpleConsent.lists': ['id', 'entity', 'data_extension', 'trigger', 'mandatory', 'type'],
    'optIn.simpleSignUp.lists': ['id', 'entity', 'data_extension', 'trigger', 'mandatory', 'type'],

    'optInSettings.dataSource': undefined,
    'optInSettings.ageConstraintEnabled': undefined,
    'optInSettings.ageConstraintLabel': undefined,
    'optInSettings.ageConstraintError': undefined,
    'optInSettings.disableAppleMusicDataCapture': undefined,
    
    'additionalDataForm.enabled': undefined,
    'additionalDataForm.fields': ['type', 'name', 'mandatory'],

    'entryQuestion.enabled': undefined,

    'entryOption.enabled': undefined,

    'theme.borders.enabled': undefined,
    'theme.borders.color': undefined,
    'theme.titleText.color': undefined,
    'theme.text.color': undefined,
    'theme.background.color': undefined,
    'theme.container.verticalPadding': undefined,
    'theme.background.image.position': undefined,
    'theme.layout.titlePosition': undefined,
    'theme.container.background.opacity': undefined,
    'theme.container.background.color': undefined,
    'theme.container.borderRadius': undefined,
    'theme.background.image.blur': undefined,
    'theme.font': undefined,
    'theme.text.size': undefined,
    'theme.icon.url': undefined,
    
    'theme.mobile.image.url': undefined,
    'theme.mobile.image.enabled': undefined,
    'theme.mobile.headerImage.url': undefined,
    'theme.mobile.background.color.value': undefined,
    'theme.mobile.background.image.url': undefined,
    
    'theme.layout.themePosition': undefined,
    'theme.layout.themeStyle': undefined,
    'theme.layout.buttonType': undefined,
    'theme.layout.buttonStyle': undefined,
    'theme.layout.buttonSize': undefined,
    'theme.layout.buttonPosition': undefined,
    'theme.layout.buttonPositionStyle': undefined,
    'theme.layout.buttonModal': undefined,
    'theme.layout.buttonModalColor': undefined,
    'theme.layout.buttonModalStyle': undefined,
    'theme.layout.buttonHideText': undefined,
    'theme.layout.buttonOfficialBrandingEnabled': undefined,

    'contentUnlock.enabled': undefined,
    'contentUnlock.link.enabled': undefined,
    'contentUnlock.link.uris': undefined,
    'contentUnlock.image.enabled': undefined,
    'contentUnlock.image.uris': undefined,
    'contentUnlock.audio.enabled': undefined,
    'contentUnlock.audio.uris': undefined,
    'contentUnlock.video.enabled': undefined,
    'contentUnlock.video.uris': undefined,

    'preSave.enabled': undefined,
    'preSave.countdown.enabled': undefined,
    'preSave.countdown.layout': undefined,
    'preSave.emailReminder.enabled': undefined,
    'preSave.emailReminder.daysAfterRelease': undefined,
    'preSave.uris': undefined,
    'preSave.instantGratUris': undefined,
    'preSave.releaseDate': undefined,
    'preSave.email.enabled': undefined,
    'preSave.email.artistName': undefined,
    'preSave.email.trackName': undefined,
    'preSave.email.image.url': undefined,

    'redeemableCode.enabled': undefined,
    'redeemableCode.method': undefined,
};

var defaultCampaignTranslationFields = {
    'integrationText': undefined,

    'password.title': undefined,
    'password.content': undefined,

    'blockedCountries.title': undefined,
    'blockedCountries.content': undefined,

    'close.content': undefined,

    'formEntry.fields': ['label', 'options'],
    'formEntry.entryButton.text': undefined,
    'formEntry.submitButton.text': undefined,
    'formEntry.backButton.text': undefined,
    'formEntry.facebook.label.text': undefined,

    'landingPage.title': undefined,
    'landingPage.content': undefined,
    'landingPage.bottomContent': undefined,
    'landingPage.bottomContentCompetition': undefined,

    'general.meta.title': undefined,
    'general.meta.description': undefined,

    'successPage.title': undefined,
    'successPage.content': undefined,

    'social.facebook.button.text': undefined,
    'social.twitter.button.text': undefined,
    'social.twitter.text': undefined,

    'termsAndConditions.content': undefined,
    
    'privacyPolicy.title': undefined,
    'privacyPolicy.content': undefined,
    
    'termsOfUse.title': undefined,
    'termsOfUse.content': undefined,

    'footer.text': undefined,
    'footer.links': undefined,

    'optIn.appleMusicPaidAccountError': undefined,
    'optIn.footer.text': undefined,

    'optIn.fullConsent.lists': ['label', 'labelCompiled'],
    'optIn.fullConsent.entryButton.text': undefined,
    'optIn.fullConsent.error.text': undefined,

    'optIn.simpleConsent.lists': ['label', 'labelCompiled'],
    'optIn.simpleConsent.entryButton.text': undefined,
    'optIn.simpleConsent.error.text': undefined,

    'optIn.simpleSignUp.entryButton.text': undefined,
    'optIn.simpleSignUp.label': undefined,

    'additionalDataForm.submitButton.text': undefined,
    'additionalDataForm.content': undefined,
    'additionalDataForm.fields': ['label', 'options'],

    'entryQuestion.text': undefined,

    'entryOption.text': undefined,
    'entryOption.list': undefined,
    
    'preSave.releaseDateText': undefined,

    'theme.layout.buttonModalText': undefined,
    'theme.layout.buttonModalCloseText': undefined,

    'redeemableCode.fieldLabel.text': undefined,
    'redeemableCode.error.text': undefined,
    'redeemableCode.successMessage.text': undefined,
    'redeemableCode.button.text': undefined,
};

const campaignFooterLinks = [
    {id: 'tiktok', label: 'TikTok', icon: <FontAwesomeIcon icon={faTiktok} />},
    {id: 'facebook', label: 'Facebook', icon: <FontAwesomeIcon icon={faFacebook} />},
    {id: 'twitter', label: 'Twitter', icon: <FontAwesomeIcon icon={faTwitter} />},
    {id: 'youtube', label: 'YouTube', icon: <FontAwesomeIcon icon={faYoutube} />},
    {id: 'instagram', label: 'Instagram', icon: <FontAwesomeIcon icon={faInstagram} />},
    {id: 'spotify', label: 'Spotify', icon: <FontAwesomeIcon icon={faSpotify} />},
    {id: 'applemusic', label: 'Apple Music', icon: <FontAwesomeIcon icon={faApple} />},
    {id: 'deezer', label: 'Deezer', icon: <FontAwesomeIcon icon={faDeezer} />},
    {id: 'amazon', label: 'Amazon', icon: <FontAwesomeIcon icon={faAmazon} />},
    {id: 'snapchat', label: 'Snapchat', icon: <FontAwesomeIcon icon={faSnapchat} />},
];

export { defaultCampaignConfigurationFields, defaultCampaignTranslationFields, campaignTypes, campaignFooterLinks }
