import React, { useState, useReducer, useEffect } from 'react';
import HeroButtonGroup from './HeroButtonGroup';
import ContextMenu from './ContextMenu';

import { useAuthState } from '../../Context';

import { Utilities, AcquisitionRepository, AcquisitionProcessingAPI, AcquisitionAPI, UserRepository } from '@warnerconnect/library';

import { formReducer } from '../../Context/formReducer';

import { Alert, Avatar, Box, CircularProgress, Grid, IconButton, InputLabel, Link as MLink, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Paper, Select, Typography } from '@mui/material';

import { Link, useHistory } from 'react-router-dom';

import { getName } from 'country-list';

import { supportedIntegrations } from '../Utilities/Services';

import { useSnackbar } from "notistack";

import {
  Share as ShareIcon,
  EmojiEvents as EmojiEventsIcon,
  FileDownload as FileDownloadIcon,
  MoreHoriz as MoreHorizIcon,
  ShowChart as ShowChartIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Build as BuildIcon,
  Code as CodeIcon,
  InsertLink as InsertLinkIcon,
  ContentCopy as ContentCopyIcon
} from '@mui/icons-material';

import EntrantList from './EntrantList';
import HeroButton from './HeroButton';
import TabGroup from './TabGroup';
import ModalDialogue from './ModalDialogue';
import Fieldset from './Forms/Fieldset';
import FieldContainer from './Forms/FieldContainer';
import { LoadingButton } from '@mui/lab';
import ContentBox from './ContentBox';

export default function CampaignHeaderButtons(props) {
  const [entrantsCountries, setEntrantsCountries] = useState(props.campaign.getStatistic('entrantsCountries') ? props.campaign.getStatistic('entrantsCountries').getData() : []);
  const { enqueueSnackbar } = useSnackbar();
  const userDetails = useAuthState();
  const acquisitionRepository = new AcquisitionRepository();
  const userRepository = new UserRepository();
  const acquisitionAPI = new AcquisitionAPI();
  
  userRepository.setBearerToken(userDetails.accessToken);
  acquisitionAPI.setBearerToken(userDetails.accessToken);
  acquisitionRepository.setBearerToken(userDetails.accessToken);

  // Share Stats
  const [shareStatsModal, setShareStatsModal] = useState(false);

  const toggleShareStatsModal = event => {
    setShareStatsModal(!shareStatsModal);
  }

  // Implementations
  const [implementationModal, setImplementation] = useState(false);

  const toggleImplementationModal = event => {
    setImplementation(!implementationModal);
  }

  // Export Emails
  const [exportEmailsAccess, setExportEmailsAccess] = useState(false);
  const [exportEmailsModal, setExportEmailsModal] = useState(false);
  const [exportEmailsData, seExportEmails] = useState();
  const [exportEmailsDataLoading, seExportEmailsLoading] = useState();

  
  useEffect(() => {
    userRepository.getUserPermission(userDetails.user.id, 'exportAccess')
        .then( userPermission => {
            if( userPermission.getValue() )
            {
              setExportEmailsAccess(userPermission.getValue());
            }
        })
        // User doesn't have this permission
        .catch(error => {

        });
  }, []);


  const toggleExportEmailsModal = event => {
    setExportEmailsModal(!exportEmailsModal);

    if( !exportEmailsDataLoading && !exportEmailsData )
    {

      acquisitionRepository
        .createExport({
            user: userDetails.user.id,
            type: 'default',
            campaign: props.campaign.getId()
        })
        .then(async (exportResponse) => {
            let total = null;
            let limit = 1000;
            let offset = 0;

            do
            {
                let campaignEntrants = await acquisitionAPI.getCampaignEntrants(props.campaign.getId(), {limit: limit, offset: offset, include: ['entrant.id']});
                
                let entrantIds = Array.from(campaignEntrants.data, item => item.entrant.id);

                await acquisitionRepository.addExportEntrants(exportResponse.getId(), entrantIds);

                if( !total )
                {
                    total = campaignEntrants.total;
                }

                offset = offset + limit;

            }while( offset < total );

            acquisitionRepository
                .updateExport(exportResponse.getId(), {
                    imported: true
                })
                .then(() => {
                    acquisitionAPI.genereateExport(exportResponse.getId())
                        .then( generatedExportResponse => {
                          seExportEmails(generatedExportResponse);
                        });
                });
        });
    }

  }

  // Pick Winners
  const [pickWinnersModal, setPickWinnersModal] = useState(false);
  const [pickWinnersData, setPickWinnersData] = useState();
  const [pickWinnersFormData, setPickWinnersFormData] = useReducer(formReducer, {total: 1});
  const [pickWinnersFormSubmitting, setPickWinnersFormSubmitting] = useState(false);

  const setPickWinnersFormDataSingle = (name, value) => {
    setPickWinnersFormData({ name: name, value: value});
  };

  const handlePickWinnersFormData = event => {
    const isCheckbox = event.target.type === 'checkbox';

    setPickWinnersFormDataSingle(
        event.target.name,
        isCheckbox ? event.target.checked : event.target.value
    );
  }

  const handlePickWinnersFormSubmit = event => {
    setPickWinnersFormSubmitting(true);

    acquisitionRepository.getCampaignEntrantsWinners(props.campaign.getId(), { limit: pickWinnersFormData.total, criteria: { addressCountry: pickWinnersFormData.country } })
    .then( (entrantWinners) => {
      setPickWinnersData(entrantWinners);
      setPickWinnersFormSubmitting(false);
    });
  }

  const togglePickWinnersModal = event => {
    setPickWinnersModal(!pickWinnersModal);
  }

  let toolkitOptions = [
    {icon: <EmojiEventsIcon />, label: 'Pick Winners', onClick: togglePickWinnersModal},
    {icon: <FileDownloadIcon />, label: 'Export Emails', onClick: toggleExportEmailsModal},
    {icon: <ShareIcon />, label: 'Share Stats', onClick: () => {
        navigator.clipboard.writeText(window.location.protocol+'//'+window.location.host+'/campaigns/'+props.campaign.getId()+'/stats?token='+btoa(userDetails.user.id.toString()+userDetails.user.email));

        enqueueSnackbar("Campaign URL copied to clipboard", {
          variant: 'success'
        });
      }
    },
    {divider: true},
    {icon: <CodeIcon />, label: 'Development Kit', onClick: toggleImplementationModal},
    {icon: <InsertLinkIcon />, label: 'Additional Links', onClick: toggleImplementationModal}
  ];

  return (
    <>
      <ModalDialogue open={exportEmailsModal} onClose={toggleExportEmailsModal}>
        <Box sx={{px: 2, pt: 2, pb: 1}}>
            <Typography gutterBottom variant="h4">Export Emails</Typography>
            {
              exportEmailsAccess ?
                exportEmailsData ?
                  <Alert
                    severity="success"
                    sx={{mt: 2}}
                  >
                    Your fan data export has been generated. <MLink href={exportEmailsData.uri} target="_blank">Click here to download your export</MLink>.
                  </Alert>
                :
                  <Alert
                    icon={<CircularProgress size={20} />}
                    severity="info"
                    sx={{mt: 2}}
                  >
                    Please wait while we build your fan data export
                  </Alert>
              :
                <Alert
                  severity="info"
                  sx={{mt: 2}}
                >
                  In order to make Warner Connect as secure as possible with it's user data, we've been asked to review how we expose sensitive data.<br /><br />Please get in touch with <MLink href="mailto:warner-connect-support@wmg.com" target="_blank">warner-connect-support@wmg.com</MLink> if you require access to export user data.
                </Alert>
            }
        </Box>
      </ModalDialogue>
      
      <ModalDialogue size="large" open={implementationModal} onClose={toggleImplementationModal}>
        <TabGroup
          tabList={[
              {
                  label: 'Development Kit',
                  content: <Box sx={{px: 2, pb: 1}}>
                    <Typography gutterBottom variant="h4">Development Kit</Typography>
                    <Typography gutterBottom>If you'd like to create your own custom landing page but want even more control over how the button looks & the overall user experience then you can use the JavaScript SDK to fully control how the button looks & operates.</Typography>

                    <Paper sx={{p: 2, overflowX: 'auto'}} component="pre">{`<button type="button" class="wmg-button" data-platform="spotify">Custom Spotify Button</button>
<button type="button" class="wmg-button" data-platform="apple">Custom Apple Button</button>

<script type="text/javascript" src="https://wct.live/app/sdk/v2/platform.js?campaign=${props.campaign.getId()}"></script>
<script type="text/javascript">
var connect_button = new WMGConnect(".wmg-button", {
  // Would you like a popup "Thank you" message to automatically appear once the user has been acquired?
  // opt_in_thank_you_enabled: true,

  // Data sources are automatically generated based on how the campaign is set up but you can specify a custom one below
  // exacttarget_datasource: 'WMG_EdSheeran_PreSave'
});

connect_button.setCallback(function(connect_data){
  // Callback code
  console.log(connect_data);
});
</script>`}</Paper>
                  </Box>
              },
              {
                  label: 'Additional Links',
                  content: <Box sx={{px: 2, pb: 1}}>
                    <Typography gutterBottom variant="h4">Additional Links</Typography>
                    {
                      props.campaign.getDefaultLocale().getIntegrations() &&
                        <>
                          <Typography gutterBottom>We have a variety of different links allowing you to run your campaign in different ways.</Typography>

                          <Grid container sx={{mt: 2}}>
                            <Grid xs={5.5} item>
                              <Typography gutterBottom variant="h6">Single Service Link</Typography>
                              <Typography gutterBottom>Users clicking these links will only see a single service link on the page.</Typography>

                              <List dense={false}>
                                  {props.campaign.getDefaultLocale().getIntegrations().map(integration => {
                                    var supportedIntegration = supportedIntegrations.find(supportedIntegration => integration === supportedIntegration.id);

                                    return <ListItem
                                      key={integration}
                                      secondaryAction={
                                        <IconButton 
                                          edge="end"
                                          onClick={() => {
                                            navigator.clipboard.writeText(process.env.REACT_APP_FRONTEND_URL+props.campaign.getFullUrlSlug()+'/'+supportedIntegration.id);
                            
                                            enqueueSnackbar("Campaign URL copied to clipboard", {
                                              variant: 'success'
                                            });
                                          }}
                                        >
                                          <ContentCopyIcon />
                                        </IconButton>
                                      }
                                    >
                                      <ListItemAvatar>
                                        <Avatar>
                                          {supportedIntegration.icon}
                                        </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={supportedIntegration.label}
                                      />
                                    </ListItem>
                                  })}
                                  
                                </List>
                            </Grid>
                            <Grid xs={5.5} item sx={{marginLeft: 'auto'}}>
                              <Typography gutterBottom variant="h6">Direct to Service</Typography>
                              <Typography gutterBottom>Users clicking these links will be sent straight to the sign in page.</Typography>

                              <List dense={false}>
                                {props.campaign.getDefaultLocale().getIntegrations().map(integration => {
                                    var supportedIntegration = supportedIntegrations.find(supportedIntegration => integration === supportedIntegration.id);

                                  return <ListItem
                                    key={integration}
                                    secondaryAction={
                                      <IconButton
                                        onClick={() => {
                                          navigator.clipboard.writeText(process.env.REACT_APP_FRONTEND_URL+props.campaign.getFullUrlSlug()+'/'+supportedIntegration.id+'?autoConnect=true');

                                          enqueueSnackbar("Campaign URL copied to clipboard", {
                                            variant: 'success'
                                          });
                                        }}
                                        edge="end"
                                      >
                                        <ContentCopyIcon />
                                      </IconButton>
                                    }
                                  >
                                    <ListItemAvatar>
                                      <Avatar>
                                        {supportedIntegration.icon}
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={supportedIntegration.label}
                                    />
                                  </ListItem>
                                })}
                                
                              </List>
                            </Grid>
                          </Grid>
                          </>
                    }
                  </Box>
              }
          ]}
      />

      </ModalDialogue>
      
      <ModalDialogue open={pickWinnersModal} onClose={togglePickWinnersModal}>
        <Box sx={{px: 2, pt: 2, pb: 1}}>
            <Typography gutterBottom variant="h4">Pick Winners</Typography>
            <Typography gutterBottom>Because we're unable to expose the emails for all users you can use this form to get contact information for a group of random users in order to contact them with regards to prize information.</Typography>
        </Box>
        {
          ( props.campaign.getTotalEntrants() === 0 ) ?
            <Box sx={{px: 2, pb: 2}}><Alert severity="warning">No fans have entered this campaign.</Alert></Box>
          :
            <Fieldset>

              <FieldContainer xs={12}>
                <InputLabel shrink>How Many Winners Would you Like to Pick?</InputLabel>

                <Select
                    onChange={handlePickWinnersFormData}
                    value={pickWinnersFormData.total}
                    name="total"
                    fullWidth
                >
                    {
                      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40, 50].map( (item) => (
                        <MenuItem key={item} value={item}>{item}</MenuItem>
                      ))
                    }

                </Select>
              </FieldContainer>

              <FieldContainer xs={12}>
                <InputLabel shrink>Which Country/Region Would you Like to Select Winners From?</InputLabel>

                <Select
                    onChange={handlePickWinnersFormData}
                    value={pickWinnersFormData.country || ''}
                    name="country"
                    fullWidth
                    displayEmpty
                >
                  <MenuItem key="All" value="">All</MenuItem>

                  {
                    entrantsCountries &&
                      entrantsCountries.filter(item => item.addressCountry).map( (item) => {
                        return <MenuItem key={item.addressCountry} value={item.addressCountry}>{item.addressCountry ? getName(item.addressCountry) : 'Unknown'}</MenuItem>
                      })
                  }
                </Select>
              </FieldContainer>

              <FieldContainer xs={12} sx={{textAlign: 'right'}}>
                <LoadingButton
                    sx={{mb: 2}}
                    onClick={handlePickWinnersFormSubmit}
                    loading={pickWinnersFormSubmitting}
                    variant="contained"
                >
                  Pick Winners
                </LoadingButton>
              </FieldContainer>

            </Fieldset>
          }

          {
            pickWinnersData &&
              <EntrantList
                data={pickWinnersData.getItems()}
                labelField="email"
                showOptions={false}
                fields={['addressCity']}
              />
          }
      </ModalDialogue>
      
      <HeroButtonGroup>
        {
          props.options.includes('toolkit') &&
            <ContextMenu
              color="neutral"
              component={HeroButton}
              options={toolkitOptions}
          >
              Toolkits<BuildIcon />
          </ContextMenu>
      }
      {
        props.options.includes('view') &&
          <HeroButton component={MLink} href={`${process.env.REACT_APP_FRONTEND_URL}/app/${props.campaign.getId()}/${props.campaign.getUrlSlug()}`} target="_blank" color="primary">View Campaign<VisibilityIcon /></HeroButton>
      }
      {
        props.options.includes('stats') &&
          <HeroButton component={Link} to={`/campaigns/${props.campaign.getId()}/stats`} color="primary">Statistics<ShowChartIcon /></HeroButton>
      }
      {
        props.options.includes('edit') &&
          <HeroButton component={Link} to={`/campaigns/${props.campaign.getId()}/edit`} color="primary">Edit<EditIcon /></HeroButton>
      }
    </HeroButtonGroup>
    </>
  );
}
