import * as React from 'react';
import Box from '@mui/material/Box';

export default function HeroText(props) {
  /*
  let _props = Object.assign({}, props);
  if( !_props.sx ) _props.sx = {};

  _props = Object.assign({sx: {height: '150px', width: '150px', textAlign: 'left', verticalAlign: 'top', alignItems: 'flex-start', padding: '20px', boxShadow: '0 6px #cfdadd', marginBottom: '6px', '.MuiSvgIcon-root': { position: 'absolute', width: '1.5em', height: '1.5em', left: '20px', bottom: '20px' }}}, _props.sx);

  _props.variant = 'contained';
  _props.color = 'secondary';
*/
  let styles = {color: '#fff', borderRadius: '3px 3px 0 0', background: 'rgba(0, 0, 0, .75)', maxWidth: '580px', width: '100%',  padding: '40px',  position: 'absolute', left: 0, bottom: 0}

  return (
    <Box sx={styles}>{props.children}</Box>
  );
}