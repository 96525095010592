import React, { useState, useEffect, useReducer, Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AcquisitionRepository, AcquisitionAPI, InsightAPI } from '@warnerconnect/library';

import { TablePagination, Box, InputLabel, Tooltip, InputAdornment, Select, TextField, MenuItem, Button, Typography, CircularProgress, Link as MLink, Grid, Chip, Divider, List, ListItem, ListItemText } from '@mui/material';

import { CloudUpload as CloudUploadIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import exactTargetGenres from '../../Utilities/exactTargetGenres';

import { useAuthDispatch, logout, useAuthState, changeUserTeam } from '../../../Context';

import {
    Info as InfoIcon,
    AddBox as AddBoxIcon,
    ArrowBack as ArrowBackIcon
} from '@mui/icons-material';

import { campaignTypes } from '../../../Config/campaigns';

import { getMonth, getDaysInMonth } from '../../Utilities/Utilities';

import IconButton from '@mui/material/IconButton';

import CampaignList from '../../Elements/CampaignList';
import useQuery from '../../Utilities/useQuery';
import useTitle from '../../Utilities/useTitle';
import FileUpload from '../../Elements/Forms/Fields/FileUpload';
import FieldContainer from '../../Elements/Forms/FieldContainer';
import Fieldset from '../../Elements/Forms/Fieldset';
import ContentBox from '../../Elements/ContentBox';
import ModalDialogue from '../../Elements/ModalDialogue';
import CampaignTypeSelector from './../../Elements/CampaignTypeSelector';
import PageContainer from './../../Elements/PageContainer';
import CountrySelector from './../../Elements/Forms/Fields/CountrySelector';
import HeroText from '../../Elements/HeroText';
import Hero from '../../Elements/Hero';
import ReleaseList from '../../Elements/ReleaseList';
import ReleaseListSingle from '../../Elements/ReleaseListSingle';
import HeroButtonGroup from '../../Elements/HeroButtonGroup';
import HeroButton from '../../Elements/HeroButton';
import Loader from '../../Elements/Loader';

import CampaignsHeroImage from '../../../assets/media/hero/campaigns.jpg';
import { LoadingButton } from '@mui/lab';
import TabGroup from '../../Elements/TabGroup';

export default function ReleasesIndex() {
    useTitle('Releases');

    let query = useQuery();
    const history = useHistory();

    const userDetails = useAuthState();

    if( !query.get('page') )
    {
        query.set('page', 0)
    }

    if( !query.get('perPage') )
    {
        query.set('perPage', 25)
    }

    //const [campaignPage, setCampaignPage] = useState({page: query.get('page') || 0, perPage: query.get('perPage') || 25});
    const [date, setDate] = useState( new Date() );
    const [days, setDays] = useState(null);

    const [releaseRecordLabel, setReleaseRecordLabel] = useState(null);
    const [releaseMajorRecordLabel, setReleaseMajorRecordLabel] = useState(null);
    const [releaseArtist, setReleaseArtist] = useState(null);

    const [releaseData, setReleaseData] = useState(null);
    const [releaseList, setReleaseList] = useState(null);
    const [releaseDataLoading, setReleaseDataLoading] = useState(false);

    const [releaseUploadModal, setReleaseUploadModal] = useState(false);

    const acquisitionRepository = new AcquisitionRepository();

    acquisitionRepository.setBearerToken(userDetails.accessToken);

    const insightAPI = new InsightAPI();
    insightAPI.setMode('bigquery');
    insightAPI.setBearerToken(userDetails.accessToken);

    const toggleReleaseUploadModal = event => {
        setReleaseUploadModal(!releaseUploadModal);
    }

    useEffect(() => {
        setReleaseData(null);

        let firstWeekDay = 0;
        let lastWeekDay = firstWeekDay === 0 ? 6 : firstWeekDay - 1;
        let days = getDaysInMonth(date);

        while( firstWeekDay !== days[0].getDay() )
        {
            let newDate = new Date(days[0]);
            newDate.setDate(newDate.getDate() - 1);
            days.unshift(newDate);
        }

        while( lastWeekDay !== days[days.length - 1].getDay() )
        {
            let newDate = new Date(days[days.length - 1]);
            newDate.setDate(newDate.getDate() + 1);
            days.push(newDate);
        }

        setDays(days);
    }, [date, releaseArtist, releaseMajorRecordLabel, releaseRecordLabel]);
    
    useEffect(() => {
        if( days )
        {
            setReleaseDataLoading(true);

            //let limit = query.get('perPage');
            let limit = 1000;

            let offset = query.get('page') !== 0 ? ( query.get('page') * query.get('perPage') ) : 0;

            let criteria = {
                releaseDate: {
                    from: days[0].getFullYear()+'-'+( days[0].getMonth() + 1 )+'-'+days[0].getDate(),
                    to: days[days.length-1].getFullYear()+'-'+( days[days.length-1].getMonth() + 1 )+'-'+days[days.length-1].getDate()
                },
                recordLabelName: releaseRecordLabel,
                majorRecordLabelName: releaseMajorRecordLabel,
                artist: releaseArtist,
            };

            let options = {
                limit: limit,
                offset: offset,
                include: ['campaign', 'campaign.locales', 'campaign.locales.countries', 'campaign.locales.translations']
            };

            acquisitionRepository.getReleases(options, criteria).then( response => {
                let releases = [];
                let items = response.getItems();

                for (const releaseKey in items) {
                    let release = items[releaseKey];

                    let preorderDate = release.getReleaseDate();
                    let key = preorderDate.getFullYear()+'-'+preorderDate.getMonth()+'-'+preorderDate.getDate();

                    let existingReleases = releases[key];
                    if( !existingReleases )
                    {
                        existingReleases = [];
                        releases[key] = existingReleases;
                    }

                    existingReleases.push(release);
                };

                setReleaseList(response);
                setReleaseData(releases);
                setReleaseDataLoading(false);
            });
    }   
    }, [days]);

    function handleChangePage(event, newValue){
        query.set('page', newValue);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function handleChangeRowsPerPage(event, newValue){
        query.set('perPage', newValue.props.value);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function labelDisplayedRows( { from, to, count, page } ){
        return `${from}–${to} of ${count !== -1 ? count.toLocaleString() : `more than ${to}`}`;
    }

    const prevMonth = () => {
        let newDate = new Date(date);
        newDate.setMonth( newDate.getMonth() - 1 );

        setDate(newDate);
    };

    const nextMonth = () => {
        let newDate = new Date(date);
        newDate.setMonth( newDate.getMonth() + 1 );

        setDate(newDate);
    };

    let tabData = [
        {
            label: 'Calendar',
            content: <>
                <Grid
                    container
                    spacing={0.5}
                    sx={{
                        minHeight: '1000px'
                    }}
                >
                    {
                        days && days.slice(0, 7).map((day, dayIndex) => {
                            return <Grid key={'title-'+dayIndex} item xs={1.71}>
                                <Typography mb={1} variant="subtitle1">{day.toLocaleDateString(navigator.language, { weekday: 'long' })}</Typography>
                            </Grid>;
                        })
                    }
                    {
                        ( days && releaseData ) && days.map((day, dayIndex) => {
                            let dayKey = day.getFullYear()+'-'+day.getMonth()+'-'+day.getDate();

                            let currentReleases = releaseData ? releaseData[dayKey] : null;

                            return <Grid
                                key={'day-'+dayIndex}
                                sx={{
                                    opacity: day.getMonth() === date.getMonth() ? 1 : 0.5,
                                    minHeight: '7rem',
                                    
                                }}
                                item
                                xs={1.71}
                            >
                                <Box p={1} sx={{bgcolor: 'background.paper', height: '100%', width: '100%'}}>
                                    <Typography variant="subtitle2">{day.getDate()}</Typography>
                                    <List>
        
                                    {
                                        currentReleases && currentReleases.map((currentRelease, currentReleaseIndex) => {
                                            return <Fragment key={currentReleaseIndex}>
                                                
                                                {currentReleaseIndex > 0 ? <Divider variant="inset" component="li" /> : <></>}
                                                <ListItem sx={{padding: 0}} alignItems="flex-start">
                                                    <ListItemText
                                                        primary={currentRelease.getTitle()}
                                                        secondary={currentRelease.getArtist()}
                                                    />
                                                </ListItem>
                                            </Fragment>
                                        })
                                    }
                                    </List>
                                </Box>
                            </Grid>;
                        })
                    }
                </Grid>
            </>
        },
        {
            label: 'List',
            content: <>
                { releaseDataLoading && <Loader /> }
                { !releaseDataLoading && releaseList &&
                    <ReleaseList data={releaseList.getItems()} />
                }
            </>
        }
    ];

    return (
        <>
        
            <ModalDialogue open={releaseUploadModal} size="large" onClose={toggleReleaseUploadModal}>
                <Box sx={{px: 2, pt: 2, pb: 1}}>
                    <Typography variant="h4">Import Schedule</Typography>
                </Box>
                <Fieldset>
                    <FieldContainer xs={12}>
                        <FileUpload
                            raw={true}
                            onChange={(acceptedFile) => {
                                let formData = new FormData();           
                                formData.append("file", acceptedFile);

                                var acquisitionAPI = new AcquisitionAPI();
                                acquisitionAPI.uploadReleases(formData).then(uploadResponse => {
                                    console.log(uploadResponse);
                                });
                            }}
                        />
                    </FieldContainer>
                </Fieldset>

            </ModalDialogue>

            <Hero image={CampaignsHeroImage}>
                <HeroText>
                    <Typography variant="h4" sx={{mb: 1}}>Releases</Typography>
                    <Typography paragraph>Here you can view all of WMG's upcoming releases along with their pre-release pages allowing fans to pre-order and pre-save new music.</Typography>
                    <Typography variant="button"><MLink component={Link} to="/resources">Learn More</MLink></Typography>
                </HeroText>
                <HeroButtonGroup>
                    <HeroButton color="secondary" onClick={toggleReleaseUploadModal}>Import Schedule<CloudUploadIcon /></HeroButton>
                </HeroButtonGroup>
            </Hero>

            <PageContainer>
                <Typography variant="h4">Releases</Typography>

                <Grid container spacing={1}  mb={2} alignItems="center" justifyContent="center">
                    <Grid item xs={4} textAlign="right">
                        <IconButton onClick={prevMonth} size="small">
                            <ChevronLeftIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="h5" textAlign="center">{getMonth(date.getMonth())} {date.getFullYear()}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <IconButton onClick={nextMonth} size="small">
                            <ChevronRightIcon />
                        </IconButton>
                    </Grid>

                    <Grid item xs={4}>
                        { releaseList &&
                            <Select
                                id="majorRecordLabel"
                                name="majorRecordLabel"
                                value={releaseMajorRecordLabel || ""}
                                fullWidth
                                displayEmpty={true}
                                onChange={e => setReleaseMajorRecordLabel(e.target.value || null)}
                            >
                                <MenuItem value="">{releaseMajorRecordLabel ? 'Clear Selection' : 'Major Label' }</MenuItem>

                                {[...new Set(releaseList.getItems().map((item) => item.getMajorRecordLabelName()))].sort().map((recordLabel) => (
                                    <MenuItem key={recordLabel} value={recordLabel}>{recordLabel}</MenuItem>
                                ))}
                            </Select>
                        }
                    </Grid>

                    <Grid item xs={4}>
                        { releaseList &&
                            <Select
                                id="recordLabel"
                                name="recordLabel"
                                value={releaseRecordLabel || ""}
                                fullWidth
                                disabled={!releaseMajorRecordLabel}
                                displayEmpty={true}
                                onChange={e => setReleaseRecordLabel(e.target.value || null)}
                            >
                                <MenuItem value="">{releaseMajorRecordLabel ? releaseRecordLabel ? 'Clear Selection' : 'Label' : 'Choose Major Label'}</MenuItem>

                                {[...new Set(releaseList.getItems().map((item) => item.getRecordLabelName()))].sort().map((recordLabel) => (
                                    <MenuItem key={recordLabel} value={recordLabel}>{recordLabel}</MenuItem>
                                ))}
                            </Select>
                        }
                    </Grid>

                    <Grid item xs={4}>
                        { releaseList &&
                            <Select
                                id="artist"
                                name="artist"
                                value={releaseArtist || ""}
                                fullWidth
                                displayEmpty={true}
                                onChange={e => setReleaseArtist(e.target.value || null)}
                            >
                                <MenuItem value="">{releaseArtist ? 'Clear Selection' : 'Artist' }</MenuItem>

                                {[...new Set(releaseList.getItems().map((item) => item.getArtist()))].sort().map((artist) => (
                                    <MenuItem key={artist} value={artist}>{artist}</MenuItem>
                                ))}
                            </Select>
                        }
                    </Grid>
                </Grid>

                <TabGroup sx={{mt: 4}} tabList={tabData} />
            </PageContainer>
        </>
    );
};
