import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import MLink from '@mui/material/Link';
import NoImage from './../../assets/img/no-image.png';
import Image from 'mui-image'
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

import { useHistory } from 'react-router-dom';

import ContextMenu from './ContextMenu.js';

import { Utilities } from '@warnerconnect/library';

import dateFormat, { masks } from "dateformat";
import { Tooltip } from '@mui/material';

export default function ReleaseListSingle(props) {
    let image = props.data.getCampaign() ? props.data.getCampaign().getDataValue(null, null, 'theme.image.url') : null;
    const history = useHistory();

    var contextLinks = [];

    var status = 'warning';
    var statusText = 'We\'re still getting this release ready but everyhting is on track.';

    let today = new Date();
    let releaseDate = props.data.getReleaseDate();
    let dayBeforeReleaseDate = new Date(releaseDate);

    dayBeforeReleaseDate.setDate(dayBeforeReleaseDate.getDate() - 1)

    if( today > dayBeforeReleaseDate )
    {
        if( !props.data.getURLs() )
        {
            status = 'error';
            statusText = 'This release requires attention.';
        }
        else if( props.data.getCampaign() && props.data.getURLs() )
        {
            let integrations = props.data.getCampaign().getDefaultLocale().getIntegrations();
            let urlServices = [];
    
            if( integrations )
            {
                let parsedURLs = Utilities.parseUris(props.data.getURLs());
                parsedURLs.forEach(parsedURL => {
                    if( !urlServices.includes(parsedURL.service) )
                    {
                        urlServices.push(parsedURL.service);
                    }
                })
            }
    
            if( urlServices.length === integrations.length )
            {
                status = 'success';
                statusText = 'This release is on track and requires no changes.';
            }
    
            contextLinks.push({label: 'View Landing Page', onClick: () => window.location.href = process.env.REACT_APP_FRONTEND_URL+'/app/'+props.data.getCampaign().getId()+'/'+props.data.getCampaign().getUrlSlug()});
        }
    }
    
    if( !props.data.getUpcs() )
    {
        status = 'error';
        statusText = 'This release requires attention as there\'s no associated UPC.';
    }

    contextLinks.push({label: 'Stats', onClick: () => history.push('/campaigns/'+props.data.getCampaign().getId()+'/stats')});
    contextLinks.push({label: 'Edit', onClick: () => history.push('/campaigns/'+props.data.getCampaign().getId()+'/edit')});

    return (
        <TableRow key={props.data.getId()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell sx={{width: '5%', paddingRight: 0}} component="th" scope="row">
                <Image sx={{maxWidth: '40px', borderRadius: '3px'}} src={image || NoImage} />
            </TableCell>
            <TableCell sx={{width: '30%', paddingRight: 0}} component="th" scope="row">
                {
                    props.data.getCampaign() ?
                        //<Typography variant="body1"><MLink title={props.data.getType()} component={Link} to={`/campaigns/${props.data.getCampaign().getId()}/stats`}>{props.data.getTitle()}</MLink></Typography>
                        <Typography variant="body1"><MLink target="_blank" title={props.data.getType()} href={`${process.env.REACT_APP_FRONTEND_URL}/app/${props.data.getCampaign().getId()}/${props.data.getCampaign().getUrlSlug()}`}>{props.data.getTitle()}</MLink></Typography>
                    :
                        <Typography variant="body1">{props.data.getTitle()}</Typography>
                }
                <Typography variant="subtitle2">{props.data.getArtist()}</Typography>
            </TableCell>
            <TableCell sx={{width: '35%', paddingRight: 0}}>
                <Typography variant="subtitle2">{props.data.getRecordLabelName()}<br />{props.data.getMajorRecordLabelName()}</Typography>
            </TableCell>
            <TableCell sx={{width: '10%', paddingRight: 0}}>
                <Typography variant="subtitle2">{props.data.getReleaseDate() ? dateFormat(props.data.getReleaseDate(), "mmm dS yyyy") : ''}</Typography>
            </TableCell>
            <TableCell align="center" sx={{width: '8%', paddingRight: 0}}>
                <Tooltip title={statusText}><CheckCircleIcon color={status} /></Tooltip>
            </TableCell>
            <TableCell sx={{width: '7%', paddingRight: 0}}>
                {
                    props.data.getCampaign() &&
                        <ContextMenu
                            options={contextLinks}
                        />
                }
            </TableCell>
        </TableRow>
    );
}