import React, { Fragment, useState } from 'react';

import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, TablePagination, Chip, Box, Button, ButtonGroup, Typography, CircularProgress, Stack, Divider, Paper, TextField, Tooltip, Link as MLink, InputLabel, Grid, Autocomplete, NativeSelect, Select, MenuItem, InputAdornment, List, ListItem, ListItemText, Checkbox, IconButton, ListItemIcon, ListItemButton } from '@mui/material';

import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Info as InfoIcon
} from '@mui/icons-material';
import ContextMenu from '../../ContextMenu';

import Flag from 'react-world-flags'

export default function ImageRadio(props) {
    const [formFieldData, setFormFieldData] = useState(props.value);

    const handleFormFieldDataChange = event => {
        setFormFieldData(event.target.value);

        if( props.onChange )
        {
            props.onChange(event.target.value);
        }
    }

    let height = 333;
    let width = '88%';

    if( props.states.length === 3 )
    {
        height = 260;
        width = '82%';
    }

    return (

            <Grid
                component={RadioGroup}
                container
                defaultValue={0}
                value={formFieldData}
                justifyContent="space-between"
                onChange={handleFormFieldDataChange}
            >

                {props.states.map((state) => (
                    <Grid
                        component={InputLabel}
                        sx={{
                            height: height.toString() + 'px',
                            backgroundColor: ( state.value == formFieldData ? 'rgba(70, 190, 230, .1)' : '#ffffff' ),
                            backgroundImage: 'url('+( state.value == formFieldData ? state.active : state.inactive )+')',
                            backgroundPosition: 'center 65%',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: width
                         }}
                        key={state.value}
                        item
                        xs={11.5 / props.states.length}
                    >
                        <Radio value={state.value} />
                        <InputLabel sx={{paddingLeft: '48px', paddingTop: '27px'}} shrink>{state.label}</InputLabel>
                    </Grid>
                ))}
            </Grid>
    );
}