import React, { useState, useEffect, useReducer } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { UserRepository, UserAPI, AcquisitionRepository, AcquisitionAPI } from '@warnerconnect/library';

import { TablePagination, Box, InputLabel, Tooltip, InputAdornment, Select, TextField, MenuItem, Button, Typography, CircularProgress, Link as MLink, Checkbox, ListItemText, OutlinedInput, Autocomplete, FormControlLabel, Switch, TableContainer, Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material';

import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';

import { useAuthDispatch, logout, useAuthState, changeUserUser } from '../../../Context';
import { formReducer } from '../../../Context/formReducer';
import { useSnackbar } from "notistack";

import {
    Info as InfoIcon,
    AddBox as AddBoxIcon,
    Save as SaveIcon,
    RemoveCircleOutline as RemoveCircleOutlineIcon
} from '@mui/icons-material';

import UserList from '../../Elements/UserList';

import useQuery from '../../Utilities/useQuery';
import useTitle from '../../Utilities/useTitle';
import FieldContainer from '../../Elements/Forms/FieldContainer';
import UserSearch from '../../Elements/Forms/Fields/UserSearch';
import Fieldset from '../../Elements/Forms/Fieldset';
import ContentBox from '../../Elements/ContentBox';
import ModalDialogue from '../../Elements/ModalDialogue';
import PageContainer from './../../Elements/PageContainer';
import CountrySelector from './../../Elements/Forms/Fields/CountrySelector';
import RecordLabelSelector from './../../Elements/Forms/Fields/RecordLabelSelector';
import HeroText from '../../Elements/HeroText';
import Hero from '../../Elements/Hero';
import HeroButtonGroup from '../../Elements/HeroButtonGroup';
import HeroButton from '../../Elements/HeroButton';
import Loader from '../../Elements/Loader';
import TabGroup from '../../Elements/TabGroup';

import UsersHeroImage from '../../../assets/media/hero/campaigns.jpg';
import { LoadingButton } from '@mui/lab';
import TeamList from '../../Elements/TeamList';

export default function UsersIndex() {
    useTitle('Users');

    let query = useQuery();
    const history = useHistory();
    const location = useLocation();

    const { enqueueSnackbar } = useSnackbar();

    const userDetails = useAuthState();

    if( !query.get('page') )
    {
        query.set('page', 0)
    }

    if( !query.get('perPage') )
    {
        query.set('perPage', 25)
    }
    
    const [userEditStep, setUserEditStep] = useState(0);

    const [countryData, setCountryData] = useState();
    const [countryDataLoading, setCountryDataLoading] = useState(true);
    const [recordLabelData, setRecordLabelData] = useState();
    const [recordLabelDataLoading, setRecordLabelLoading] = useState(true);

    const [userData, setUserData] = useState();
    const [userDataLoading, setUserDataLoading] = useState(false);
    const [userDataEvents, setUserDataEvents] = useState(0);

    const userRepository = new UserRepository();
    userRepository.setBearerToken(userDetails.accessToken);

    const acquisitionRepository = new AcquisitionRepository();
    acquisitionRepository.setBearerToken(userDetails.accessToken);

    const userAPI = new UserAPI();
    userAPI.setBearerToken(userDetails.accessToken);

    const [userSearchModal, setUserSearchModal] = useState(false);
    const [userSearchLabelData, setUserSearchLabelData] = useState();
    const [userSearchLabelDataLoading, setUserSearchLabelDataLoading] = useState(false);

    const [userEditUserSearchData, setUserEditUserSearchData] = useState({});

    const [locationData, setLocationData] = useState([]);

    const [userEditModal, setUserEditModal] = useState(false);
    const [userEditTeams, setUserEditTeams] = useState();
    const [userEditPermissions, setUserEditPermissions] = useState();
    const [userEditLoading, setUserEditLoading] = useState(false);
    const [userEditSubmitting, setUserEditSubmitting] = useState(false);

    const [userNotApprovedDeleteModal, setUserNotApprovedDeleteModal] = useState(false);
    const [userNotApprovedDeleteSubmitting, setUserNotApprovedDeleteSubmitting] = useState(false);

    const [userPermissionCreateData, setUserPermissionCreateData] = useReducer(formReducer, {});

    const [userEditData, setUserEditData] = useReducer(
        ( currentValues, newValues ) => ({...currentValues, ...newValues}), {}
    );

    const [userSearchData, setUserSearchData] = useReducer(
        ( currentValues, newValues ) => ({...currentValues, ...newValues}), {approved: 1}
    );

    const refreshUserData = event => {
        setUserDataEvents(userDataEvents + 1);
    }

    const toggleUserSearchModal = event => {
        setUserSearchModal(!userSearchModal);
    }

    const toggleUserNotApprovedDeleteModal = event => {
        setUserNotApprovedDeleteModal(!userNotApprovedDeleteModal);
    }

    const closeUserEditModal = event => {
        history.push('/users');
    }
    
    const setUserPermissionCreateDataSingle = (name, value) => {
        setUserPermissionCreateData({ name: name, value: value});
    };

    const setUserEditPermissionsSingle = (name, value) => {
        let newPermissions = [...userEditPermissions];

        newPermissions.find(permission => permission.key = name).value = value;

        setUserEditPermissions(newPermissions);
    };

    const setUserEditDataSingle = (name, value) => {
        setUserEditData({ [name]: value });
    };

    const setUserSearchDataSingle = (name, value) => {
        setUserSearchData({ [name]: value });
    };

    const handleChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setUserEditDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    const handlePermissionCreateChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setUserPermissionCreateDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    const handlePermissionEditChange = (permissionName) => (e) => {
        let value = e.target.value;
    }

    const handleSearchChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setUserSearchDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    const userPermissions = [
        {
            label: 'Audience Countries',
            key: 'audienceCountries'
        },
        {
            label: 'Campaign Export Access',
            key: 'exportAccess'
        },
        {
            label: 'Audience Builder Export Access',
            key: 'audienceBuilderExportAccess'
        },
        {
            label: 'Audience Suite Admin',
            key: 'audienceSuiteAdmin'
        },
        {
            label: 'Advanced Opt In',
            key: 'advancedOptIn'
        }
    ];

    // Detect path change to hand page edit
    useEffect(() => {
        let locationParts = location.pathname.split('/');

        setLocationData(locationParts);
    }, [location]);

    useEffect(() => {
        setUserEditLoading(false);
        setUserEditModal(false);
        setUserEditData({});
        setUserEditTeams({});
        setUserEditPermissions([]);

        if( locationData[2] && locationData[3] && ( locationData[3] === 'edit' ) )
        {
            setUserEditLoading(true);
            setUserEditModal(true);

            let userId = parseInt(locationData[2]);

            userRepository.getUser(userId, {include: ['teams', 'permissions']}).then( user => {
                setUserEditData({
                    id: user.getId(),
                    name: user.getName(),
                    email: user.getEmail(),
                    global: user.getGlobal()
                });

                setUserEditTeams(user.getTeams());
                setUserEditPermissions(Array.from(user.getUserPermissions(), permission => {
                    return {
                        name: permission.getName(),
                        value: permission.getValue()
                    };
                }));

                setUserEditLoading(false);
            });
        }
        
        if( locationData[2] && ( locationData[2] === 'pending-approval' ) && ( userSearchData['approved'] === 1 ) )
        {
            setUserSearchDataSingle(
                'approved',
                0
            );
        }
        else if( ( !locationData[2] || ( locationData[2] !== 'pending-approval' ) ) && ( userSearchData['approved'] === 0 ) )
        {
            setUserSearchDataSingle(
                'approved',
                1
            );
        }

    }, [locationData]);

    useEffect(() => {
        acquisitionRepository.getCountries({limit: 200})
            .then( countries => {
                var countryData = [];

                countries.getItems().forEach(country => {
                    countryData.push(country);
                });

                setCountryData(countryData);
                setCountryDataLoading(false);
            });

        acquisitionRepository.getRecordLabels({limit: 200})
            .then( recordLabels => {
                var recordLabelOptions = [];

                recordLabels.getItems().forEach(recordLabel => {
                    recordLabelOptions.push({
                        label: recordLabel.getName(),
                        id: recordLabel.getId()
                    });

                    if( recordLabel.getChildRecordLabels() )
                    {
                        recordLabel.getChildRecordLabels().forEach(childRecordLabel => {
                            recordLabelOptions.push({
                                label: ' - '+childRecordLabel.getName(),
                                id: childRecordLabel.getId()
                            });
                        });
                    }
                });

                setRecordLabelData(recordLabelOptions);
                setRecordLabelLoading(false);
            });

    }, []);

    useEffect(() => {
        setUserDataLoading(true);

        let limit = query.get('perPage');

        let offset = query.get('page') !== 0 ? ( query.get('page') * query.get('perPage') ) : 0;

        let criteria = userSearchData || {};

        let options = {
            limit: limit,
            offset: offset,
            include: ['childUsers', 'countries', 'regions', 'users']
        };

        userRepository.getUsers(options, criteria).then( response => {
            setUserData(response);
            setUserDataLoading(false);
        });
    }, [userDetails.user, query.get('page'), query.get('perPage'), userDataEvents, userSearchData]);


    function handleChangePage(event, newValue){
        query.set('page', newValue);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function handleChangeRowsPerPage(event, newValue){
        query.set('perPage', newValue.props.value);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function labelDisplayedRows( { from, to, count, page } ){
        return `${from}–${to} of ${count !== -1 ? count.toLocaleString() : `more than ${to}`}`;
    }

    return (
        <>
            <ModalDialogue open={userEditModal} size="large" onClose={closeUserEditModal}>
                { userEditLoading ?
                    <Loader size="x-small" />
                    :
                    <>
                        <Box sx={{px: 2, pt: 2, pb: 1}}>
                            <Typography variant="h4">Edit {userEditData['name']}</Typography>                    
                        </Box>
                        <TabGroup
                            tabList={[
                                {
                                    label: 'Details',
                                    content: <>
                                        <Fieldset>
                                            <FieldContainer xs={12}>
                                                <InputLabel shrink>Name</InputLabel>
                                                <TextField
                                                    name="name"
                                                    fullWidth
                                                    value={userEditData['name'] || ''}
                                                    onChange={handleChange}
                                                />
                                            </FieldContainer>

                                            <FieldContainer xs={12}>
                                                <InputLabel shrink>Email</InputLabel>
                                                <TextField
                                                    name="email"
                                                    fullWidth
                                                    value={userEditData['email'] || ''}
                                                    onChange={handleChange}
                                                />
                                            </FieldContainer>

                                            <FieldContainer xs={12}>
                                                <InputLabel shrink>Global <Tooltip title={<>Does this user need to have visibility on everything globally, for instance, should this user be able to see an Ed Sheeran campaign so they can share the link?</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            name="global"
                                                            onChange={handleChange}
                                                            checked={userEditData['global'] ? true : false}
                                                        />
                                                    }
                                                />
                                            </FieldContainer>

                                            <FieldContainer xs={12} sx={{textAlign: 'right'}}>
                                                <LoadingButton
                                                    sx={{mb: 2}}
                                                    type="submit"
                                                    loading={userEditSubmitting}
                                                    onClick={() => {
                                                        setUserEditSubmitting(true);

                                                        userRepository
                                                            .updateUser(userEditData['id'], {
                                                                name: userEditData['name'],
                                                                email: userEditData['email'],
                                                                global: userEditData['global']
                                                            })
                                                            .then(updatedUser => {
                                                                setUserEditSubmitting(false);

                                                                enqueueSnackbar("User "+updatedUser.getName()+" updated", {
                                                                    variant: 'success'
                                                                });
                        
                                                                refreshUserData();
                                                            });
                                                    }}
                                                    loadingPosition="end"
                                                    variant="contained"
                                                    endIcon={<SaveIcon />}
                                                >
                                                    Save Changes
                                                </LoadingButton>
                                            </FieldContainer>
                                        </Fieldset>
                                    </>
                                },
                                {
                                    label: 'Change Password',
                                    content: <>
                                        <Fieldset>
                                            <FieldContainer xs={12}>
                                                <InputLabel shrink>New Password</InputLabel>
                                                <TextField
                                                    name="newPassword"
                                                    type="password"
                                                    fullWidth
                                                    value={userEditData['newPassword'] || ''}
                                                    onChange={handleChange}
                                                />
                                            </FieldContainer>

                                            <FieldContainer xs={12}>
                                                <InputLabel shrink>Confirm Password</InputLabel>
                                                <TextField
                                                    name="newPasswordConfirm"
                                                    type="password"
                                                    fullWidth
                                                    value={userEditData['newPasswordConfirm'] || ''}
                                                    onChange={handleChange}
                                                />
                                            </FieldContainer>

                                            <FieldContainer xs={12} sx={{textAlign: 'right'}}>
                                                <LoadingButton
                                                    sx={{mb: 2}}
                                                    type="submit"
                                                    loading={userEditSubmitting}
                                                    disabled={!(userEditData['newPasswordConfirm'] && ( userEditData['newPassword'] === userEditData['newPasswordConfirm'] ))}
                                                    onClick={() => {
                                                        setUserEditSubmitting(true);

                                                        userRepository
                                                            .updateUser(userEditData['id'], {
                                                                password: userEditData['newPassword']
                                                            })
                                                            .then(updatedUser => {
                                                                setUserEditSubmitting(false);

                                                                enqueueSnackbar("User "+updatedUser.getName()+" password changed", {
                                                                    variant: 'success'
                                                                });
                                                            });
                                                    }}
                                                    loadingPosition="end"
                                                    variant="contained"
                                                    endIcon={<SaveIcon />}
                                                >
                                                    Change Password
                                                </LoadingButton>
                                            </FieldContainer>
                                        </Fieldset>
                                    </>
                                },
                                {
                                    label: 'Teams',
                                    content: userEditTeams &&

                                        <TeamList
                                            noRecordsLabel="This user isn't a member of any teams"
                                            fields={[
                                                {
                                                    name: 'name',
                                                    width: '50%'
                                                },
                                                {
                                                    name: 'territories',
                                                    width: '50%'
                                                }
                                            ]}
                                            data={userEditTeams}
                                        />

                                },
                                {
                                    label: 'Permissions',
                                    content: 
                                    <>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{width: '45%', paddingRight: 0}}><Typography variant="body1">Name</Typography></TableCell>
                                                        <TableCell sx={{width: '45%', paddingRight: 0}}><Typography variant="body1">Value</Typography></TableCell>
                                                        <TableCell sx={{width: '10%', paddingRight: 0}}></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            </Table>
                                            <ContentBox sx={{p: 0}}>
                                                <Table>
                                                    <TableBody>
                                                        {
                                                            (!userEditPermissions || ( userEditPermissions.length === 0 )) ?
                                                            <TableRow>
                                                                <TableCell colSpan={3} sx={{width: '100%', paddingRight: 0, textAlign: 'center'}} component="th" scope="row">
                                                                    <Typography variant="body1">No permissions to display</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                            :
                                                            userEditPermissions.map(permission => {
                                                                return <TableRow key={permission.name}>
                                                                        <TableCell sx={{width: '40%', paddingRight: 0, verticalAlign: 'top'}}>
                                                                            <TextField
                                                                                name="name"
                                                                                fullWidth
                                                                                disabled
                                                                                value={userPermissions.find(userPermission => userPermission.key === permission.name).label}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{width: '45%', paddingRight: 0, verticalAlign: 'top'}} valign="top">
                                                                            {/*<TextField
                                                                                name="value"
                                                                                fullWidth
                                                                                value={permission.getValue()}
                                                                                onChange={handlePermissionEditChange(permission.getName())}
                                                            />*/}
                                                                            {
                                                                                ( ( permission.name === 'audienceCountries' ) && countryData ) &&
                                                                                    <CountrySelector
                                                                                        name="value"
                                                                                        value={permission.value ? countryData.filter(countryDataSingle => JSON.parse(permission.value).includes(countryDataSingle.getA2())) : []}
                                                                                        options={countryData}
                                                                                        onChange={(event, newValue) => {
                                                                                            setUserEditPermissionsSingle('value', JSON.stringify(Array.from(newValue, countrySingle => countrySingle.getA2())));
                                                                                        }}
                                                                                    />
                                                                            }
                                                                            {
                                                                                ( ['exportAccess', 'audienceBuilderExportAccess', 'audienceSuiteAdmin', 'advancedOptIn'].includes(permission.name) ) &&
                                                                                    <Select
                                                                                        name="value"
                                                                                        fullWidth
                                                                                        value={permission.value || false}
                                                                                        onChange={handlePermissionCreateChange}
                                                                                    >
                                                                                         <MenuItem value={false}>No</MenuItem>
                                                                                         <MenuItem value={true}>Yes</MenuItem>
                                                                                    </Select>
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell sx={{width: '15%', paddingRight: 0, verticalAlign: 'middle'}}>
                                                                            <LoadingButton
                                                                                color="neutral"
                                                                                sx={{mb: 0}}
                                                                                onClick={() => {
                                                                                    userRepository.putUserPermission(userEditData['id'], permission.name, permission.value)
                                                                                        .then(response => {

                                                                                        });
                                                                                }}
                                                                            >
                                                                                <SaveIcon />
                                                                            </LoadingButton>
                                                                            <LoadingButton
                                                                                color="neutral"
                                                                                sx={{mb: 0}}
                                                                                onClick={() => {
                                                                                    userRepository.deleteUserPermission(userEditData['id'], permission.name)
                                                                                        .then(response => {
                                                                                            let userEditPermissionIndex = userEditPermissions.findIndex(userEditPermission => userEditPermission.name === permission.name);
                                                                                            let userEditPermissionsNew = [...userEditPermissions];

                                                                                            userEditPermissionsNew.splice(userEditPermissionIndex, 1);

                                                                                            setUserEditPermissions(userEditPermissionsNew);
                                                                                        });
                                                                                }}
                                                                            >
                                                                                <RemoveCircleOutlineIcon />
                                                                            </LoadingButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </ContentBox>
                                        </TableContainer>

                                        <Fieldset>
                                            <FieldContainer xs={12}>
                                                <Typography variant="h5">Add New Permission</Typography>
                                            </FieldContainer>

                                            <FieldContainer xs={6}>
                                                <InputLabel shrink>Permission Type</InputLabel>
                                                <Select
                                                    name="name"
                                                    fullWidth
                                                    value={userPermissionCreateData['name'] || ''}
                                                    onChange={handlePermissionCreateChange}
                                                >
                                                    {
                                                        userEditPermissions &&
                                                            userPermissions.map( (item) => {
                                                                if(!userEditPermissions.find(userEditPermission => userEditPermission.name === item.key))
                                                                {
                                                                    return <MenuItem key={item.key} value={item.key}>{item.label}</MenuItem>
                                                                }
                                                            })
                                                    }
                                                </Select>
                                            </FieldContainer>
                                            <FieldContainer xs={6}>
                                                <InputLabel shrink>Permission Scope</InputLabel>
                                                {
                                                    ( ( userPermissionCreateData['name'] === 'audienceCountries' ) && countryData ) &&
                                                        <CountrySelector
                                                            name="value"
                                                            value={userPermissionCreateData['value'] ? countryData.filter(countryDataSingle => JSON.parse(userPermissionCreateData['value']).includes(countryDataSingle.getA2())) : []}
                                                            options={countryData}
                                                            onChange={(event, newValue) => {
                                                                setUserPermissionCreateDataSingle('value', JSON.stringify(Array.from(newValue, countrySingle => countrySingle.getA2())));
                                                            }}
                                                        />
                                                }
                                                {
                                                    ( ['audienceSuiteAdmin', 'exportAccess', 'advancedOptIn', 'audienceBuilderExportAccess'].includes(userPermissionCreateData['name']) ) &&
                                                        <Select
                                                            name="value"
                                                            fullWidth
                                                            value={userPermissionCreateData['value'] || false}
                                                            onChange={(event) => {
                                                                setUserPermissionCreateDataSingle('value', event.target.value);
                                                            }}
                                                        >
                                                                <MenuItem value={false}>No</MenuItem>
                                                                <MenuItem value={true}>Yes</MenuItem>
                                                        </Select>
                                                }
                                            </FieldContainer>
                                            <FieldContainer xs={6}>
                                                <LoadingButton
                                                    sx={{mb: 2}}
                                                    type="submit"
                                                    disabled={!(userPermissionCreateData['name'] && userPermissionCreateData['value'])}
                                                    onClick={() => {
                                                        userRepository.putUserPermission(userEditData['id'], userPermissionCreateData['name'], userPermissionCreateData['value'])
                                                            .then(response => {
                                                                let newPermissions = [...userEditPermissions];

                                                                newPermissions.push({
                                                                    name: response.getName(),
                                                                    value: response.getValue()
                                                                });

                                                                setUserEditPermissions(newPermissions);
                                                                setUserPermissionCreateData({type: 'reset'});
                                                            });
                                                    }}
                                                    loadingPosition="end"
                                                    variant="contained"
                                                    endIcon={<AddBoxIcon />}
                                                >
                                                    Add Permission
                                                </LoadingButton>
                                            </FieldContainer>

                                        </Fieldset>

                                    </>

                                }
                            ]}
                        />
                    </>
                    
                }
            </ModalDialogue>

            <ModalDialogue open={userNotApprovedDeleteModal} size="large" onClose={toggleUserNotApprovedDeleteModal}>
                <Box sx={{px: 2, pt: 2, pb: 1}}>
                    <Typography variant="h4" gutterBottom>Decline Remaining</Typography>                    

                    <Typography variant="body1">Are you sure you want to refuse all remaining users that aren't approved? This will delete these users and can't be un-done.</Typography>

                    <Box sx={{textAlign: 'right'}}>
                        <Button
                            onClick={toggleUserNotApprovedDeleteModal}
                            sx={{mt: 2, ml: 2}}
                        >
                            Cancel
                        </Button>

                        <Button
                            onClick={() => {
                                userAPI.deleteNotAllNotApprovedUsers().then(response => {
                                    enqueueSnackbar(response.total.toLocaleString()+" remaining users deleted", {
                                        variant: 'success'
                                    });

                                    toggleUserNotApprovedDeleteModal();
                                    refreshUserData();
                                });
                            }}
                            sx={{mt: 2, ml: 2}
                        }>
                            Delete
                        </Button>
                    </Box>
                </Box>

            </ModalDialogue>

            <ModalDialogue open={userSearchModal} size="large" onClose={toggleUserSearchModal}>
                <Box sx={{px: 2, pt: 2, pb: 1}}>
                    <Typography variant="h4">Search</Typography>                    
                </Box>

                <Fieldset>
                    <FieldContainer xs={12}>
                        <InputLabel shrink>Keywords</InputLabel>
                        <TextField
                            name="keywords"
                            fullWidth
                            value={userSearchData['keywords'] || ''}
                            onChange={handleSearchChange}
                        />
                    </FieldContainer>
                </Fieldset>
            </ModalDialogue>

            <Hero image={UsersHeroImage}>
                <HeroText>
                    <Typography variant="h4" sx={{mb: 1}}>Welcome</Typography>
                    <Typography paragraph>Here you can edit existing users and create new users.</Typography>
                    <Typography variant="button"><MLink component={Link} to="/resources">Learn More</MLink></Typography>
                </HeroText>
                {
                    ( locationData[2] && ( locationData[2] === 'pending-approval') ) &&
                    <HeroButtonGroup>
                        <HeroButton onClick={toggleUserNotApprovedDeleteModal} color="error">Decline Remaining<RemoveCircleOutlineIcon /></HeroButton>
                    </HeroButtonGroup>
                }

            </Hero>
            <PageContainer>
                <Typography variant="h4">{userSearchData['approved'] ? 'Users' : 'User Pending Approval'}</Typography>
                { userDataLoading && <Loader /> }
                { !userDataLoading && userData &&
                <>
                    <UserList
                        filter={toggleUserSearchModal}
                        data={userData.getItems()}
                        onDelete={userId => {
                            return userRepository.deleteUser(userId)
                                .then(deleteUserResponse => {
                                    enqueueSnackbar("User deleted", {
                                        variant: 'success'
                                    });

                                    refreshUserData();

                                    return deleteUserResponse;
                                });
                        }}
                        onApprovedChange={(userId, approved) => {
                            if( approved )
                            {
                                return userAPI.approveUser(userId)
                                    .then(approveUserResponse => {
                                        enqueueSnackbar("User approved", {
                                            variant: 'success'
                                        });

                                        refreshUserData();

                                        return approveUserResponse;
                                    });
                            }
                            else
                            {
                                return userAPI.deleteUser(userId)
                                .then(deleteUserResponse => {
                                    enqueueSnackbar("User refused and deleted", {
                                        variant: 'success'
                                    });

                                    refreshUserData();

                                    return deleteUserResponse;
                                });
                            }

                        }}
                    />
                    {
                        ( userData.getTotal() > 0 ) &&
                            <TablePagination
                                component="div"
                                count={userData.getTotal()}
                                page={parseInt(query.get('page'))}
                                rowsPerPage={parseInt(query.get('perPage'))}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelDisplayedRows={labelDisplayedRows}
                            />
                    }
                </>
                }
            </PageContainer>
        </>
    );
};
