import React, { Fragment, useState } from 'react';

import { supportedIntegrations } from '../../../Utilities/Services';
import { TablePagination, Collapse, Avatar, ListItemAvatar, Box, Button, Typography, Switch, CircularProgress, Stack, Divider, Paper, TextField, Tooltip, Link as MLink, InputLabel, Grid, Autocomplete, NativeSelect, Select, MenuItem, InputAdornment, List, ListItem, ListItemText, Checkbox, IconButton, ListItemIcon, ListItemButton, FormControlLabel, RadioGroup, Radio, Chip, Snackbar, Alert } from '@mui/material';

import { Utilities } from '@warnerconnect/library';

import { useSnackbar } from "notistack";

import {
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    Info as InfoIcon,
    Folder as FolderIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    ShowChart as ShowChartIcon,
    Visibility as VisibilityIcon,
    MoreHoriz as MoreHorizIcon,
    ContentCopy as ContentCopyIcon
} from '@mui/icons-material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpotify, faApple, faDeezer, faYoutube, faAmazon, faVk } from '@fortawesome/free-brands-svg-icons'

import Flag from 'react-world-flags'

export default function UriList(props) {
    const { enqueueSnackbar } = useSnackbar();

    let newValuesValid = props.value ? Array.from(Utilities.parseUris(props.value), newValuesFinalUniqueSingle => newValuesFinalUniqueSingle.uri) : null;

    const [uriListData, setUriListData] = useState(newValuesValid);
    const [uriData, setUriData] = useState();

    var serviceOpenStateInitial = {};
    supportedIntegrations.map(serviceElement => {
        serviceOpenStateInitial[serviceElement.id] = false;
    });

    const [serviceOpenState, setServiceOpenState] = useState(serviceOpenStateInitial);

    const handleServiceToggle = (service) => {
        var newServiceOpenState = {...serviceOpenState};
        newServiceOpenState[service] = !newServiceOpenState[service];
        setServiceOpenState(newServiceOpenState);
    };

    const handleDelete = (uri) => {
        let uriListDataNew = [...uriListData];

        var index = uriListDataNew.indexOf(uri);

        if (index > -1)
        {
            uriListDataNew.splice(index, 1);
        }

        if( props.onChange )
        {
            props.onChange(uriListDataNew);
        }

        setUriListData(uriListDataNew);
    }

    const handleAdd = (e) => {
        let newValuesRaw = e.clipboardData.getData('Text')
            .split(/[\s\r\n,]/)
            .filter(n => n);

        let newValuesRawTotal = newValuesRaw.length;
        let newValues = [...new Set(newValuesRaw)];

        let newValuesTotal = newValues.length;
        let existingValues = uriListData ? [...uriListData] : [];
        let existingValuesTotal = existingValues.length;


        let newValuesValid = Array.from(Utilities.parseUris(newValues), newValuesFinalUniqueSingle => newValuesFinalUniqueSingle.uri);

        let newValuesValidTotal = newValuesValid.length;

        let newValuesFinal = [...existingValues.concat(newValuesValid)];
        let newValuesFinalTotal = newValuesFinal.length;
        let newValuesFinalUnique = [...new Set(newValuesFinal)];
        let newValuesFinalUniqueTotal = newValuesFinalUnique.length;

        const snackbarAdded = newValuesFinalUniqueTotal - existingValuesTotal;
        const snackbarDuplicate = newValuesFinalTotal - newValuesFinalUniqueTotal;
        const snackbarInvalid = newValuesRawTotal - newValuesValidTotal;

        if( snackbarAdded > 0 )
        {
            enqueueSnackbar(`${snackbarAdded} new ${snackbarAdded > 1 ? 'URIs' : 'URI'} added!`, {
                variant: 'success'
            });
        }

        if( snackbarDuplicate > 0 )
        {
            enqueueSnackbar(`${snackbarDuplicate} duplicate ${snackbarDuplicate > 1 ? 'URIs' : 'URI'} not added!`, {
                variant: 'warning'
            });
        }

        if( snackbarInvalid > 0 )
        {
            enqueueSnackbar(`${snackbarInvalid} invalid ${snackbarInvalid > 1 ? 'URIs' : 'URI'} not added!`, {
                variant: 'error'
            });
        }

        if( props.onChange )
        {
            props.onChange( newValuesFinalUnique );
        }

        setUriListData( newValuesFinalUnique );

        setUriData('');
    };

    return (
        <>
            <TextField
                multiline
                fullWidth
                placeholder="Paste URIs"
                onPaste={handleAdd}
                value={uriData}
            />

            { ( uriListData && uriListData.length > 0 ) &&
            <Box elevation={0} component={Paper} mt={1}>
                <List dense={true}>
                {
                    uriListData &&
                        [...new Set(Utilities.parseUris(uriListData).map(item => item.service))]
                            .sort((a, b) => (a > b) ? 1 : -1)
                            .map((service, serviceIndex) => {
                                let formattedService = service;
                                if( service === 'youtube' )
                                {
                                    formattedService = 'youTube';
                                }

                                var serviceData = supportedIntegrations.find(serviceElement => serviceElement.id === formattedService);
                                var serviceLinks = [...new Set(Utilities.parseUris(uriListData).filter(item => item.service === service ))];

                                return (
                                    <Fragment key={serviceIndex}>
                                        <ListItemButton onClick={() => handleServiceToggle(service)}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    {serviceData.icon}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={serviceData.label} secondary={`${serviceLinks.length} links`} />
                                            {serviceOpenState[service] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </ListItemButton>
                                        <Collapse in={serviceOpenState[service]} timeout="auto" unmountOnExit>
                                            <List dense={true} component="div" disablePadding>
                                                {
                                                    serviceLinks.map((parsedUri, parsedUriIndex) => {
                                                        return (
                                                            <ListItem key={parsedUriIndex}
                                                                secondaryAction={
                                                                    <>
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                navigator.clipboard.writeText(parsedUri.uri);
                                                                
                                                                                enqueueSnackbar("URL copied to clipboard", {
                                                                                    variant: 'success'
                                                                                });
                                                                            }}
                                                                            edge="end"
                                                                            aria-label="copy"
                                                                        >
                                                                            <ContentCopyIcon />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            onClick={() => handleDelete(parsedUri.uri)}
                                                                            edge="end"
                                                                            aria-label="delete"
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </>
                                                                }
                                                            >
                                                            <ListItemAvatar>
                                                                
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={`${parsedUri.id}`}
                                                                secondary={Utilities.capitalize(parsedUri.type.replace('_', ' '))}
                                                            />
                                                            </ListItem>
                                                        );
                                                    })
                                                }
                                            </List>
                                        </Collapse>
                                    </Fragment>
                                );

                    })}
                </List>
            </Box>
            }
        </>
    );
}
