import * as React from 'react';
import ContentBox from './ContentBox';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import TeamListSingle from './TeamListSingle';

export default function TeamList(props) {

    const defaultFields = [
        {
            name: 'name',
            width: '30%'
        },
        {
            name: 'territories',
            width: '35%'
        },
        {
            name: 'totalUsers',
            width: '15%'
        },
        {
            name: 'options',
            width: '10%'
        }
    ];

    const fields = props.fields || defaultFields;

    const fieldLabels = [
        {
            name: 'name',
            label: 'Name'
        },
        {
            name: 'territories',
            label: 'Territories'
        },
        {
            name: 'totalUsers',
            label: 'Total Users'
        }
    ];

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                    {
                        fields.map(field => {
                            const label = fieldLabels.find(fieldLabel => fieldLabel.name === field.name);

                            return <TableCell key={field.name} sx={{width: field.width, paddingRight: 0}}><Typography variant="body1">{ label && label.label}</Typography></TableCell>;
                        })
                    }
                    </TableRow>
                </TableHead>
            </Table>
            <ContentBox sx={{p: 0}}>
                <Table>
                    <TableBody>
                        { ( props.data.length > 0 ) ?
                            props.data.map((row) => {
                                var teamSingleProps = {
                                    key: row.getId(),
                                    data: row,
                                    fields: fields
                                };

                                if( props.onDelete )
                                {
                                    teamSingleProps['onDelete'] = props.onDelete;
                                }

                                if( props.onClickAddChild )
                                {
                                    teamSingleProps['onClickAddChild'] = props.onClickAddChild;
                                }

                                return(
                                    <TeamListSingle {...teamSingleProps}/>
                                )
                            }
                        )
                        :
                        <TableRow>
                            <TableCell colSpan={7} sx={{width: '40%', paddingRight: 0, textAlign: 'center'}} component="th" scope="row">
                                <Typography variant="body1">{props.noRecordsLabel || 'No results to display'}</Typography>
                            </TableCell>
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </ContentBox>
        </TableContainer>
    );
}