import React, { useState, useEffect } from 'react';
import { UserRepository } from '@warnerconnect/library';

import { CircularProgress, Avatar } from '@mui/material';
import { useAuthState } from '../../Context';

export default function User(props) {

    const [userData, setUserData] = useState();
    const [userDataLoading, setUserDataLoading] = useState(true);
    const userDetails = useAuthState();

    const userRepository = new UserRepository();
    userRepository.setBearerToken(userDetails.accessToken);
    
    // Detect path change to hand page edit
    useEffect(() => {

        if( props.id )
        {
            setUserDataLoading(true);

            userRepository.getUser(props.id).then( user => {
                setUserData(user);
    
                setUserDataLoading(false);
            });
        }
        else
        {
            setUserDataLoading(false);
        }
    }, []);

    function stringAvatar(name) {
        let splitName = name.trim().split(' ');

        if( splitName.length > 1 ){
            return `${splitName[0].charAt(0).toUpperCase()}${splitName[1].charAt(0).toUpperCase()}`
        }
        else{
            if( name.length > 1 ){
                return `${name.charAt(0).toUpperCase()}${name.charAt(1).toLowerCase()}`
            }
            else{
                return name.toUpperCase();
            }
        }
    }

    return (
        <Avatar title={( userDataLoading || !userData ) ? null : userData.getName()}>{ userDataLoading ? <CircularProgress color="secondary" size={17} /> : userData ? stringAvatar(userData.getName()) : '?' }</Avatar>
    );
};
