import React, { useState, useEffect, useReducer } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

import { AcquisitionRepository, AcquisitionAPI } from '@warnerconnect/library';

import { TablePagination, Box, InputLabel, Tooltip, InputAdornment, Select, TextField, MenuItem, Button, Typography, CircularProgress, Link as MLink, Checkbox, ListItemText, OutlinedInput, Autocomplete, Chip, FormControlLabel, Switch } from '@mui/material';

import slugify from 'react-slugify';

import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';
import exactTargetGenres from '../../Utilities/exactTargetGenres';

import { useAuthDispatch, logout, useAuthState, changeUserTeam } from '../../../Context';
import { useSnackbar } from "notistack";

import {
    Info as InfoIcon,
    AddBox as AddBoxIcon,
    ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import { formReducer } from '../../../Context/formReducer';

import { campaignTypes } from '../../../Config/campaigns';

import CampaignList from '../../Elements/CampaignList';
import useQuery from '../../Utilities/useQuery';
import useTitle from '../../Utilities/useTitle';
import FieldContainer from '../../Elements/Forms/FieldContainer';
import DSPSearch from '../../Elements/Forms/Fields/DSPSearch';
import Fieldset from '../../Elements/Forms/Fieldset';
import ContentBox from '../../Elements/ContentBox';
import ModalDialogue from '../../Elements/ModalDialogue';
import CampaignTypeSelector from './../../Elements/CampaignTypeSelector';
import PageContainer from './../../Elements/PageContainer';
import CountrySelector from './../../Elements/Forms/Fields/CountrySelector';
import RecordLabelSelector from './../../Elements/Forms/Fields/RecordLabelSelector';
import HeroText from '../../Elements/HeroText';
import Hero from '../../Elements/Hero';
import HeroButtonGroup from '../../Elements/HeroButtonGroup';
import HeroButton from '../../Elements/HeroButton';
import Loader from '../../Elements/Loader';

import CampaignsHeroImage from '../../../assets/media/hero/campaigns.jpg';
import { LoadingButton } from '@mui/lab';
import TabGroup from '../../Elements/TabGroup';

export default function CampaignsIndex() {
    useTitle('Campaigns');

    const { selectedCampaignType } = useParams();

    let query = useQuery();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const userDetails = useAuthState();

    if( !query.get('page') )
    {
        query.set('page', 0)
    }

    if( !query.get('perPage') )
    {
        query.set('perPage', 25)
    }

    if( !query.get('teamPage') )
    {
        query.set('teamPage', 0)
    }

    if( !query.get('teamPerPage') )
    {
        query.set('teamPerPage', 25)
    }

    if( !query.get('allPage') )
    {
        query.set('allPage', 0)
    }

    if( !query.get('allPerPage') )
    {
        query.set('allPerPage', 25)
    }

    const [campaignFormStep, setCampaignFormStep] = useState(0);

    const [countryData, setCountryData] = useState();
    const [countryDataLoading, setCountryDataLoading] = useState(true);
    const [recordLabelData, setRecordLabelData] = useState();
    const [recordLabelDataLoading, setRecordLabelLoading] = useState(true);
    const [languageData, setLanguageData] = useState();
    const [languageDataLoading, setLanguageLoading] = useState(true);

    const [campaignDataEvents, setCampaignDataEvents] = useState(0);

    const [campaignData, setCampaignData] = useState();
    const [campaignDataLoading, setCampaignDataLoading] = useState(false);

    const [teamCampaignData, setTeamCampaignData] = useState();
    const [teamCampaignDataLoading, setTeamCampaignDataLoading] = useState(false);

    const [allCampaignData, setAllCampaignData] = useState();
    const [allCampaignDataLoading, setAllCampaignDataLoading] = useState(false);

    const acquisitionRepository = new AcquisitionRepository();
    const acquisitionAPI = new AcquisitionAPI();

    acquisitionAPI.setBearerToken(userDetails.accessToken);
    acquisitionRepository.setBearerToken(userDetails.accessToken);

    const [campaignSearchModal, setCampaignSearchModal] = useState(false);
    const [campaignSearchLabelData, setCampaignSearchLabelData] = useState();
    const [campaignSearchLabelDataLoading, setCampaignSearchLabelDataLoading] = useState(false);

    const [campaignCreateModal, setCampaignCreateModal] = useState(false);
    const [campaignCreateLoading, setCampaignCreateLoading] = useState(false);
    const [campaignCreateSubmitting, setCampaignCreateSubmitting] = useState(false);

    const [campaignFormData, setCampaignFormData] = useReducer(formReducer, {});

    const [campaignSearchData, setCampaignSearchData] = useReducer(
        ( currentValues, newValues ) => ({...currentValues, ...newValues}), {types: []}
    );

    const refreshCampaignData = event => {
        setCampaignDataEvents(campaignDataEvents + 1);
    }
    
    const toggleCampaignSearchModal = event => {
        setCampaignSearchModal(!campaignSearchModal);
    }
        
    const toggleCampaignCreateModal = event => {
        setCampaignCreateModal(!campaignCreateModal);
        setCampaignFormData({type: 'reset'});
        setCampaignFormStep(0);
    }
    
    const setCampaignFormDataSingle = (name, value) => {
        setCampaignFormData({ name: name, value: value});
    };

    const setCampaignSearchDataSingle = (name, value) => {
        setCampaignSearchData({ [name]: value });
    };

    const campaignCreateValid = () => {
        if( !campaignFormData['language'] )
        {
            return false;
        }

        if( ['presave', 'autoprerelease'].includes( campaignFormData['types'] ) && campaignFormData['artist'] )
        {
            if( campaignFormData['artist'] )
            {
                return true;
            }

            return false;
        }
        else if( campaignFormData['name'] )
        {
            return true;
        }
        else
        {
            return false;
        }
    };

    const handleChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setCampaignFormDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    const handleSearchChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setCampaignSearchDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    useEffect(() => {
        acquisitionRepository.getCountries({limit: 200})
            .then( countries => {
                var countryData = [];

                countries.getItems().forEach(country => {
                    countryData.push(country);
                });

                setCountryData(countryData);
                setCountryDataLoading(false);
            });

        acquisitionRepository.getRecordLabels({limit: 200})
            .then( recordLabels => {
                var recordLabelOptions = [];

                recordLabels.getItems().forEach(recordLabel => {
                    recordLabelOptions.push({
                        label: recordLabel.getName(),
                        id: recordLabel.getId()
                    });

                    if( recordLabel.getChildRecordLabels() )
                    {
                        recordLabel.getChildRecordLabels().forEach(childRecordLabel => {
                            recordLabelOptions.push({
                                label: ' - '+childRecordLabel.getName(),
                                id: childRecordLabel.getId()
                            });
                        });
                    }
                });

                setRecordLabelData(recordLabelOptions);
                setRecordLabelLoading(false);
            });

        acquisitionRepository.getLanguages({limit: 200})
            .then( languages => {
                setLanguageData(languages);
                setLanguageLoading(false);
            });
    }, []);


    useEffect(() => {
        setCampaignDataLoading(true);

        let limit = query.get('perPage');

        let offset = query.get('page') !== 0 ? ( query.get('page') * query.get('perPage') ) : 0;

        let searchData = {...campaignSearchData};

        let criteria = {};

        if( searchData['keywords'] ){
            criteria['keywords'] = searchData['keywords'];
        }

        if( searchData['recordLabel'] ){
            criteria['recordLabel'] = searchData['recordLabel'];
        }

        if( searchData['artist'] ){
            criteria['artist'] = Array.from(searchData['artist'], artist => artist.label);
        }

        if( searchData['country'] ){
            criteria['country'] = Array.from(searchData['country'], country => country.getA2());
        }

        if( selectedCampaignType )
        {
            criteria['locale.type'] = [selectedCampaignType];
        }
        else if( searchData['locale.type'] && ( searchData['locale.type'].length > 0 ) )
        {
            criteria['locale.type'] = searchData['locale.type'];
        }
        else
        {
            if( !searchData['locale.type'] || ( searchData['locale.type'].length === 0 ) ){
                criteria['locale.type'] = Array.from(campaignTypes, campaignType => campaignType.value);
            }
        }
        
        if( userDetails.user.id )  criteria['user'] = userDetails.user.id;

        let options = {
            limit: limit,
            offset: offset,
            include: ['locales', 'locales.translations', 'locales.countries']
        };

        acquisitionRepository.getCampaigns(options, criteria).then( response => {
            setCampaignData(response);
            setCampaignDataLoading(false);
        })
    }, [query.get('page'), query.get('perPage'), campaignDataEvents, campaignSearchData, selectedCampaignType]);

    useEffect(() => {
        setTeamCampaignDataLoading(true);

        let limit = query.get('teamPerPage');

        let offset = query.get('teamPage') !== 0 ? ( query.get('teamPage') * query.get('teamPerPage') ) : 0;

        let searchData = {...campaignSearchData};
        let criteria = {};

        if( searchData['keywords'] ){
            criteria['keywords'] = searchData['keywords'];
        }

        if( searchData['recordLabel'] ){
            criteria['recordLabel'] = searchData['recordLabel'];
        }

        if( searchData['artist'] ){
            criteria['artist'] = Array.from(searchData['artist'], artist => artist.label);
        }

        if( searchData['country'] ){
            criteria['country'] = Array.from(searchData['country'], country => country.getA2());
        }

        if( selectedCampaignType )
        {
            criteria['locale.type'] = [selectedCampaignType];
        }
        else if( searchData['locale.type'] && ( searchData['locale.type'].length > 0 ) )
        {
            criteria['locale.type'] = searchData['locale.type'];
        }
        else
        {
            if( !searchData['locale.type'] || ( searchData['locale.type'].length === 0 ) ){
                criteria['locale.type'] = Array.from(campaignTypes, campaignType => campaignType.value);
            }
        }
        
        if( userDetails.team )  criteria['team'] = userDetails.team;

        let options = {
            limit: limit,
            offset: offset,
            include: ['locales', 'locales.translations', 'locales.countries']
        };

        acquisitionRepository.getCampaigns(options, criteria).then( response => {
            setTeamCampaignData(response);
            setTeamCampaignDataLoading(false);
        });
        
    }, [userDetails.team, query.get('teamPage'), query.get('teamPerPage'), campaignDataEvents, campaignSearchData, selectedCampaignType]);

    useEffect(() => {
        setAllCampaignDataLoading(true);

        let limit = query.get('allPerPage');

        let offset = query.get('allPage') !== 0 ? ( query.get('allPage') * query.get('allPerPage') ) : 0;

        let searchData = {...campaignSearchData};
        let criteria = {};

        if( searchData['keywords'] ){
            criteria['keywords'] = searchData['keywords'];
        }

        if( searchData['recordLabel'] ){
            criteria['recordLabel'] = searchData['recordLabel'];
        }

        if( searchData['artist'] ){
            criteria['artist'] = Array.from(searchData['artist'], artist => artist.label);
        }

        if( searchData['country'] ){
            criteria['country'] = Array.from(searchData['country'], country => country.getA2());
        }

        if( selectedCampaignType )
        {
            criteria['locale.type'] = [selectedCampaignType];
        }
        else if( searchData['locale.type'] && ( searchData['locale.type'].length > 0 ) )
        {
            criteria['locale.type'] = searchData['locale.type'];
        }
        else
        {
            if( !searchData['locale.type'] || ( searchData['locale.type'].length === 0 ) ){
                criteria['locale.type'] = Array.from(campaignTypes, campaignType => campaignType.value);
            }
        }

        let options = {
            limit: limit,
            offset: offset,
            include: ['locales', 'locales.translations', 'locales.countries']
        };

        acquisitionRepository.getCampaigns(options, criteria).then( response => {
            setAllCampaignData(response);
            setAllCampaignDataLoading(false);
        });
        
    }, [query.get('allPage'), query.get('allPerPage'), campaignDataEvents, campaignSearchData, selectedCampaignType]);

    function handleChangePage(event, newValue){
        query.set('page', newValue);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function handleChangeRowsPerPage(event, newValue){
        query.set('perPage', newValue.props.value);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function handleChangeTeamPage(event, newValue){
        query.set('teamPage', newValue);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function handleChangeTeamRowsPerPage(event, newValue){
        query.set('teamPerPage', newValue.props.value);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function handleChangeAllPage(event, newValue){
        query.set('allPage', newValue);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function handleChangeAllRowsPerPage(event, newValue){
        query.set('allPerPage', newValue.props.value);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function labelDisplayedRows( { from, to, count, page } ){
        return `${from}–${to} of ${count !== -1 ? count.toLocaleString() : `more than ${to}`}`;
    }

    let tabList = [{
            label: 'My Campaigns',
            content: <>
                { campaignDataLoading && <Loader /> }
                { !campaignDataLoading && campaignData &&
                <>
                    <CampaignList
                        filter={toggleCampaignSearchModal}
                        data={campaignData.getItems()}
                        onDelete={campaignId => {
                            return acquisitionAPI.deleteCampaign(campaignId)
                                .then(deleteCampaignResponse => {
                                    enqueueSnackbar("Campaign deleted", {
                                        variant: 'success'
                                    });

                                    refreshCampaignData();

                                    return deleteCampaignResponse;
                                });
                        }}
                        onCopy={campaignId => {
                            return acquisitionAPI.copyCampaign(campaignId)
                                .then(copyCampaignResponse => {
                                    enqueueSnackbar("Campaign copied", {
                                        variant: 'success'
                                    });

                                    history.push('/campaigns/'+copyCampaignResponse.id+'/edit');

                                    return copyCampaignResponse;
                                });
                        }}
                    />
                    {
                        ( campaignData.getTotal() > 0 ) &&
                            <TablePagination
                                component="div"
                                count={campaignData.getTotal()}
                                page={parseInt(query.get('page'))}
                                rowsPerPage={parseInt(query.get('perPage'))}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelDisplayedRows={labelDisplayedRows}
                            />
                    }
                </>
                }
            </>
        }
    ];

    if( userDetails.team ){
        tabList.push({
            label: 'My Team\'s Campaigns',
            content: <>
                { teamCampaignDataLoading && <Loader /> }
                { !teamCampaignDataLoading && teamCampaignData &&
                <>
                    <CampaignList
                        filter={toggleCampaignSearchModal}
                        data={teamCampaignData.getItems()}
                        onDelete={campaignId => {
                            return acquisitionAPI.deleteCampaign(campaignId)
                                .then(deleteCampaignResponse => {
                                    enqueueSnackbar("Campaign deleted", {
                                        variant: 'success'
                                    });

                                    refreshCampaignData();

                                    return deleteCampaignResponse;
                                });
                        }}
                        onCopy={campaignId => {
                            return acquisitionAPI.copyCampaign(campaignId)
                                .then(copyCampaignResponse => {
                                    enqueueSnackbar("Campaign copied", {
                                        variant: 'success'
                                    });

                                    history.push('/campaigns/'+copyCampaignResponse.id+'/edit');

                                    return copyCampaignResponse;
                                });
                        }}
                    />
                    {
                        ( teamCampaignData.getTotal() > 0 ) &&
                            <TablePagination
                                component="div"
                                count={teamCampaignData.getTotal()}
                                page={parseInt(query.get('teamPage'))}
                                rowsPerPage={parseInt(query.get('teamPerPage'))}
                                onPageChange={handleChangeTeamPage}
                                onRowsPerPageChange={handleChangeTeamRowsPerPage}
                                labelDisplayedRows={labelDisplayedRows}
                            />
                    }
                </>
                }
            </>
        });
    }

    tabList.push({
        label: 'All Campaigns',
        content: <>
            { allCampaignDataLoading && <Loader /> }
            { !allCampaignDataLoading && allCampaignData &&
            <>
                <CampaignList
                    filter={toggleCampaignSearchModal}
                    data={allCampaignData.getItems()}
                    onDelete={campaignId => {
                        return acquisitionAPI.deleteCampaign(campaignId)
                            .then(deleteCampaignResponse => {
                                enqueueSnackbar("Campaign deleted", {
                                    variant: 'success'
                                });

                                refreshCampaignData();

                                return deleteCampaignResponse;
                            });
                    }}
                    onCopy={campaignId => {
                        return acquisitionAPI.copyCampaign(campaignId)
                            .then(copyCampaignResponse => {
                                enqueueSnackbar("Campaign copied", {
                                    variant: 'success'
                                });

                                history.push('/campaigns/'+copyCampaignResponse.id+'/edit');

                                return copyCampaignResponse;
                            });
                    }}
                />
                {
                    ( allCampaignData.getTotal() > 0 ) &&
                        <TablePagination
                            component="div"
                            count={allCampaignData.getTotal()}
                            page={parseInt(query.get('allPage'))}
                            rowsPerPage={parseInt(query.get('allPerPage'))}
                            onPageChange={handleChangeAllPage}
                            onRowsPerPageChange={handleChangeAllRowsPerPage}
                            labelDisplayedRows={labelDisplayedRows}
                        />
                }
            </>
            }
        </>
    });

    return (
        <>
            <ModalDialogue open={campaignSearchModal} size="large" onClose={toggleCampaignSearchModal}>
                <Box sx={{px: 2, pt: 2, pb: 1}}>
                    <Typography variant="h4">Search</Typography>                    
                </Box>

                <Fieldset>
                    <FieldContainer xs={12}>
                        <InputLabel shrink>Keywords</InputLabel>
                        <TextField
                            name="keywords"
                            fullWidth
                            value={campaignSearchData['keywords'] || ''}
                            onChange={handleSearchChange}
                        />
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel shrink>Territories</InputLabel>
                        {
                            ( countryData && campaignData ) &&
                                <CountrySelector
                                    name="country"
                                    value={campaignSearchData['country'] || ''}
                                    options={countryData}
                                    onChange={(event, newValues) => {
                                        setCampaignSearchDataSingle('country', newValues);
                                    }}
                                />
                        }
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel shrink htmlFor="artist">Artist <Tooltip title={<>If this campaign pertains to an artist then enter that artists name here, for instance <strong>"Jason Derulo"</strong>. This is used internally for statistics and record keeping.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                        <DSPSearch
                            name="artist"
                            type="artist"
                            fullWidth
                            placeholder="All"
                            value={campaignSearchData['artist'] || []}
                            onChange={(event, newValue) => {
                                setCampaignSearchDataSingle('artist', newValue);
                            }}
                        />
                    </FieldContainer>

                    {
                        !selectedCampaignType &&
                            <FieldContainer xs={12}>
                                <InputLabel shrink htmlFor="artist">Type</InputLabel>

                                <Select
                                    name="locale.type"
                                    fullWidth
                                    multiple
                                    displayEmpty
                                    value={campaignSearchData['locale.type'] || []}
                                    onChange={handleSearchChange}
                                    renderValue={(selected) => {
                                        if( !selected || ( selected.length === 0 ) ){
                                            return <Typography color="text.disabled">All</Typography>
                                        }else{
                                            return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={campaignTypes.find(campaignType => value === campaignType.value).title} />
                                                ))}
                                            </Box>

                                        }
                                    }}
                                >
                                    {
                                        campaignTypes &&
                                            campaignTypes.map(campaignType => {
                                                let selected = campaignSearchData['locale.type'] && campaignSearchData['locale.type'].includes(campaignType.value) ? true : false;

                                                return <MenuItem key={campaignType.value} value={campaignType.value}>
                                                    <Checkbox checked={selected} />
                                                    <ListItemText primary={campaignType.title} />
                                                </MenuItem>;
                                            })
                                    }
                                </Select>
                            </FieldContainer>
                    }

                    <FieldContainer xs={12}>
                        <InputLabel shrink htmlFor="label">Campaign Label <Tooltip title={<>For internal use only. Please select the correct label for this campaign. This information is used for data collection and, aside from admins, only members of this record label will be able to make changes to and view stats for this campaign. If you can't find the label you're looking for then please email <MLink to="mailto:warner-connect-support@wmg.com">warner-connect-support@wmg.com</MLink>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                        {
                            recordLabelData &&
                                <RecordLabelSelector
                                    name="recordLabel"
                                    value={campaignSearchData['recordLabel'] || []}
                                    options={recordLabelData}
                                    onChange={(event, newValue) => {
                                        var newValues = [];
        
                                        newValue.forEach(label => {
                                            newValues.push(label.id);
                                        });
        
                                        setCampaignSearchDataSingle('recordLabel', newValues);
                                    }}
                                />
                        }
                    </FieldContainer>
                </Fieldset>
            </ModalDialogue>

            <ModalDialogue open={campaignCreateModal} size="large" onClose={toggleCampaignCreateModal}>

                <form
                    autoComplete="off"
                    onSubmit={(e) => {
                        e.preventDefault();

                        let urlSlugRaw = [];

                        if( campaignFormData['name'] )
                        {
                            urlSlugRaw.push(campaignFormData['name']);
                        }
                        
                        if( campaignFormData['artist'] )
                        {
                            campaignFormData['artist'].forEach(artist => urlSlugRaw.push(artist.label));
                        }

                        if( campaignFormData['general.context'] )
                        {
                            urlSlugRaw.push(campaignFormData['general.context']);
                        }

                        setCampaignCreateSubmitting(true);

                        let createCampaignData = {
                            user: userDetails.user.id,
                            name: campaignFormData['name'],
                            team: userDetails.team || null,
                            isCompetition: campaignFormData['isCompetition'] === true ? true : false,
                            urlSlug: slugify(urlSlugRaw.join(' '))
                        };

                        // Create campaign
                        acquisitionAPI.createCampaign(createCampaignData)
                            .then(createCampaignResponse => {
                                let createCampaignLocaleData = {
                                    user: userDetails.user.id,
                                    team: userDetails.team || null,
                                    artist: campaignFormData['artist'] ? Array.from(campaignFormData['artist'], artist => artist.label).join(', ') : null,
                                    recordLabel: campaignFormData['label'],
                                    types: [campaignFormData['types']],
                                    configuration: {general: {context: campaignFormData['general.context']}},
                                    isGlobal: true
                                };

                                // Create Campaign Locale
                                acquisitionAPI.createCampaignLocale(createCampaignResponse.id, createCampaignLocaleData)
                                    .then(createCampaignLocaleResponse => {
                                        let createCampaignTranslationData = {
                                            language: campaignFormData['language'],
                                            isDefault: true
                                        };

                                        // Create Campaign Translation
                                        acquisitionAPI.createCampaignTranslation(createCampaignResponse.id, createCampaignLocaleResponse.id, createCampaignTranslationData)
                                            .then(createCampaignLocaleResponse => {
                                                history.push('/campaigns/'+createCampaignResponse.id+'/edit');
                                            
                                                enqueueSnackbar('New campaign created.', {
                                                    variant: 'success'
                                                });
    
                                                setCampaignCreateSubmitting(false);
                                            });
    
                                    });
                            });
                    }}
                >
                    { ( campaignFormStep === 0 ) &&
                        <Fieldset sx={{p:0}}>
                            <FieldContainer xs={12}>

                                <CampaignTypeSelector
                                    expanded={true}
                                    transitioning={false}
                                    multiple={false}

                                    onChange={(newValue) => { setCampaignFormDataSingle('types', newValue); setCampaignFormStep(1) }}
                                    value={campaignFormData['types']}
                                    items={campaignTypes}
                                />
                            </FieldContainer>

                        </Fieldset>
                    }
                    { ( campaignFormStep === 1 ) &&
                    <>
                        <Box sx={{px: 2, pt: 2, pb: 1}}>
                            <Typography variant="h4">{ campaignFormData['types'] ? 'Create '+campaignTypes.find(campaignType => campaignType.value === campaignFormData['types'] ).title+' Campaign' : 'Create Campaign' }</Typography>                    
                        </Box>
                        <Fieldset>
                            {
                                !['presave', 'autoprerelease'].includes( campaignFormData['types'] ) &&
                                    <FieldContainer xs={12}>
                                        <InputLabel shrink htmlFor="name">Campaign Name <Tooltip title={<>The fan will never see this, it's purely for internal use to best identify your campaign.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                        <TextField
                                            id="name"
                                            name="name"
                                            fullWidth
                                            value={campaignFormData['name'] || ''}
                                            onChange={handleChange}
                                            placeholder="e.g. Win Tickets to see Ed Sheeran live at the Stadium of Light"

                                        />
                                    </FieldContainer>
                            }

                            <FieldContainer>
                                <InputLabel shrink htmlFor="artist">Artist <Tooltip title={<>If this campaign pertains to an artist then enter that artists name here, for instance <strong>"Jason Derulo"</strong>. This is used internally for statistics and record keeping.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <DSPSearch
                                    name="artist"
                                    type="artist"
                                    fullWidth
                                    placeholder="All"
                                    value={campaignFormData['artist'] || []}
                                    onChange={(event, newValue) => {
                                        setCampaignFormDataSingle('artist', newValue);
                                    }}
                                />
                            </FieldContainer>
                            

                            <FieldContainer>
                                <InputLabel shrink htmlFor="general.context">Track/Album <Tooltip title={!['presave', 'autoprerelease'].includes( campaignFormData['types'] ) ? <>The single or album this pre-save relates to.</> : <>If the user follows a playlist or album when they enter the campaign then enter the name of that playlist here, for instance: <strong>"Topsify UK Top 50"</strong>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                <TextField
                                    id="general.context"
                                    name="general.context"
                                    fullWidth
                                    value={campaignFormData['general.context'] || ''}
                                    onChange={handleChange}
                                    placeholder="e.g. Equals"
                                />
                            </FieldContainer>

                            <FieldContainer>
                                <InputLabel shrink htmlFor="label">Label <Tooltip title={<>For internal use only. Please select the correct label for this campaign. This information is used for data collection and, aside from admins, only members of this record label will be able to make changes to and view stats for this campaign. If you can't find the label you\'re looking for then please email <MLink to="mailto:warner-connect-support@wmg.com">warner-connect-support@wmg.com</MLink>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                { recordLabelData &&
                                    <Select
                                        id="label"
                                        name="label"
                                        onChange={handleChange}
                                        value={campaignFormData['label'] || ''}
                                        fullWidth
                                    >
                                        <MenuItem value="">None</MenuItem>

                                        {recordLabelData.map((recordLabel) => (
                                            <MenuItem key={recordLabel.id} value={recordLabel.id}>{recordLabel.label}</MenuItem>
                                        ))}
                                    </Select>
                                }
                            </FieldContainer>

                            <FieldContainer>
                                <InputLabel shrink htmlFor="language">Language <Tooltip title={<>You can add additional languages later but this should specify the campaign's main language.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                                { languageData &&
                                    <Select
                                        id="language"
                                        name="language"
                                        onChange={handleChange}
                                        value={campaignFormData['language'] || ''}
                                        fullWidth
                                    >
                                        {languageData.getItems().map((language) => (
                                            <MenuItem key={language.getId()} value={language.getId()}>{language.getName()}</MenuItem>
                                        ))}
                                    </Select>
                                }
                            </FieldContainer>

                            <FieldContainer xs={6}>
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink htmlFor="isCompetition">Is This Campaign a Competition?</InputLabel>

                                <FormControlLabel control={<Switch name="isCompetition" onChange={handleChange} checked={campaignFormData['isCompetition'] ? true : false} />} label={campaignFormData['isCompetition'] ? "Yes" : "No"} />
                            </FieldContainer>
                            
                            <FieldContainer>
                                <Button
                                    sx={{mb: 2}}
                                    variant="outlined"
                                    startIcon={<ArrowBackIcon />}
                                    onClick={() => { setCampaignFormStep(0); }}
                                >
                                    Back
                                </Button>
                            </FieldContainer>

                            <FieldContainer sx={{textAlign: 'right'}}>
                                <LoadingButton
                                    sx={{mb: 2}}
                                    type="submit"
                                    loading={campaignCreateSubmitting}
                                    loadingPosition="end"
                                    variant="contained"
                                    endIcon={<AddBoxIcon />}
                                    disabled={!campaignCreateValid()}
                                >
                                    Create Campaign
                                </LoadingButton>
                            </FieldContainer>

                        </Fieldset>
                        </>
                    }
                </form>

                    
            </ModalDialogue>

            <Hero image={CampaignsHeroImage}>
                <HeroText>
                    <Typography variant="h4" sx={{mb: 1}}>Welcome</Typography>
                    <Typography paragraph>Here you can view your campaigns, check out other campaigns built by other users and create new campaigns.</Typography>
                    <Typography variant="button"><MLink component={Link} to="/resources">Learn More</MLink></Typography>
                </HeroText>
                <HeroButtonGroup>
                    <HeroButton color="secondary" onClick={toggleCampaignCreateModal}>Create Campaign<AddCircleOutlineIcon /></HeroButton>
                </HeroButtonGroup>
            </Hero>
            <PageContainer>
                <Typography variant="h4" gutterBottom>{ (selectedCampaignType && campaignTypes) ? campaignTypes.find(campaignType => campaignType.value === selectedCampaignType).title+' Campaigns' : 'Campaigns' }</Typography>

                <TabGroup
                    tabList={tabList}
                />
            </PageContainer>

        </>
    );
};
