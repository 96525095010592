import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function HeroHeader(props) {

  return (
    <Box sx={{pt: 9, pb: 15}}>
      { props.subTitle && <Typography variant="h4">{props.subTitle}</Typography>}
      { props.title && <Typography variant="h2">{props.title}</Typography>}
      { props.children && props.children }
    </Box>
  );
}