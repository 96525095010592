import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import './App.css';
import App from './App';
import theme from './Config/theme';

import {
  BrowserRouter as Router
} from "react-router-dom";

import { AuthProvider } from "./Context";
import { CoreAPIConfig, CORE_API_ENVIRONMENT_TYPE } from '@warnerconnect/library';

/*
For now, can be used for testing
*/
if( process.env.NODE_ENV === 'development-d' ){
  CoreAPIConfig.setEnvironment(CORE_API_ENVIRONMENT_TYPE.LOCAL);
}else if( process.env.NODE_ENV === 'test' ){
  CoreAPIConfig.setEnvironment(CORE_API_ENVIRONMENT_TYPE.STAGING);
}


ReactDOM.render(
  <>
    <ThemeProvider theme={theme} >
      <CssBaseline />
      <AuthProvider>
        <Router>
          <App />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  </>,
  document.getElementById('root')
);

reportWebVitals();
